import React from "react";
import Col from "react-bootstrap/Col";
import "./PendingObjEvaluationPeopleList.css";
import PendingObjPerson from "./PendingObjPerson";

function PendingObjEvaluationPeopleList({ people, moveEvaluation }) {
  return (
    <Col className="pending-obj-evaluation-list">
      {people.map((item) => (
        <PendingObjPerson
          key={item._id}
          id={item._id}
          firstName={item.firstName}
          lastName={item.lastName}
          bossName={item.bossName}
          status={item.status}
          moveEvaluation={moveEvaluation}
          evaluationId={item.evaluationId}
        />
      ))}
    </Col>
  );
}

export default PendingObjEvaluationPeopleList;
