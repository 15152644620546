import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./PoolPerson.css";

function PoolPerson({ id, firstName, lastName, profile, handleSelection }) {
  return (
    <Row key={id} className="align-items-center people-pool-item">
      <Col lg={2}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => handleSelection(id)}
        />
      </Col>
      <Col lg={10} className="right">
        <Col>
          <h5>
            {firstName} {lastName}
          </h5>
        </Col>
        <Col>
          <h6>{profile}</h6>
        </Col>
      </Col>
    </Row>
  );
}

export default PoolPerson;
