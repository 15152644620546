import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./NewOrganizationForm.css";

function NewOrganizationForm() {
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/organizations`,
        data: {
          name: name,
          accountId: state.account,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/organizations");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  let breadcrumbs = [
    {
      title: "Organizaciones",
      path: "/organizations",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col className="new-organization-header">
          <h2>{t("Organizaciones.Organizacion.NewForm.Titulo")}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="new-organization-form">
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Nombre de la organización"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="nombre de la organización"
                onChange={handleNameChange}
              />
            </FloatingLabel>
            <Button className="primary" onClick={handleSubmit}>
              {t("Organizaciones.Organizacion.NewForm.Guardar")}
            </Button>
            <Link to="/organizations">
              <Button className="danger">
                {t("Organizaciones.Organizacion.NewForm.Cancelar")}
              </Button>
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewOrganizationForm;
