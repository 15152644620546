import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Behavior.css";

function Behavior({ id, name, name_english, behaviors, language }) {
  const { t } = useTranslation();

  return (
    <Row className="behavior">
      <Col className="behavior-wrapper">
        <Col className="competency-name">
          <h4>{language === "es" ? name : name_english}</h4>
        </Col>
        <Col>
          {behaviors.length === 0 ? (
            <Col className="center no-behaviors">
              {t("Competencias.Comportamiento.SinComportamientos")}
            </Col>
          ) : (
            behaviors.map((element) => (
              <Row
                key={element._id}
                className="align-items-center single-behavior"
              >
                {language === "es" ? (
                  <Col lg={11} className="behavior-header-description">
                    <strong>{element.title}</strong> - {element.description}
                  </Col>
                ) : (
                  <Col lg={11} className="behavior-header-description">
                    <strong>{element.title_english}</strong> -{" "}
                    {element.description_english}
                  </Col>
                )}

                <Col lg={1} className="behavior-content">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <FontAwesomeIcon icon={faCog} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={Link}
                        to={`/behaviors/edit/${element._id}`}
                      >
                        <FontAwesomeIcon icon={faEdit} />{" "}
                        {t("Competencias.Comportamiento.Editar")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to={`/behaviors/delete/${element._id}`}
                      >
                        <FontAwesomeIcon icon={faTrash} />{" "}
                        {t("Competencias.Comportamiento.Eliminar")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            ))
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default Behavior;
