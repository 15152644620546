import React from "react";
import Col from "react-bootstrap/Col";
import "./PersonStrenghts.css";
import { useTranslation } from "react-i18next";

function PersonStrenghts({ weaknesses, strenghts, language }) {
  const { t } = useTranslation();

  return (
    <>
      <Col className="strenghts mt-3">
        <Col>
          <h6>{t("Personas.Persona.Strenghts.Fortalezas")}</h6>
        </Col>
        <Col>
          {strenghts !== undefined
            ? strenghts.map((element) => (
                <span className="strength" key={element.competency}>
                  {element.competency}
                </span>
              ))
            : ""}
        </Col>
      </Col>
      <Col className="weaknessess mt-3">
        <Col>
          <h6>{t("Personas.Persona.Strenghts.AreasDeOportunidad")}</h6>
        </Col>
        <Col>
          {weaknesses !== undefined
            ? weaknesses.map((element) => (
                <span className="weakness" key={element.competency}>
                  {element.competency}
                </span>
              ))
            : ""}
        </Col>
      </Col>
    </>
  );
}

export default PersonStrenghts;
