import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import Spinner from "react-bootstrap/Spinner";
import "./EditPersonForm.css";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function EditPersonForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [profile, setProfile] = useState("");
  const [role, setRole] = useState("");
  const [boss, setBoss] = useState("");
  const [inactive, setInactive] = useState(false);
  const [show, setShow] = useState(true);
  const [users, setUsers] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { pid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${pid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setFirstName(response.data.user.firstName);
      setLastName(response.data.user.lastName);
      setEmail(response.data.user.email);
      setEmployeeId(response.data.user.employeeId);
      setProfile(response.data.user.profile);
      setRole(response.data.user.role);
      setBoss(response.data.user.boss);
      setInactive(response.data.user.inactive);
      setShow(response.data.user.show_org);

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/form/data/${state.organization}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setProfiles(result.data.profiles);
      setUsers(result.data.users);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const navigate = useNavigate();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePersonIdChange = (e) => {
    setEmployeeId(e.target.value);
  };

  const handleProfileChange = (e) => {
    setProfile(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleBossChange = (e) => {
    setBoss(e.target.value);
  };

  const handleInactiveChange = (e) => {
    setInactive(e.target.checked);
  };

  const handleShowChange = (e) => {
    setShow(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/${pid}`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          employeeId: employeeId,
          inactive: inactive,
          show_org: show,
          organization: state.organization,
          role: role,
          profile: profile,
          boss: boss,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/people");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Personas",
      path: "/people",
    },
  ];

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col className="edit-person-header">
            <h2>{t("Personas.Persona.EditForm.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="edit-person-form">
            <Form>
              <Row className="justify-content-center">
                <Col className="center mb-5 mt-5">
                  <FontAwesomeIcon
                    icon={faUser}
                    size="10x"
                    className="avatar"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nombres"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nombres"
                      onChange={handleFirstNameChange}
                      value={firstName}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Apellidos"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Apellidos"
                      onChange={handleLastNameChange}
                      value={lastName}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={handleEmailChange}
                      value={email}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Id"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Id"
                      onChange={handlePersonIdChange}
                      value={employeeId}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Select
                    aria-label="Perfil"
                    onChange={handleProfileChange}
                    value={profile}
                  >
                    <option value="">
                      {t("Personas.Persona.EditForm.SeleccionarPerfil")}
                    </option>
                    {profiles.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col className="mb-3">
                  <Form.Select
                    aria-label="Rol"
                    onChange={handleRoleChange}
                    value={role}
                  >
                    <option value="">
                      {t("Personas.Persona.EditForm.SeleccionarRol")}
                    </option>
                    <option value="user">
                      {t("Personas.Persona.EditForm.Usuario")}
                    </option>
                    <option value="admin">
                      {t("Personas.Persona.EditForm.Administrador")}
                    </option>
                    {state.role === "root" || state.role === "owner" ? (
                      <option value="owner">
                        {t("Personas.Persona.NewForm.Owner")}
                      </option>
                    ) : (
                      <></>
                    )}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleBossChange}
                    value={boss}
                  >
                    <option value="">
                      {t("Personas.Persona.EditForm.JefeDirecto")}
                    </option>
                    {users.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col className="mb-3">
                  <Form.Check
                    inline
                    label="Inactivo"
                    name="group1"
                    type="checkbox"
                    onChange={handleInactiveChange}
                    checked={inactive}
                  />
                  <Form.Check
                    inline
                    label="Mostrar en organigrama"
                    name="group2"
                    type="checkbox"
                    onChange={handleShowChange}
                    checked={show}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button className="primary" onClick={handleSubmit}>
                    {t("Personas.Persona.EditForm.Guardar")}
                  </Button>
                  <Link to="/people">
                    <Button className="danger">
                      {t("Personas.Persona.EditForm.Cancelar")}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditPersonForm;
