import React from "react";
import { Col } from "react-bootstrap";
import Hobby from "./Hobby";
import "./HobbiesList.css";

function HobbiesList({ hobbies, language }) {
  return (
    <Col className="hobbies-list">
      {hobbies.map((element) => (
        <Hobby
          key={element._id}
          id={element._id}
          title={element.title}
          title_english={element.title_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default HobbiesList;
