import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./RankingReportPerson.css";

function RankingReportPerson({
  name,
  profile,
  profile_english,
  type,
  grade,
  number,
  language,
}) {
  const badgeType = (type) => {
    if (type === "above") {
      return "above";
    } else if (type === "average") {
      return "average";
    } else if (type === "below") {
      return "below";
    }
  };

  const borderType = (type) => {
    if (type === "above") {
      return "center border-green number";
    } else if (type === "average") {
      return "center border-gray number";
    } else if (type === "below") {
      return "center border-red number";
    }
  };

  const selectMessage = (type) => {
    if (type === "above") {
      if (language === "es") {
        return "arriba del promedio";
      } else if (language === "en") {
        return "above average";
      }
    } else if (type === "average") {
      if (language === "es") {
        return "promedio";
      } else if (language === "en") {
        return "average";
      }
    } else if (type === "below") {
      if (language === "es") {
        return "debajo del promedio";
      } else if (language === "en") {
        return "below average";
      }
    }
  };

  return (
    <Col className="ranking-report-person">
      <Row className="align-items-center">
        <Col lg={1} className={borderType(type)}>
          <h5>{number}</h5>
        </Col>
        <Col lg={1} className="center">
          <FontAwesomeIcon icon={faUser} size="2x" className="avatar" />
        </Col>
        <Col lg={6} className="person-info">
          <h5>{name}</h5>
          <p>{language === "es" ? profile : profile_english}</p>
        </Col>
        <Col lg={2} className="center">
          <span className={badgeType(type)}>{selectMessage(type)}</span>
        </Col>
        <Col lg={1} className="center grade">
          <h6>{grade}</h6>
        </Col>
      </Row>
    </Col>
  );
}

export default RankingReportPerson;
