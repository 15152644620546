import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useWizard } from "react-use-wizard";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./WellnessStep.css";

function WellnessStep({
  question,
  question_english,
  answers,
  type,
  id,
  handleRadioClick,
  value,
  handleClose,
  handleShow,
  show,
  period,
  handleSubmit,
  language,
}) {
  const {
    stepCount,
    activeStep,
    isFirstStep,
    isLastStep,
    previousStep,
    nextStep,
  } = useWizard();

  const { t } = useTranslation();

  return (
    <Col className="wellnessStep">
      <Col className="wellnessStep-container-header mb-3">
        <h4>{language === "es" ? period.name : period.name_english}</h4>
      </Col>
      <Col className="wellnessStep-container-controls mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={2}>
            <Button
              className="primary"
              disabled={isFirstStep}
              onClick={() => previousStep()}
            >
              {t("Client.WellnessStep.Anterior")}
            </Button>
          </Col>
          <Col lg={8} className="center">
            <ProgressBar now={((activeStep + 1) / stepCount) * 100} />
          </Col>
          <Col lg={2} className="right">
            {isLastStep ? (
              <Button className="primary" onClick={() => handleShow()}>
                {t("Client.WellnessStep.Enviar")}
              </Button>
            ) : (
              <Button className="primary" onClick={() => nextStep()}>
                {t("Client.WellnessStep.Siguiente")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col className="wellnessStep-container-content mb-3">
        <h6>{language === "es" ? question : question_english}</h6>
      </Col>
      {type === "options" ? (
        <Col className="wellnessStep-container-answers">
          <Form.Group
            as={Row}
            className="align-items-center mb-2 wellnessStep-answers"
            controlId="answersRadio"
            onChange={(e) => handleRadioClick(id, e.target.value)}
            value={value}
          >
            {answers.map((item) => (
              <Form.Check
                key={item._id}
                id={item._id}
                type="radio"
                label={
                  language === "es"
                    ? item.description
                    : item.description_english
                }
                name={`group${id}`}
                value={item.description}
                defaultChecked={value === item.description}
              />
            ))}
          </Form.Group>
        </Col>
      ) : (
        <Col className="wellnessStep-container-forum">
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => handleRadioClick(id, e.target.value)}
              value={value}
            />
          </Form.Group>
        </Col>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Client.WellnessStep.EnviarEncuesta")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Client.WellnessStep.UltimaOportunidad")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Client.WellnessStep.Cancelar")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Client.WellnessStep.Enviar")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default WellnessStep;
