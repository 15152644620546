import React from "react";
import Col from "react-bootstrap/Col";
import "./PendingCompEvaluationPeopleList.css";
import PendingCompPerson from "./PendingCompPerson";

function PendingCompEvaluationPeopleList({ people, toggleCompetenciesEvaluation }) {
  return (
    <Col className="pending-comp-evaluation-list">
      {people.map((item) => (
        <PendingCompPerson
          key={item._id}
          id={item._id}
          firstName={item.firstName}
          lastName={item.lastName}
          status={item.status}
          toggleCompetenciesEvaluation={toggleCompetenciesEvaluation}
        />
      ))}
    </Col>
  );
}

export default PendingCompEvaluationPeopleList;
