import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import StackedBarChart from "./StackedBarChart";
import QuestionStackedBar from "./QuestionStackedBar";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import WordCloudChart from "./WordCloudChart";
import { useTranslation } from "react-i18next";

function SurveyReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [segmentationQuestions, setSegmentationQuestions] = useState([]);
  const [filter, setFilter] = useState("");
  const [questionResults, setQuestionResults] = useState([]);
  const [openQuestions, setOpenQuestions] = useState([]);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/survey/data/${rid}`,
        data: {
          filter: filter,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setLabels(response.data.labels);
      setDataSets(response.data.datasets);
      setQuestionResults(response.data.masterArray);
      setSegmentationQuestions(response.data.segmentationQuestions);
      setOpenQuestions(response.data.openQuestionsArray);
      setIsLoading(false);
    }
    fetch();
  }, [filter]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Col className="mb-3">
              <Form.Label>{t("Reportes.Segmentar")}</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => handleFilterChange(e)}
              >
                <option value="">{t("Reportes.SinFiltro")}</option>
                {segmentationQuestions.map((item) => (
                  <option key={item.questionId} value={item.questionId}>
                    {item.questionDescription}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <StackedBarChart labels={labels} datasets={datasets} />
              {questionResults.map((item) => (
                <QuestionStackedBar
                  key={item.questionDescription}
                  labels={item.labels}
                  datasets={item.datasets}
                  question={item.questionDescription}
                />
              ))}
            </Col>
            <Col>
              {openQuestions.map((item) => (
                <WordCloudChart
                  key={item.questionDescription}
                  description={item.questionDescription}
                  answers={item.answers}
                />
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SurveyReport;
