import React from "react";
import { Col } from "react-bootstrap";
import Evaluation from "./Evaluation";
import "./EvaluationsList.css";

function EvaluationsList({ evaluations, toggleStatus, language }) {
  return (
    <Col className="evaluations-list">
      {evaluations.map((element) => (
        <Evaluation
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          description={element.description}
          description_english={element.description_english}
          date={element.date}
          status={element.status}
          toggleStatus={toggleStatus}
          type={element.type}
          language={language}
        />
      ))}
    </Col>
  );
}

export default EvaluationsList;
