import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faList, faUser } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import "./GeneralDataPanel.css";

function GeneralDataPanel({ userData, finalGrade, pid, rid, language }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Col className="mb-3">
      <Col className="right">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faList} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                navigate("/report/pdf", {
                  state: { person: pid, report: rid },
                });
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} /> {t("Reportes.DescargarPDF")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>

      <Col className="general-data-panel">
        <Row className="align-items-center">
          <Col lg={2} className="center">
            <FontAwesomeIcon icon={faUser} size="5x" />
          </Col>
          <Col lg={7} className="general-data">
            <h5>
              {userData.firstName} {userData.lastName}
            </h5>
            <p>
              {language === "es"
                ? userData.profileName
                : userData.profileNameEnglish}
            </p>
          </Col>
          <Col lg={3} className="grade-data center">
            <p>{finalGrade.toFixed(2)}</p>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default GeneralDataPanel;
