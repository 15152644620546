import React from "react";
import { Col } from "react-bootstrap";
import QuestionnaireElement from "./QuestionnaireElement";
import "./QuestionnairesList.css";

function QuestionnairesList({ questionnaires, language }) {
  return (
    <Col className="questionnaires-list">
      {questionnaires.map((element) => (
        <QuestionnaireElement
          key={element._id}
          id={element._id}
          title={element.title}
          title_english={element.title_english}
          description={element.description}
          description_english={element.description_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default QuestionnairesList;
