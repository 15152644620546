import React from "react";
import { Col } from "react-bootstrap";
import Category from "./Category";
import "./CategoriesList.css";

function CategoriesList({ categories, language }) {
  return (
    <Col className="categories-list">
      {categories.map((element) => (
        <Category
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default CategoriesList;
