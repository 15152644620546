import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./ScoresPanel.css";

function ScoresPanel({ scores, language }) {
  const { t } = useTranslation();

  const generateEvaluationSection = (item) => {
    return (
      <Col key={item.id} className="center">
        <h5>{item.period}</h5>
        <p>
          <strong>{t("Reportes.Ponderacion")}:</strong> {item.weight}
        </p>
        <p>
          <strong>{t("Reportes.Calificacion")}:</strong>{" "}
          {item.finalGrade.toFixed(2)}
        </p>
      </Col>
    );
  };

  return (
    <Col className="scores-panel mb-3">
      <Row className="align-items-center">
        {scores.map((item) => generateEvaluationSection(item))}
      </Row>
    </Col>
  );
}

export default ScoresPanel;
