import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import OrganizationChart from "@dabeng/react-orgchart";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./OrgChart.css";

function OrgChart() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/tree/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setData(response.data.data[0]);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <div className="organization-chart">
        <Row className="align-items-center">
          <Col lg={11} className="reports-header"></Col>
          <Col lg={1} className="right reports-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/vertOrgChart`}>
                  <FontAwesomeIcon icon={faSitemap} />{" "}
                  {t("Organigrama.OrganigramaVertical")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <OrganizationChart
              datasource={data}
              pan={true}
              zoom={true}
              collapsible={true}
              draggable={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default OrgChart;
