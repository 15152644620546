import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./NineBoxPeople.css";
import NineBoxPerson from "./NineBoxPerson";
import { useTranslation } from "react-i18next";

function NineBoxPeople({ people, language }) {
  const { t } = useTranslation();

  return (
    <Col className="nine-box-people">
      <Row>
        <Col lg={8}></Col>
        <Col lg={2} className="talent">
          <h5>{t("Reportes.Talento")}</h5>
        </Col>
        <Col lg={2} className="performance">
          <h5>{t("Reportes.Desempeno")}</h5>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          {people.map((item) => (
            <NineBoxPerson
              key={item._id}
              name={item.name}
              profile={item.profile}
              profile_english={item.profile_english}
              talent={item.talentMean.toFixed(2)}
              performance={item.performanceMean.toFixed(2)}
              language={language}
            />
          ))}
        </Col>
      </Row>
    </Col>
  );
}

export default NineBoxPeople;
