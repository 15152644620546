import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./TrainingStatusElement.css";

function TrainingStatusElement({ id, name, profile, trainings }) {
  const { t } = useTranslation();

  const countTrainingStatus = (label) => {
    let count = 0;
    for (const training of trainings) {
      if (training.status === label) {
        count++;
      }
    }
    return count;
  };

  return (
    <Row className="training align-items-center">
      <Col lg={6} className="training-header">
        <NavLink className="nav-link" to={`/mytrainings/${id}`}>
          <h5>{name}</h5>
        </NavLink>
        <p>{profile}</p>
      </Col>
      <Col lg={6} className="training-content">
        <span className="missing">
          {t("Capacitaciones.Capacitacion.Estatus.Faltantes")}:{" "}
          {countTrainingStatus("pending")}
        </span>
        <span className="expired">
          {t("Capacitaciones.Capacitacion.Estatus.Expiradas")}:{" "}
          {countTrainingStatus("expired")}
        </span>
        <span className="pending">
          {t("Capacitaciones.Capacitacion.Estatus.EnRevision")}:{" "}
          {countTrainingStatus("completed")}
        </span>
        <span className="approved">
          {t("Capacitaciones.Capacitacion.Estatus.Aprobadas")}:{" "}
          {countTrainingStatus("approved")}
        </span>
      </Col>
    </Row>
  );
}

export default TrainingStatusElement;
