import React from "react";
import { Col } from "react-bootstrap";
import OptionSet from "./OptionSet";
import "./OptionSetsList.css";

function OptionSetsList({ optionsets, language }) {
  return (
    <Col className="option-set-list">
      {optionsets.map((element) => (
        <OptionSet
          key={element._id}
          id={element._id}
          title={element.title}
          title_english={element.title_english}
          description={element.description}
          description_english={element.description_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default OptionSetsList;
