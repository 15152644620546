import React from "react";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";

function NewOptionForm({
  id,
  title,
  title_english,
  handleChangeInput,
  english,
  description,
  description_english,
  isNegative,
  isNeutral,
}) {
  return (
    <Row className="option-form mb-3 align-items-center">
      <Col lg={2}>
        <Form.Label>{english ? title_english : title}</Form.Label>
      </Col>
      <Col lg={6}>
        <Form.Control
          placeholder="Descripción"
          className="mb-2"
          name="description"
          defaultValue={description}
          onChange={(e) => handleChangeInput(e, id)}
        />
        {english ? (
          <Form.Control
            placeholder="Description"
            name="description_english"
            onChange={(e) => handleChangeInput(e, id)}
            defaultValue={description_english}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col lg={2}>
        <Form.Check
          type="switch"
          label={english ? "Is negative" : "Es negativo?"}
          name="isNegative"
          defaultChecked={isNegative}
          onChange={(e) => handleChangeInput(e, id)}
        />
      </Col>
      <Col lg={2}>
        <Form.Check
          type="switch"
          label={english ? "Is neutral" : "Es neutral?"}
          name="isNeutral"
          defaultChecked={isNeutral}
          onChange={(e) => handleChangeInput(e, id)}
        />
      </Col>
    </Row>
  );
}

export default NewOptionForm;
