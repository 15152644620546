import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faList,
  faPlus,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import BehaviorsList from "../Components/BehaviorsList";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./Behaviors.css";

function Behaviors() {
  const [isLoading, setIsLoading] = useState(true);
  const [competencies, setCompetencies] = useState([]);
  const [show, setShow] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState("");
  const [counter, setCounter] = useState(0);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencies/behaviors/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/organizations`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      let filteredOrganizations = result.data.organizations.filter(
        (element) => element._id !== state.organization
      );

      setOrganizations(filteredOrganizations);
      setCompetencies(response.data.competencies);
      setCounter(response.data.counter);
      setIsLoading(false);
    }
    fetch();
  }, [state.language, state.organization, state.token]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value);
  };

  const handleCloneCatalog = async () => {
    setIsLoading(true);
    try {
      if (organization === "") {
        toast("Por favor selecciona una organización");
        return;
      } else {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/competencies/clone`,
          data: {
            sourceOrganization: state.organization,
            destinationOrganization: organization,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setShow(false);
        setIsLoading(false);
        toast("Competencias clonadas exitosamente");
      }
    } catch (error) {
      toast("No fue posible clonar las competencias");
    }
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <ToastContainer />
        <Row className="align-items-center">
          <Col lg={11} className="behaviors">
            <h2>
              {t("Competencias.Comportamientos")} - {counter}
            </h2>
          </Col>
          <Col lg={1} className="right behaviors-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/behaviors/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Competencias.NuevoComportamiento")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/behaviors/competencies`}>
                  <FontAwesomeIcon icon={faStream} />{" "}
                  {t("Competencias.IrCompetencias")}
                </Dropdown.Item>
                {state.role === "root" ? (
                  <Dropdown.Item onClick={handleShow}>
                    <FontAwesomeIcon icon={faCopy} />{" "}
                    {t("Competencias.ClonarCatalogo")}
                  </Dropdown.Item>
                ) : (
                  <></>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          {competencies.length === 0 ? (
            <Col className="empty-behaviors center">
              <h5>{t("Competencias.SinComportamientos")}</h5>
            </Col>
          ) : (
            <BehaviorsList
              competencies={competencies}
              language={state.language}
            />
          )}
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Competencias.ClonarModal")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Competencias.ClonarDescripcionModal")}
            <Form.Select onChange={(e) => handleOrganizationChange(e)}>
              <option value="">
                {t("Competencias.ClonarSeleccionarOrganizacion")}
              </option>
              {organizations.map((organization) => (
                <option key={organization._id} value={organization._id}>
                  {organization.name}
                </option>
              ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button className="primary" onClick={handleCloneCatalog}>
              {t("Competencias.ClonarBtn")}
            </Button>
            <Button className="danger" onClick={handleClose}>
              {t("Competencias.ClonarCancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default Behaviors;
