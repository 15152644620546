import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./PendingEvaluation.css";
const dayjs = require("dayjs");

function PendingEvaluation({
  end_date,
  periodName,
  periodName_english,
  user,
  status,
  type,
  path,
  language,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const getStatusMessage = (status) => {
    if (status === "createObjectives") {
      if (language === "es") {
        return "Alta de objetivos";
      } else if (language === "en") {
        return "Create objectives";
      }
    } else if (status === "inReview") {
      if (language === "es") {
        return "En revisión";
      } else if (language === "en") {
        return "In review";
      }
    } else if (status === "inProgress") {
      if (language === "es") {
        return "Cumplimiento usuario";
      } else if (language === "en") {
        return "In progress";
      }
    } else if (status === "firstApproval") {
      if (language === "es") {
        return "Esperando primera aprobación";
      } else if (language === "en") {
        return "Waiting first approval";
      }
    } else if (status === "secondApproval") {
      if (language === "es") {
        return "Esperando segunda aprobación";
      } else if (language === "en") {
        return "Waiting second approval";
      }
    } else if (status === "finished") {
      if (language === "es") {
        return "Terminada";
      } else if (language === "en") {
        return "Finished";
      }
    }
  };

  return (
    <Col lg={4} className="mb-4">
      <Col className={`pending-evaluation-${type}`}>
        <h5 className="pending-evaluation-title">
          {language === "es" ? periodName : periodName_english}
        </h5>
        <p className="pending-evaluation-date">
          {t("Dashboard.EvaluacionesPendientes.Termina")}{" "}
          {dayjs(end_date).format("MMM-DD")}
        </p>
        <p className="pending-evaluation-user">{user}</p>
        <p className="pending-evaluation-status">{getStatusMessage(status)}</p>
        <Col className="d-grid gap-2">
          <Button size="lg" onClick={() => navigate(path)}>
            {t("Dashboard.EvaluacionesPendientes.AbrirEvaluacion")}
          </Button>
        </Col>
      </Col>
    </Col>
  );
}

export default PendingEvaluation;
