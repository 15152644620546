import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import "./NewMyTrainingForm.css";

function NewMyTrainingForm() {
  const [training, setTraining] = useState("");
  const [trainings, setTrainings] = useState([
    { id: "1", name: "Finanzas avanzadas" },
    { id: "2", name: "Six Sigma" },
    { id: "3", name: "Administración de personal" },
    { id: "4", name: "Valores en el trabajo" },
  ]);
  const [trainingDate, setTrainingDate] = useState(new Date());

  const { t } = useTranslation();

  const handleTrainingChange = (e) => {
    setTraining(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(training);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="new-mytraining-header">
          <h2>{t("Capacitaciones.MisCapacitaciones.NewForm.Titulo")}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="new-mytraining-form">
          <Form onSubmit={handleSubmit}>
            <Form.Label>
              {t(
                "Capacitaciones.MisCapacitaciones.NewForm.CapacitacionCursada"
              )}
            </Form.Label>
            <Form.Select onChange={handleTrainingChange} className="mb-2">
              <option>
                {t(
                  "Capacitaciones.MisCapacitaciones.NewForm.SeleccionarCapacitacion"
                )}
              </option>
              {trainings.map((item) => (
                <option>{item.name}</option>
              ))}
            </Form.Select>
            <Form.Label>
              {t(
                "Capacitaciones.MisCapacitaciones.NewForm.FechaDeCumplimiento"
              )}
            </Form.Label>
            <DatePicker
              selected={trainingDate}
              onChange={(date) => setTrainingDate(date)}
              className="mb-2"
            />
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                {t(
                  "Capacitaciones.MisCapacitaciones.NewForm.ArchivoDeEvidencia"
                )}
              </Form.Label>
              <Form.Control type="file" />
            </Form.Group>
            <Button variant="primary" type="submit">
              {t("Capacitaciones.MisCapacitaciones.NewForm.MandarAprobacion")}
            </Button>
            <Link to="/mytrainings">
              <Button variant="danger">
                {t("Capacitaciones.MisCapacitaciones.NewForm.Cancelar")}
              </Button>
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewMyTrainingForm;
