import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./EditCommitmentForm.css";

function EditCommitmentForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { cid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/commitments/users/${state.userId}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        const result = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/commitments/${cid}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        setUsers(response.data.users);
        setUser(result.data.commitment.user);
        setTitle(result.data.commitment.title);
        setDescription(result.data.commitment.description);
        setDueDate(new Date(result.data.commitment.dueDate));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetch();
  }, []);

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/commitments/${cid}`,
        data: {
          title: title,
          user: user,
          description: description,
          dueDate: new Date(dueDate),
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/commitments");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Compromisos",
      path: "/commitments",
    },
  ];

  if (isLoading === true) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col className="edit-commitment-header">
            <h2>{t("Compromisos.Compromiso.EditForm.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="edit-commitment-form">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.EditForm.ParaQuien")}
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleUserChange}
                  value={user}
                >
                  <option value="">
                    {t("Compromisos.Compromiso.EditForm.SeleccionarPersona")}
                  </option>
                  {users.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.EditForm.TituloCompromiso")}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleTitleChange}
                  value={title}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.EditForm.DecripcionCompromiso")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  disabled={false}
                  onChange={handleDescription}
                  value={description}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.EditForm.ArchivoReferencia")}
                </Form.Label>
                <Form.Control type="file" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.EditForm.FechaCumplimiento")}
                </Form.Label>
                <DatePicker
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                />
              </Form.Group>
              <Button className="primary" onClick={handleSubmit}>
                {t("Compromisos.Compromiso.EditForm.Guardar")}
              </Button>
              <Link to="/commitments">
                <Button className="danger">
                  {t("Compromisos.Compromiso.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditCommitmentForm;
