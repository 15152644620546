import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NineBoxPeople from "./NineBoxPeople";
import NineBoxes from "./NineBoxes";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NineBoxReport.css";

function NineBoxReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [peopleData, setPeopleData] = useState([]);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/ninebox/data/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPeopleData(response.data.finalArray);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const selectBox = (box) => {
    let peopleArray = [];
    peopleArray = peopleData.filter((person) => person.box === box);
    setPeople(peopleArray);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container>
        <Row className="justify-content-center nine-box-report">
          <Col lg={1} className="left">
            <h4 className="performance">{t("Reportes.Desempeno")}</h4>
          </Col>
          <Col lg={7} className="center">
            <Col>
              <h4 className="talent">{t("Reportes.Talento")}</h4>
            </Col>
            <Col>
              <NineBoxes selectBox={selectBox} />
            </Col>
          </Col>
        </Row>
        <Row>
          <NineBoxPeople people={people} language={state.language} />
        </Row>
      </Container>
    );
  }
}

export default NineBoxReport;
