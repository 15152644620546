import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PeopleWillEvaluateDetailsList from "../Components/PeopleWillEvaluateDetailsList";
import { useTranslation } from "react-i18next";

function PeopleWillEvaluateDetails() {
  const [people, setPeople] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { eid, pid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/person/evaluation`,
        data: {
          periodId: eid,
          personId: pid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.people);
      setCurrentUser(response.data.currentUser);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleDeletePersonEvaluation = async (id) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/person/delete/evaluation`,
      data: {
        periodId: eid,
        personId: pid,
        evaluateeId: id,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/person/evaluation`,
      data: {
        periodId: eid,
        personId: pid,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    setPeople(response.data.people);
    setCurrentUser(response.data.currentUser);
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="people-will-evaluate-details">
            <Col>
              <h2>
                {currentUser.firstName} {currentUser.lastName} -{" "}
                {t("Evaluaciones.EvaluaA")}
              </h2>
            </Col>
            <PeopleWillEvaluateDetailsList
              people={people}
              handleDeletePersonEvaluation={handleDeletePersonEvaluation}
            />
            <Col>
              <Form.Group className="new-rule-form-buttons">
                <Link to={`/evaluation/${eid}/altpeople`}>
                  <Button className="danger">
                    {t("Evaluaciones.Regresar")}
                  </Button>
                </Link>
              </Form.Group>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PeopleWillEvaluateDetails;
