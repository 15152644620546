import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrainingGroupsList from "../Components/TrainingGroupsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardTeacher,
  faList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./TrainingGroups.css";

function TrainingGroups() {
  const [isLoading, setIsLoading] = useState(true);
  const [trainingGroups, setTrainingGroups] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainingGroups/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTrainingGroups(response.data.trainingGroups);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.language, state.token]);

  let breadcrumbs = [
    {
      title: "Planes desarrollo",
      path: "/trainings/training-status",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={11} className="training-groups-header">
            <h2>{t("Capacitaciones.GruposDeCapacitacion.Titulo")}</h2>
          </Col>
          <Col lg={1} className="right training-groups-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/trainings/training-group/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Capacitaciones.GruposDeCapacitacion.NuevoGrupo")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/trainings`}>
                  <FontAwesomeIcon icon={faChalkboardTeacher} />{" "}
                  {t("Capacitaciones.Actividades")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {trainingGroups.length === 0 ? (
            <Col className="empty-training-groups center">
              <h5>{t("Capacitaciones.GruposDeCapacitacion.SinGrupos")}</h5>
            </Col>
          ) : (
            <TrainingGroupsList
              trainingGroups={trainingGroups}
              language={state.language}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default TrainingGroups;
