import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HobbieBadge from "../Components/HobbieBadge";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./MyProfile.css";

function MyProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [successorOne, setSuccessorOne] = useState("");
  const [successorTwo, setSuccessorTwo] = useState("");
  const [keyProfile, setKeyProfile] = useState(false);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({
    name: "",
    profile: "",
    email: "",
    boss: "",
  });

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/hobbies/user`,
        data: {
          organization: state.organization,
          userId: state.userId,
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/general/${state.userId}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      let formattedHobbies = [];
      for (const item of response.data.hobbies) {
        if (response.data.currentHobbies.includes(item._id)) {
          formattedHobbies.push({
            _id: item._id,
            title: item.title,
            title_english: item.title_english,
            status: true,
          });
        } else {
          formattedHobbies.push({
            _id: item._id,
            title: item.title,
            title_english: item.title_english,
            status: false,
          });
        }
      }
      setUsers(response.data.users);
      setData(result.data.user);
      setKeyProfile(result.data.keyProfile);
      setTags(formattedHobbies);

      if (result.data.nominees.length > 0) {
        setSuccessorOne(result.data.nominees[0]._id);
      }
      if (result.data.nominees.length > 1) {
        setSuccessorTwo(result.data.nominees[1]._id);
      }
      setIsLoading(false);
    }
    fetch();
  }, []);

  const toggleStatus = (id) => {
    setIsLoading(true);
    let newTags = tags.map((item) => {
      if (item._id === id) {
        item.status = !item.status;
      }
      return item;
    });
    setTags(newTags);
    setIsLoading(false);
  };

  const handleChangeSuccesorOne = (e) => {
    setSuccessorOne(e.target.value);
  };

  const handleChangeSuccesorTwo = (e) => {
    setSuccessorTwo(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/hobbies`,
        data: {
          hobbies: tags,
          userId: state.userId,
          successorOne: successorOne,
          successorTwo: successorTwo,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/dashboard");
    } catch (error) {}
  };

  if (isLoading === true) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container>
        <Row>
          <Col>
            <h3 className="mb-4">{t("MiPerfil.MisDatos")}</h3>
            <Row className="align-items-center">
              <Col className="personal-info">
                <Col>
                  <p>
                    <strong>{t("MiPerfil.Nombre")}</strong> {data.firstName}{" "}
                    {data.lastName}
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>{t("MiPerfil.Email")}</strong> {data.email}
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>{t("MiPerfil.Perfil")}</strong> {data.profileName}
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>{t("MiPerfil.JefeDirecto")}</strong> {data.bossName}
                  </p>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col>
            <h3 className="mb-4">{t("MiPerfil.PosiblesSucesores")}</h3>
            <Col className="succesors-info">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>{t("MiPerfil.Sucesor1")}</Form.Label>
                  <Form.Select
                    onChange={(e) => handleChangeSuccesorOne(e)}
                    value={successorOne}
                    disabled={!keyProfile}
                  >
                    <option value="">{t("MiPerfil.SeleccionarSucesor")}</option>
                    {users.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("MiPerfil.Sucesor2")}</Form.Label>
                  <Form.Select
                    onChange={(e) => handleChangeSuccesorTwo(e)}
                    value={successorTwo}
                    disabled={!keyProfile}
                  >
                    <option value="">{t("MiPerfil.SeleccionarSucesor")}</option>
                    {users.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="mb-4 mt-4">{t("MiPerfil.MisHobbies")}</h3>
            <Col className="badges-container">
              {tags.map((item) => (
                <HobbieBadge
                  key={item._id}
                  id={item._id}
                  title={item.title}
                  title_english={item.title_english}
                  status={item.status}
                  toggleStatus={toggleStatus}
                  language={state.language}
                />
              ))}
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className="button-container mt-3">
            <Button className="primary" onClick={handleSubmit}>
              {t("MiPerfil.Guardar")}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MyProfile;
