import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsInEng from "./locales/en/translation.json";
import translationsInSpa from "./locales/es/translation.json";

let language = "es";

if (
  JSON.parse(localStorage.getItem("UserData")) !== null &&
  "language" in JSON.parse(localStorage.getItem("UserData"))
) {
  language = JSON.parse(localStorage.getItem("UserData")).language;
} else {
  language = "es";
}

i18n.use(initReactI18next).init({
  lng: language,
  fallbackLng: "es",
  resources: {
    en: {
      translation: translationsInEng,
    },
    es: {
      translation: translationsInSpa,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
