import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./Hobby.css";

function Hobby({ id, title, title_english, language }) {
  const { t } = useTranslation();

  return (
    <Row className="hobby align-items-center">
      <Col lg={11} className="hobby-header">
        <h5>{language === "es" ? title : title_english}</h5>
      </Col>
      <Col lg={1} className="hobby-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/hobbies/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} /> {t("Hobbies.Hobby.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/hobbies/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} /> {t("Hobbies.Hobby.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Hobby;
