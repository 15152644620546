import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import EditScaleOptionForm from "./EditScaleOptionForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import "./EditScaleForm.css";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function EditScaleForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [name_english, setNameEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [levels, setLevels] = useState([]);

  let { sid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/scales/${sid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setName(response.data.scale.name);
      setNameEnglish(response.data.scale.name_english);
      setDescription(response.data.scale.description);
      setDescriptionEnglish(response.data.scale.description_english);
      setLevels(response.data.scale.levels);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/scales/${sid}`,
        data: {
          name: name,
          description: description,
          name_english: name_english,
          description_english: description_english,
          levels: levels,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/behaviors/competencies/scales");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleAddOption = () => {
    setCounter(counter + 1);
    setLevels([
      ...levels,
      {
        id: uuidv4(),
        title: counter,
        anchor: "",
        description: "",
        anchor_english: "",
        description_english: "",
        isNa: "",
      },
    ]);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleChangeInput = (e, id) => {
    const newLevelData = levels.map((i) => {
      if (id === i._id) {
        if (e.target.name === "isNa") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setLevels(newLevelData);
  };

  let breadcrumbs = [
    {
      title: "Competencias",
      path: "/behaviors/competencies",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="edit-scale-header">
            <h2>{t("Competencias.Escala.EditForm.EditarEscala")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Col className="edit-scale-form">
                <FloatingLabel label="Nombre" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Nombre"
                    onChange={handleNameChange}
                    value={name}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Name" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onChange={handleNameEnglishChange}
                      value={name_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
                <FloatingLabel label="Descripción" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Descripción"
                    onChange={handleDescriptionChange}
                    value={description}
                  />
                </FloatingLabel>
                <Form.Text id="passwordHelpBlock" muted>
                  {t("Competencias.Escala.EditForm.MutedText")}
                </Form.Text>
                {english ? (
                  <FloatingLabel label="Description">
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      onChange={handleDescriptionEnglishChange}
                      value={description_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {levels.map((element) => (
                  <EditScaleOptionForm
                    id={element._id}
                    key={element._id}
                    title={element.title}
                    handleChangeInput={handleChangeInput}
                    english={english}
                    anchor={element.anchor}
                    description={element.description}
                    anchor_english={element.anchor_english}
                    description_english={element.description_english}
                    isNa={element.isNa}
                  />
                ))}
              </Col>
              <Col className="mb-3">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Competencias.Escala.EditForm.Guardar")}
                </Button>
                <Link to="/behaviors//competencies/scales">
                  <Button to="/scales" className="danger">
                    {t("Competencias.Escala.EditForm.Cancelar")}
                  </Button>
                </Link>
                <Button className="info" onClick={handleAddOption}>
                  {t("Competencias.Escala.EditForm.AgregarOpcion")}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditScaleForm;
