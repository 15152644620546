import React from "react";
import { Col } from "react-bootstrap";
import Organization from "./Organization";
import "./OrganizationsList.css";

function OrganizationsList({ organizations }) {
  return (
    <Col className="organizations-list">
      {organizations.map((element) => (
        <Organization key={element._id} id={element._id} name={element.name} />
      ))}
    </Col>
  );
}

export default OrganizationsList;
