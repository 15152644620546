import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./PersonWillEvaluate.css";

function PersonWillEvaluate({ id, name, profile, amount, evaluation }) {
  const { t } = useTranslation();

  return (
    <Col className="person-will-evaluate">
      <Row>
        <Col>
          <Link to={`/evaluation/${evaluation}/altperson/${id}`}>
            <h5>{name}</h5>
          </Link>
          <p>{profile}</p>
          <p>
            {t("Evaluaciones.Total")} {amount}
          </p>
        </Col>
      </Row>
    </Col>
  );
}

export default PersonWillEvaluate;
