import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PoolBehaviorElement.css";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function PoolBehaviorElement({
  id,
  title,
  title_english,
  handleSelection,
  language,
}) {
  return (
    <Row key={id} className="align-items-center behavior-pool-item">
      <Col lg={2}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => handleSelection(id)}
        />
      </Col>
      <Col lg={10}>{language === "es" ? title : title_english}</Col>
    </Row>
  );
}

export default PoolBehaviorElement;
