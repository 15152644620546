import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./HobbiesReport.css";

function HobbiesReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [hobbiesData, setHobbiesData] = useState({});
  const [categories, setCategories] = useState([]);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/hobbies/data/${rid}`,
        data: {
          filter: "",
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setHobbiesData(response.data.data);

      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setCategories(result.data.categories);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const filterByCategory = async (e) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/hobbies/data/${rid}`,
      data: {
        filter: e.target.value,
        language: state.language,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setHobbiesData(response.data.data);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Col>
              <h1 className="mb-4">{t("Reportes.Hobbies")}</h1>
            </Col>
            <Col className="mb-2">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => filterByCategory(e)}
              >
                <option value="">{t("Reportes.Filtar")}</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="hobbies-graph">
              <Bar data={hobbiesData} />
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HobbiesReport;
