import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./Category.css";

function Category({ id, name, name_english, language }) {
  const { t } = useTranslation();

  return (
    <Row className="category align-items-center">
      <Col lg={9} className="category-header">
        <NavLink className="nav-link" to={`/categories/${id}`}>
          <h5>{language === "es" ? name : name_english}</h5>
        </NavLink>
      </Col>
      <Col lg={3} className="category-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/categories/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Categorias.Categoria.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/categories/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Categorias.Categoria.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Category;
