import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./NewQuestionnaireForm.css";

function NewQuestionnaireForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [type, setType] = useState("");
  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires`,
        data: {
          title: title,
          title_english: title_english,
          description: description,
          description_english: description_english,
          type: type,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/questionnaires");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  let breadcrumbs = [
    {
      title: "Cuestionarios",
      path: "/questionnaires",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="questionnaire-form-header">
          <h2>{t("Cuestionarios.Cuestionario.NewForm.Titulo")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2 right">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="questionnaire-form">
          <Form>
            <Col className="mb-3">
              <FloatingLabel label="Título">
                <Form.Control
                  type="text"
                  placeholder="Título"
                  onChange={handleTitleChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Title" className="mt-2">
                  <Form.Control
                    type="text"
                    placeholder="Título"
                    onChange={handleTitleEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
            </Col>
            <Col className="mb-3">
              <FloatingLabel label="Descripción">
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  onChange={handleDescriptionChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Description" className="mt-2">
                  <Form.Control
                    type="text"
                    placeholder="Descripción"
                    onChange={handleDescriptionEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
            </Col>
            <Col className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label={english ? "Questionnaire type" : "Tipo de cuestionario"}
              >
                <Form.Select onChange={handleTypeChange}>
                  <option>
                    {t("Cuestionarios.Cuestionario.NewForm.Tipo")}
                  </option>
                  <option value="traditional">
                    {t("Cuestionarios.Cuestionario.NewForm.Tradicional")}
                  </option>
                  <option value="annonymus">
                    {t("Cuestionarios.Cuestionario.NewForm.Anonimo")}
                  </option>
                  <option value="competencies">
                    {t("Cuestionarios.Cuestionario.NewForm.Integrado")}
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="questionnaire-form-buttons">
              <Button className="primary" onClick={handleSubmit}>
                {t("Cuestionarios.Cuestionario.NewForm.Guardar")}
              </Button>
              <Link to="/questionnaires">
                <Button className="danger">
                  {t("Cuestionarios.Cuestionario.NewForm.Cancelar")}
                </Button>
              </Link>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewQuestionnaireForm;
