import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TrainingAssignment.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function TrainingAssignment() {
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [people, setPeople] = useState([]);
  const [completePeople, setCompletePeople] = useState([]);
  const [trainingName, setTrainingName] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { tid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/users/organization/${state.organization}`,
        data: {
          training: tid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTrainingName(result.data.training.name);
      setPeople(response.data.users);
      setCompletePeople(response.data.users);
      setSelectedPeople(response.data.selectedUsers);
      setCategories(response.data.categories);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handlePoolChange = async (e) => {
    const difference = completePeople.filter(
      ({ _id: id1 }) => !selectedPeople.some(({ _id: id2 }) => id2 === id1)
    );
    if (e.target.value !== "") {
      const newPeople = difference.filter((item) =>
        item.categories.some((element) => element._id === e.target.value)
      );
      setPeople(newPeople);
    } else {
      setPeople(difference);
    }
  };

  const handleSelection = (id) => {
    let buffer = people.find((element) => element._id === id);

    let newPeopleArray = people.filter((element) => {
      return element._id !== id;
    });

    setSelectedPeople([...selectedPeople, buffer]);
    setPeople(newPeopleArray);
  };

  const handleBackToPool = (id) => {
    let buffer = selectedPeople.find((element) => element._id === id);

    let newSelectedPeopleArray = selectedPeople.filter((element) => {
      return element._id !== id;
    });

    setPeople([...people, buffer]);
    setSelectedPeople(newSelectedPeopleArray);
  };

  const handleSubmit = async () => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/trainings/users/assign/${tid}`,
      data: {
        selectedPeople: selectedPeople,
        people: people,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/trainings");
  };

  if (isLoading) {
    return (
      <div className="loading">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col lg={9}>
            <Form>
              <Col className="header">
                <h5>{trainingName}</h5>
              </Col>
              <Col className="selected-people">
                {selectedPeople.map((element) => (
                  <Container key={element._id} fluid className="person">
                    <Row className="align-items-center">
                      <Col lg={11}>
                        <Col>
                          <h5>
                            {element.firstName} {element.lastName}
                          </h5>
                        </Col>
                        <Col>
                          <p>{element.profileName}</p>
                        </Col>
                      </Col>
                      <Col lg={1} className="center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          onClick={() => handleBackToPool(element._id)}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Col>
              <Col className="category-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Capacitaciones.AsignacionCapacitaciones.Guardar")}
                </Button>
                <Link to="/trainings">
                  <Button className="danger">
                    {t("Capacitaciones.AsignacionCapacitaciones.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={3} className="people-pool">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>
                  {t("Capacitaciones.AsignacionCapacitaciones.Filtrar")}
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handlePoolChange}
                >
                  <option value="">
                    {t("Capacitaciones.AsignacionCapacitaciones.Seleccionar")}
                  </option>
                  {categories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              {people.map((element) => (
                <Row
                  key={element._id}
                  className="align-items-center people-pool-item"
                >
                  <Col lg={2}>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={() => handleSelection(element._id)}
                    />
                  </Col>
                  <Col lg={10} className="right">
                    <Col>
                      <h5>
                        {element.firstName} {element.lastName}
                      </h5>
                    </Col>
                    <Col>
                      <h6>{element.profileName}</h6>
                    </Col>
                  </Col>
                </Row>
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TrainingAssignment;
