import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NewOptionForm from "./NewOptionForm";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./NewOptionSetForm.css";

function NewOptionSetForm() {
  const [counter, setCounter] = useState(1);
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleAddOption = () => {
    setCounter(counter + 1);
    setOptions([
      ...options,
      {
        id: uuidv4(),
        title: `Opcion ${counter}`,
        title_english: `Option ${counter}`,
        description: "",
        description_english: "",
        isNegative: false,
        isNeutral: false,
      },
    ]);
  };

  const handleChangeInput = (e, id) => {
    const newOptionsData = options.map((i) => {
      if (id === i.id) {
        if (e.target.name === "isNegative" || e.target.name === "isNeutral") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setOptions(newOptionsData);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/optionsets`,
        data: {
          title: title,
          title_english: title_english,
          description: description,
          description_english: description_english,
          options: options,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/questionnaires/optionsets");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Cuestionarios",
      path: "/questionnaires",
    },
    {
      title: "Set de opciones",
      path: "/questionnaires/optionsets",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="option-set-form-header">
          <h2>{t("Cuestionarios.OptionSets.Set.NewForm.Titulo")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2 right">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="option-set-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título"
                onChange={handleTitleChange}
              />
            </Form.Group>
            {english ? (
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  onChange={handleTitleEnglishChange}
                />
              </Form.Group>
            ) : (
              <></>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción"
                onChange={handleDescriptionChange}
              />
            </Form.Group>
            {english ? (
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  onChange={handleDescriptionEnglishChange}
                />
              </Form.Group>
            ) : (
              <></>
            )}
            <Col>
              {options.map((element) => (
                <NewOptionForm
                  key={element.id}
                  id={element.id}
                  title={element.title}
                  title_english={element.title_english}
                  english={english}
                  handleChangeInput={handleChangeInput}
                />
              ))}
            </Col>
            <Button className="primary" onClick={handleSubmit}>
              {t("Cuestionarios.OptionSets.Set.NewForm.Guardar")}
            </Button>
            <Link to="/questionnaires/optionsets">
              <Button className="danger">
                {t("Cuestionarios.OptionSets.Set.NewForm.Cancelar")}
              </Button>
            </Link>
            <Button className="info" onClick={handleAddOption}>
              {t("Cuestionarios.OptionSets.Set.NewForm.Agregar")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewOptionSetForm;
