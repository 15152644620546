import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../Public/sphere-logo-grey.png";
import "react-toastify/dist/ReactToastify.css";
import "./RenewPassword.css";

function RenewPassword() {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { state } = useLocation();

  const { email } = state;

  const navigate = useNavigate();

  const handleCodeChange = (evt) => {
    setCode(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleConfirmPasswordChange = (evt) => {
    setConfirmPassword(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        toast("Las contraseñas no coinciden");
      } else {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/users/password/renew`,
          data: {
            code: code,
            email: email,
            password: password,
          },
        });
        toast(response.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center login">
        <Col lg={8}>
          <Col className="center mb-3">
            <Col className="center mb-3">
              <Image src={Logo} fluid />
              <ToastContainer />
            </Col>
            <Col>
              <FloatingLabel
                controlId="code"
                label="Código de recuperación"
                className="mb-3"
                onChange={handleCodeChange}
              >
                <Form.Control
                  type="text"
                  placeholder="Ingresa el código que te llegó al correo"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="password"
                label="Nueva contraseña"
                className="mb-3"
                onChange={handlePasswordChange}
              >
                <Form.Control
                  type="password"
                  placeholder="Ingresa una nueva contraseña"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="confirmPassword"
                label="Confirmar nueva contraseña"
                className="mb-3"
                onChange={handleConfirmPasswordChange}
              >
                <Form.Control
                  type="password"
                  placeholder="Ingresa nuevamente la nueva contraseña"
                />
              </FloatingLabel>
            </Col>
            <Col className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleSubmit}>
                Cambiar contraseña
              </Button>
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default RenewPassword;
