import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfileSuccesorsList from "../Components/ProfileSuccesorsList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./ProfileSuccesors.css";

function ProfileSuccesors() {
  const [isLoading, setIsLoading] = useState(true);
  const [succesors, setSuccesors] = useState([]);

  const { state } = useContext(AuthContext);

  let { pid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/profiles/succesors/${pid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setSuccesors(response.data.succesors);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const deleteSuccesor = async (id) => {
    setIsLoading(true);
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/profiles/succesors/delete/${pid}`,
        data: {
          userId: id,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/profiles/succesors/${pid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setSuccesors(response.data.succesors);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={11} className="profiles-header">
            <h2>{t("Perfiles.Sucesores.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          {succesors.length === 0 ? (
            <Col className="empty-profiles center">
              <h5>{t("Perfiles.Sucesores.SinSucesores")}</h5>
            </Col>
          ) : (
            <ProfileSuccesorsList
              succesors={succesors}
              deleteSuccesor={deleteSuccesor}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default ProfileSuccesors;
