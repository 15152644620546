import React from "react";
import { Col } from "react-bootstrap";
import MyTraining from "./MyTraining";
import "./MyTrainingsList.css";

function MyTrainingsList({ trainings, userId, role, language }) {
  return (
    <Col className="mytrainings-list">
      {trainings.map((element) => (
        <MyTraining
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          group={element.trainingGroupName}
          group_english={element.trainingGroupName_english}
          date={element.completed_at}
          status={element.status}
          userId={userId}
          role={role}
          language={language}
        />
      ))}
    </Col>
  );
}

export default MyTrainingsList;
