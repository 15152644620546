import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faCopy,
  faEdit,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Evaluation.css";

function Evaluation({
  id,
  name,
  name_english,
  description,
  description_english,
  date,
  status,
  toggleStatus,
  type,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Row className="align-items-center evaluation">
      <Col lg={11} className="evaluation-header">
        <NavLink className="nav-link" to={`/evaluation/${id}/people`}>
          <h5>{language === "es" ? name : name_english}</h5>
        </NavLink>
        <p>{language === "es" ? description : description_english}</p>
        <p>{date}</p>
      </Col>
      <Col lg={1} className="evaluation-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => toggleStatus(status, id)}>
              {status === "active" ? (
                <>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  {t("Evaluaciones.Evaluacion.MarcarCompletada")}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSpinner} />{" "}
                  {t("Evaluaciones.Evaluacion.MarcarActiva")}
                </>
              )}
            </Dropdown.Item>
            {type === "objetivos" ? (
              <Dropdown.Item as={Link} to={`/evaluations/clone/${id}`}>
                <FontAwesomeIcon icon={faCopy} />{" "}
                {t("Evaluaciones.Evaluacion.ClonarEvaluacion")}
              </Dropdown.Item>
            ) : (
              <></>
            )}
            <Dropdown.Item as={Link} to={`/evaluations/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Evaluaciones.Evaluacion.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/evaluations/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Evaluaciones.Evaluacion.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Evaluation;
