import React from "react";
import { Col } from "react-bootstrap";
import WordCloud from "react-d3-cloud";
import "./WordCloudChart.css";

function WordCloudChart({ description, answers }) {
  let tempWords = [];
  for (const iterator of answers) {
    tempWords.push(...iterator.toLowerCase().split(" "));
  }

  let bannedWords = [
    "a",
    "ante",
    "con",
    "contra",
    "de",
    "del",
    "desde",
    "durante",
    "en",
    "el",
    "entre",
    "hacia",
    "hasta",
    "la",
    "las",
    "los",
    "me",
    "mediante",
    "mis",
    "nos",
    "para",
    "que",
    "se",
    "segun",
    "sin",
    "su",
    "sobre",
    "tras",
    "versus",
    "vía",
    "y",
    "ya",
    "e",
    "ni",
    "o",
    "u",
    "una",
  ];

  let filteredWords = tempWords.filter((word) => !bannedWords.includes(word));

  console.log(filteredWords);

  let data = [];
  for (const item of filteredWords) {
    if (!data.some((e) => e.text === item)) {
      data.push({ text: item, value: 100 });
    } else {
      let element = data.find((e) => e.text === item);
      element.value = element.value + 500;
    }
  }

  return (
    <Col className="world-cloud-chart center">
      <Col>
        <h5>{description}</h5>
      </Col>
      <Col>
        <WordCloud
          data={data}
          padding={1}
          width={500}
          height={300}
          rotate={() => (~~(Math.random() * 6) - 3) * 10}
        />
      </Col>
    </Col>
  );
}

export default WordCloudChart;
