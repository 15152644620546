import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RadarChart from "./RadarChart";
import "./ChartsPanel.css";

function ChartsPanel({
  competencyLabels,
  competencyAnswers,
  behaviorLabels,
  behaviorAnswers,
  expectedCompetencyAnswers,
  expectedBehaviorData,
  language,
}) {
  return (
    <Col className="charts-panel mb-3">
      <Row>
        <Col lg={6} className="center">
          <RadarChart
            labels={competencyLabels}
            answers={competencyAnswers}
            expectedAnswers={expectedCompetencyAnswers}
            language={language}
          />
        </Col>
        <Col lg={6} className="center">
          <RadarChart
            labels={behaviorLabels}
            answers={behaviorAnswers}
            expectedAnswers={expectedBehaviorData}
            language={language}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default ChartsPanel;
