import React from "react";
import parse from "html-react-parser";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useWizard } from "react-use-wizard";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import "./MessageStep.css";

function MessageStep({
  periodName,
  periodName_english,
  periodMessage,
  language,
}) {
  const { stepCount, activeStep, isFirstStep, previousStep, nextStep } =
    useWizard();

  const replaceText = () => {
    let text = "";
    if (periodMessage === undefined) {
      text = "";
      return text;
    } else {
      text = periodMessage;
      let result = periodMessage.replace(/&lt;/g, "<");
      return parse(result);
    }
  };

  const { t } = useTranslation();

  return (
    <Col className="step-container">
      <Col className="step-container-header mb-3">
        <h4>{language === "es" ? periodName : periodName_english}</h4>
      </Col>
      <Col className="step-container-controls mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={2}>
            <Button
              className="primary"
              disabled={isFirstStep}
              onClick={() => previousStep()}
            >
              {t("Client.MessageStep.Anterior")}
            </Button>
          </Col>
          <Col lg={8} className="center">
            <ProgressBar now={((activeStep + 1) / stepCount) * 100} />
          </Col>
          <Col lg={2} className="right">
            <Button className="primary" onClick={() => nextStep()}>
              {t("Client.MessageStep.Siguiente")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="step-container-content mt-3 mb-3">
            {replaceText()}
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default MessageStep;
