import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../i18n";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    token: null,
    userId: null,
    role: null,
    account: null,
    organization: null,
    isLoggedIn: false,
    firstName: null,
    lastName: null,
    language: null,
  });

  const signin = async (token, userData) => {
    setState({
      ...state,
      token: token,
      isLoggedIn: true,
      userId: userData.userId,
      role: userData.role,
      organization: userData.organization,
      firstName: userData.firstName,
      lastName: userData.lastName,
      account: userData.account,
      language: userData.language,
    });

    localStorage.setItem(
      "UserData",
      JSON.stringify({
        token: token,
        userId: userData.userId,
        role: userData.role,
        firstName: userData.firstName,
        lastName: userData.lastName,
        organization: userData.organization,
        account: userData.account,
        language: userData.language,
      })
    );
  };

  const signout = () => {
    setState({
      token: null,
      userId: null,
      role: null,
      account: null,
      organization: null,
      isLoggedIn: false,
      firstName: null,
      lastName: null,
      language: null,
    });

    localStorage.removeItem("UserData");
  };

  const changeAccount = (new_account_id) => {
    setState({ ...state, account: new_account_id });
    navigate("/organizations");
  };

  const changeOrganization = (new_organization_id) => {
    setState({ ...state, organization: new_organization_id });
    navigate("/dashboard");
  };

  const changeLanguage = (language) => {
    setState({ ...state, language: language });
    i18n.changeLanguage(language);
    const storedData = JSON.parse(localStorage.getItem("UserData"));
    localStorage.setItem(
      "UserData",
      JSON.stringify({
        token: storedData.token,
        userId: storedData.userId,
        role: storedData.role,
        firstName: storedData.firstName,
        lastName: storedData.lastName,
        organization: storedData.organization,
        account: storedData.account,
        language: language,
      })
    );
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        signin,
        signout,
        changeAccount,
        changeOrganization,
        changeLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
