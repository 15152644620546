import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import "./NewCompetencyForm.css";

function NewCompetencyForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [name_english, setNameEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [english, setEnglish] = useState(false);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencies`,
        data: {
          name: name,
          name_english: name_english,
          description: description,
          description_english: description_english,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/behaviors/competencies");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Competencias",
      path: "/behaviors/competencies",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="new-competency-header">
          <h2>{t("Competencias.Competencia.NewForm.NuevaCompetencia")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="new-competency-form">
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Nombre de la competencia"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="nombre de la competencia"
                onChange={handleNameChange}
              />
            </FloatingLabel>
            {english ? (
              <FloatingLabel label="Competency name" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Competency name"
                  onChange={handleNameEnglishChange}
                />
              </FloatingLabel>
            ) : (
              <></>
            )}
            <FloatingLabel
              controlId="floatingInput"
              label="Descripción de la competencia"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="descripción de la competencia"
                onChange={handleDescriptionChange}
              />
            </FloatingLabel>
            {english ? (
              <FloatingLabel label="Competency description" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Competency description"
                  onChange={handleDescriptionEnglishChange}
                />
              </FloatingLabel>
            ) : (
              <></>
            )}
            <Button className="primary" onClick={handleSubmit}>
              {t("Competencias.Competencia.NewForm.Guardar")}
            </Button>
            <Link to="/behaviors/competencies">
              <Button className="danger">
                {t("Competencias.Competencia.NewForm.Cancelar")}
              </Button>
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewCompetencyForm;
