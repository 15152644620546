import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Organization.css";

function Organization({ id, name }) {
  const { changeOrganization } = useContext(AuthContext);

  const { t } = useTranslation();

  return (
    <Row className="organization align-items-center">
      <Col lg={11} className="organization-header">
        <h5 className="link-like" onClick={() => changeOrganization(id)}>
          {name}
        </h5>
      </Col>
      <Col lg={1} className="organization-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/organizations/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Organizaciones.Organizacion.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/organizations/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Organizaciones.Organizacion.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Organization;
