import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import PersonOverviewDashboard from "../Components/PersonOverviewDashboard";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PersonOverview.css";

function PersonOverview() {
  const [isLoading, setIsLoading] = useState(true);
  const [personData, setPersonData] = useState({});
  const [talentData, setTalentData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [strenghts, setStrenghts] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentId, setDocumentId] = useState();

  const [show, setShow] = useState(false);

  const { state } = useContext(AuthContext);

  const { pid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/people/overview/${pid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPersonData(response.data.userData);
      setStrenghts(response.data.strenghts);
      setWeaknesses(response.data.weaknesses);
      setTalentData(response.data.talentData);
      setPerformanceData(response.data.performanceData);
      setDocuments(response.data.documents);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleSubmitDocument = async (e) => {
    const formData = new FormData();
    formData.append(e.target.name, e.target.files[0]);

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/people/overview/${pid}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPersonData(response.data.userData);
    setStrenghts(response.data.strenghts);
    setWeaknesses(response.data.weaknesses);
    setTalentData(response.data.talentData);
    setPerformanceData(response.data.performanceData);
    setDocuments(response.data.documents);
  };

  const handleDownloadDocument = async (key) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/people/download/document/${pid}`,
      data: { key: key },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = key;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  const deleteDocument = async (documentId) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/people/document/delete/${pid}`,
      data: { documentId: documentId },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPersonData(response.data.userData);
    setStrenghts(response.data.strenghts);
    setWeaknesses(response.data.weaknesses);
    setTalentData(response.data.talentData);
    setPerformanceData(response.data.performanceData);
    setDocuments(response.data.documents);
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = (title) => {
    setDocumentId(title);
    setShow(true);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <>
        <PersonOverviewDashboard
          generalData={personData}
          strenghts={strenghts}
          weaknesses={weaknesses}
          talentAssessments={talentData}
          performanceAssessments={performanceData}
          documents={documents}
          handleSubmitDocument={handleSubmitDocument}
          handleDownloadDocument={handleDownloadDocument}
          id={pid}
          handleShow={handleShow}
          language={state.language}
        />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>¿Eliminar archivo?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Estás apunto de eliminar un archivo. Una ves que lo hayas eliminado
            no lo podrás recuperar. ¿Estás seguro(a)?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteDocument(documentId)}
            >
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PersonOverview;
