import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import AuthContext from "../../Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import NewScaleOptionForm from "./NewScaleOptionForm";
import { Link, useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import "./NewScaleForm.css";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

function NewScaleForm() {
  const [counter, setCounter] = useState(1);
  const [english, setEnglish] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [name_english, setNameEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [levels, setLevels] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/scales`,
        data: {
          name: name,
          name_english: name_english,
          description: description,
          description_english: description_english,
          levels: levels,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/behaviors/competencies/scales");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleAddOption = () => {
    setCounter(counter + 1);
    setLevels([
      ...levels,
      {
        id: uuidv4(),
        title: counter,
        anchor: "",
        description: "",
        anchor_english: "",
        description_english: "",
        isNa: false,
      },
    ]);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleChangeInput = (e, id) => {
    const newLevelData = levels.map((i) => {
      if (id === i.id) {
        if (e.target.name === "isNa") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setLevels(newLevelData);
  };

  let breadcrumbs = [
    {
      title: "Escalas",
      path: "/behaviors/competencies/scales",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="new-scale-header mb-2">
          <h2>{t("Competencias.Escala.NewForm.NuevaEscala")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Col className="new-scale-form">
              <FloatingLabel label="Nombre" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  onChange={handleNameChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={handleNameEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel label="Descripción" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  onChange={handleDescriptionChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Description">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onChange={handleDescriptionEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              {levels.map((element) => (
                <NewScaleOptionForm
                  id={element.id}
                  key={element.id}
                  title={element.title}
                  handleChangeInput={handleChangeInput}
                  english={english}
                />
              ))}
            </Col>
            <Col className="mb-3">
              <Button className="primary" onClick={handleSubmit}>
                {t("Competencias.Escala.NewForm.Guardar")}
              </Button>
              <Link to="/behaviors/competencies/scales">
                <Button to="/scales" className="danger">
                  {t("Competencias.Escala.NewForm.Cancelar")}
                </Button>
              </Link>
              <Button className="info" onClick={handleAddOption}>
                {t("Competencias.Escala.NewForm.AgregarOpcion")}
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewScaleForm;
