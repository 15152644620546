import React from "react";
import Col from "react-bootstrap/Col";
import { Bar } from "react-chartjs-2";
import "./QuestionStackedBar.css";

function QuestionStackedBar({ labels, datasets, question }) {
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: question,
        font: {
          size: 18,
        },
      },
    },
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Col className="comparison-chart-container">
      <Bar data={data} options={options} />
    </Col>
  );
}

export default QuestionStackedBar;
