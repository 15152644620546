import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PendingCompEvaluationPeopleList from "../Components/PendingCompEvaluationPeopleList";
import PendingObjEvaluationPeopleList from "../Components/PendingObjEvaluationPeopleList";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFileExcel,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./PendingEvaluation.css";

function PendingEvaluation() {
  const [people, setPeople] = useState([]);
  const [type, setType] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);

  const { state } = useContext(AuthContext);

  let { eid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/pending/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/organization/${state.organization}`,
        data: {
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.evaluationPeople);
      setType(response.data.type);

      let categoriesArray = result.data.categories.map((category) => {
        return {
          label: `${category.name}`,
          value: `${category._id}`,
        };
      });
      setOptions(categoriesArray);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const moveEvaluation = async (userId, direction) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/movement/evaluation`,
        data: {
          periodId: eid,
          evaluateeId: userId,
          movement: direction,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/pending/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.evaluationPeople);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCompetenciesEvaluation = async (userId, status) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/toggle/evaluation`,
        data: {
          periodId: eid,
          evaluatorId: userId,
          status: status,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/pending/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.evaluationPeople);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePeopleList = async (e) => {
    setSelected(e);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/periods/pending/categories/${eid}`,
      data: {
        categories: e,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    setPeople(response.data.evaluationPeople);
  };

  const handleSendNotifications = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/periods/notifications/users/send/${eid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
  };

  const downloadCSV = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/periods/status/report/${eid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = eid + "-status.csv";

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return type === "competencias" ? (
      <Container>
        <Row className="align-items-center">
          <Col className="right mb-1">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`#`}>
                  <FontAwesomeIcon icon={faFileExcel} />{" "}
                  {t("Evaluaciones.Descargar")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSendNotifications()}>
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  {t("Evaluaciones.EnviarNotificaciones")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col className="mb-3">
            <Row className="align-items-center">
              <Col lg={2}>
                <h6>{t("Evaluaciones.Filtrar")}:</h6>
              </Col>
              <Col lg={10}>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={(e) => handlePeopleList(e)}
                  labelledBy="Select"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <PendingCompEvaluationPeopleList
            people={people}
            toggleCompetenciesEvaluation={toggleCompetenciesEvaluation}
          />
        </Row>
      </Container>
    ) : type === "objetivos" ? (
      <Container>
        <Row className="align-items-center">
          <Col className="right mb-1">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => downloadCSV()}>
                  <FontAwesomeIcon icon={faFileExcel} />{" "}
                  {t("Evaluaciones.DescargarReporte")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSendNotifications()}>
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  {t("Evaluaciones.EnviarNotificaciones")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col className="mb-3">
            <Row className="align-items-center">
              <Col lg={2}>
                <h6>{t("Evaluaciones.Filtrar")}</h6>
              </Col>
              <Col lg={10}>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={(e) => handlePeopleList(e)}
                  labelledBy="Select"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <PendingObjEvaluationPeopleList
            people={people}
            moveEvaluation={moveEvaluation}
          />
        </Row>
      </Container>
    ) : type === "encuestas" ? (
      <Container>
        <Row className="align-items-center">
          <Col className="right mb-1">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`#`}>
                  <FontAwesomeIcon icon={faFileExcel} />{" "}
                  {t("Evaluaciones.Descargar")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`#`}>
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  {t("Evaluaciones.EnviarNotificaciones")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col className="mb-3">
            <Row className="align-items-center">
              <Col lg={2}>
                <h6>{t("Evaluaciones.Filtrar")}</h6>
              </Col>
              <Col lg={10}>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={(e) => handlePeopleList(e)}
                  labelledBy="Select"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <PendingCompEvaluationPeopleList
            people={people}
            toggleCompetenciesEvaluation={toggleCompetenciesEvaluation}
          />
        </Row>
      </Container>
    ) : (
      <></>
    );
  }
}

export default PendingEvaluation;
