import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./NewRuleForm.css";

function NewRuleForm() {
  const [title, setTitle] = useState("");
  const [boss, setBoss] = useState(false);
  const [auto, setAuto] = useState(false);
  const [peers, setPeer] = useState(false);
  const [subordinates, setSubordinates] = useState(false);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  let { eid } = useParams();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleBossRel = (e) => {
    setBoss(e.target.checked);
  };

  const handleAutoRel = (e) => {
    setAuto(e.target.checked);
  };

  const handlePeersRel = (e) => {
    setPeer(e.target.checked);
  };

  const handleSubordinateRel = (e) => {
    setSubordinates(e.target.checked);
  };

  const handleSubmit = async (e) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/rules`,
      data: {
        title: title,
        boss: boss,
        auto: auto,
        peers: peers,
        subordinates: subordinates,
        periodId: eid,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate(`/evaluations/rules/${eid}`);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4>{t("Evaluaciones.Reglas.NewForm.Titulo")}</h4>
        </Col>
      </Row>
      <Row>
        <Col className="new-rule-form">
          <Form>
            <FloatingLabel label="Título" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Título"
                onChange={handleTitleChange}
              />
            </FloatingLabel>
            <Form.Group as={Row} className="mb-3">
              <Col>
                <Form.Check
                  type="switch"
                  label="Jefe"
                  onChange={handleBossRel}
                />
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  label="Auto"
                  onChange={handleAutoRel}
                />
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  label="Homólgos"
                  onChange={handlePeersRel}
                />
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  label="Subordinados"
                  onChange={handleSubordinateRel}
                />
              </Col>
            </Form.Group>
            <Form.Group className="new-rule-form-buttons">
              <Button className="primary" onClick={handleSubmit}>
                {t("Evaluaciones.Reglas.NewForm.Guardar")}
              </Button>
              <Link to={`/evaluations/rules/${eid}`}>
                <Button className="danger">
                  {t("Evaluaciones.Reglas.NewForm.Cancelar")}
                </Button>
              </Link>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewRuleForm;
