import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./EditOrganizationForm.css";

function EditOrganizationForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");

  const { state } = useContext(AuthContext);

  let { oid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/organizations/${oid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setName(response.data.organization.name);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const navigate = useNavigate();

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/organizations/${oid}`,
        data: {
          name: name,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/organizations");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Organizaciones",
      path: "/organizations",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col className="edit-organization-header">
            <h2>{t("Organizaciones.Organizacion.EditForm.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="edit-organization-form">
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre de la organización"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nombre de la organización"
                  onChange={handleNameChange}
                  value={name}
                />
              </FloatingLabel>
              <Button className="primary" onClick={handleSubmit}>
                {t("Organizaciones.Organizacion.EditForm.Guardar")}
              </Button>
              <Link to="/organizations">
                <Button className="danger">
                  {t("Organizaciones.Organizacion.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditOrganizationForm;
