import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./NewQuestionForm.css";

function NewQuestionForm({
  id,
  addquestion,
  handleRemoveQuestion,
  label,
  label_english,
  description,
  description_english,
  optionSet,
  options,
  type,
  handleChangeInputQuestion,
  handleChangeCheckbox,
  english,
  questionnaireType,
  perspectives,
  language,
}) {
  const { t } = useTranslation();

  const selectLabel = (perspective) => {
    if (perspective === "boss") {
      return "Jefe";
    } else if (perspective === "auto") {
      return "Auto";
    } else if (perspective === "peers") {
      return "Homólogos";
    } else if (perspective === "subordinates") {
      return "Subordinados";
    } else if (perspective === "clients") {
      return "Clientes";
    }
  };

  return (
    <Col className="question-form mb-3">
      <Col className="new-question-form-header">
        <Row>
          <Col lg={11}>
            <h5>{language === "es" ? label : label_english}</h5>
          </Col>
          <Col lg={1} className="right">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => handleRemoveQuestion(id)}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mb-3">
        <FloatingLabel controlId="floatingTextarea2" label="Descripción">
          <Form.Control
            as="textarea"
            placeholder="Descripción"
            style={{ height: "100px" }}
            name="description"
            value={description}
            onChange={(event) => handleChangeInputQuestion(event, id)}
          />
        </FloatingLabel>
        {english ? (
          <FloatingLabel
            controlId="floatingTextarea3"
            label="Description"
            className="mt-3"
          >
            <Form.Control
              as="textarea"
              placeholder="Description"
              style={{ height: "100px" }}
              name="description_english"
              onChange={(event) => handleChangeInputQuestion(event, id)}
              value={description_english}
            />
          </FloatingLabel>
        ) : (
          <></>
        )}
      </Col>
      <Col className="mb-3">
        <FloatingLabel
          controlId="floatingSelect"
          label={language === "es" ? "Tipo de pregunta" : "Type of question"}
        >
          <Form.Select
            onChange={(event) => handleChangeInputQuestion(event, id)}
            name="type"
            value={type}
          >
            <option>
              {t("Cuestionarios.Cuestionario.Pregunta.NewForm.Tipo")}
            </option>
            <option value="open">
              {t("Cuestionarios.Cuestionario.Pregunta.NewForm.Abierta")}
            </option>
            <option value="options">
              {t("Cuestionarios.Cuestionario.Pregunta.NewForm.Opciones")}
            </option>
          </Form.Select>
        </FloatingLabel>
      </Col>
      {type === "options" ? (
        <Col className="mb-3">
          {options !== undefined ? (
            <FloatingLabel controlId="floatingSelect" label="Set de opciones">
              <Form.Select
                onChange={(event) => handleChangeInputQuestion(event, id)}
                name="optionSet"
                value={optionSet}
              >
                <option>
                  {t("Cuestionarios.Cuestionario.Pregunta.NewForm.Seleccionar")}
                </option>
                {options.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          ) : (
            <></>
          )}
        </Col>
      ) : (
        <></>
      )}
      {questionnaireType === "competencies" ? (
        <Col className="mb-3">
          <Form.Label>
            {t("Cuestionarios.Cuestionario.Pregunta.NewForm.PreguntaContestar")}
          </Form.Label>
          <Form.Group
            as={Row}
            className="align-items-center mb-2"
            onChange={(event) => handleChangeCheckbox(event, id)}
          >
            <div key="inline-checkbox" className="mb-3">
              {perspectives.map((item) => (
                <Form.Check
                  inline
                  label={selectLabel(item.perspective)}
                  name={item.perspective}
                  type="checkbox"
                  id={item._id}
                  key={item._id}
                  defaultChecked={item.active}
                />
              ))}
            </div>
          </Form.Group>
        </Col>
      ) : (
        <></>
      )}
      <Col className="right">
        <Button className="info" onClick={addquestion}>
          {t("Cuestionarios.Cuestionario.Pregunta.NewForm.AgregarPregunta")}
        </Button>
      </Col>
    </Col>
  );
}

export default NewQuestionForm;
