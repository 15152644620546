import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./MyTrainingDetails.css";

function MyTrainingDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [training, setTraining] = useState({});
  const [user, setUser] = useState({});

  const { state } = useContext(AuthContext);

  let { tid, uid } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/user/detail`,
        data: {
          userId: uid,
          trainingId: tid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setTraining(result.data.training);
      setUser(result.data.user);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const formatDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();

    return day + "/" + month + "/" + year;
  };

  const handleDownloadEvidence = async () => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/trainings/download/file`,
      data: {
        key: training._id + "-" + user._id + "-" + training.fileName,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = training.fileName;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  const validateTraining = async () => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/trainings/validate/training`,
      data: {
        trainingId: training._id,
        userId: user._id,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate(`/mytrainings/${user._id}`);
  };

  if (isLoading === true) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="commitment-details">
            <Form>
              <Col className="mb-2">
                <Row className="justify-content-center">
                  <Col lg={11} className="commitment-details-alert ">
                    <Alert variant="info">
                      {t("Capacitaciones.MisCapacitaciones.Detalles.Alert")}
                    </Alert>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-2">
                <Row className="justify-content-center align-items-center">
                  <Col lg={11} className="commitment-details-title">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <h4>
                          <strong>
                            {t(
                              "Capacitaciones.MisCapacitaciones.Detalles.Titulo"
                            )}
                          </strong>{" "}
                          {training.name}
                        </h4>
                        <p className="training-details-groupName">
                          {training.trainingGroupName}
                        </p>
                      </Col>
                      <Col lg={3} className="right">
                        <h5>
                          <strong>
                            {t(
                              "Capacitaciones.MisCapacitaciones.Detalles.Completada"
                            )}
                          </strong>{" "}
                          {formatDate(training.completed_at)}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-2">
                <Row className="align-items-center justify-content-center">
                  {training.filename !== "" ? (
                    <Col
                      lg={11}
                      className="commitment-details-files"
                      onClick={() => handleDownloadEvidence()}
                    >
                      {training.fileName}
                    </Col>
                  ) : (
                    <Col>
                      <p>
                        {t(
                          "Capacitaciones.MisCapacitaciones.Detalles.NoReferenceFile"
                        )}
                      </p>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col>
                <Row className="justify-content-center">
                  <Col lg={11} className="commitment-details-buttons">
                    <Button
                      variant="primary"
                      onClick={() => validateTraining()}
                    >
                      {t(
                        "Capacitaciones.MisCapacitaciones.Detalles.ValidarCumplimiento"
                      )}
                    </Button>
                    <Button variant="secondary" onClick={() => navigate("/")}>
                      {t("Capacitaciones.MisCapacitaciones.Detalles.Regresar")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MyTrainingDetails;
