import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./Rule.css";

function Rule({ id, title, type }) {
  const { t } = useTranslation();

  return (
    <Row className="align-items-center rule">
      <Col lg={11} className="rule-header">
        <h5>{title}</h5>
        <p>{type}</p>
      </Col>
      <Col lg={1} className="rule-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/evaluations/rules/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Evaluaciones.Reglas.Regla.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/evaluations/rules/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Evaluaciones.Reglas.Regla.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Rule;
