import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./CommitmentDetails.css";

function CommitmentDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [commitment, setCommitment] = useState({});

  const { state } = useContext(AuthContext);

  let { cid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const formatDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();

    return day + "/" + month + "/" + year;
  };

  const alertMessage = (status) => {
    if (
      status === "Pending" &&
      new Date(Date.now) > new Date(commitment.dueDate)
    ) {
      return (
        <Alert variant="warning">
          {t("Compromisos.Detalle.FueraDeTiempo")}
        </Alert>
      );
    } else if (status === "Pending") {
      return (
        <Alert variant="primary">{t("Compromisos.Detalle.Pendiente")}</Alert>
      );
    } else if (status === "Completed") {
      return (
        <Alert variant="success">{t("Compromisos.Detalle.Completado")}</Alert>
      );
    } else if (status === "Waiting for approval") {
      return (
        <Alert variant="info">
          {t("Compromisos.Detalle.EsperandoAprobacion")}
        </Alert>
      );
    }
  };

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/commitments/${cid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setCommitment(result.data.commitment);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleDownloadEvidence = async () => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/commitments/evidence/download`,
      data: {
        key:
          commitment.title +
          "-" +
          commitment.creator +
          "-" +
          commitment.fileName,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download =
      commitment.title + "-" + commitment.creator + "-" + commitment.fileName;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  const completeCommitment = async () => {
    let status = "";

    if (commitment.status === "Pending") {
      status = "Waiting for approval";
    } else if (commitment.status === "Waiting for approval") {
      status = "Completed";
    }

    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/commitments/status/${cid}`,
      data: {
        status: status,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/commitments");
  };

  const buttonSelector = (status, creator, user) => {
    if (status === "Pending" && creator === state.userId) {
      return (
        <Button variant="secondary" onClick={() => navigate("/commitments")}>
          {t("Compromisos.Detalle.Regresar")}
        </Button>
      );
    } else if (status === "Waiting for approval" && creator === state.userId) {
      return (
        <Button variant="primary" onClick={() => completeCommitment()}>
          {t("Compromisos.Detalle.ValidarCumplimiento")}
        </Button>
      );
    } else if (status === "Pending" && user === state.userId) {
      return (
        <Button variant="primary" onClick={() => completeCommitment()}>
          {t("Compromisos.Detalle.MarcarCompletado")}
        </Button>
      );
    } else {
      return (
        <Button variant="secondary" onClick={() => navigate("/commitments")}>
          {t("Compromisos.Detalle.Regresar")}
        </Button>
      );
    }
  };

  if (isLoading === true) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="commitment-details">
            <Form>
              <Col className="mb-2">
                <Row className="justify-content-center">
                  <Col lg={11} className="commitment-details-alert ">
                    {alertMessage(commitment.status)}
                  </Col>
                </Row>
              </Col>
              <Col className="mb-2">
                <Row className="justify-content-center align-items-center">
                  <Col lg={11} className="commitment-details-title">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <h4>{commitment.title}</h4>
                      </Col>
                      <Col lg={3} className="right">
                        <p>
                          <strong>Fecha propuesta:</strong>{" "}
                          {formatDate(commitment.dueDate)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-2">
                <Row className="align-items-center justify-content-center">
                  <Col lg={11} className="commitment-details-panel">
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        disabled={true}
                        value={commitment.description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-2">
                <Row className="align-items-center justify-content-center">
                  <Col
                    lg={11}
                    className="commitment-details-files"
                    onClick={() => handleDownloadEvidence()}
                  >
                    {commitment.fileName}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="justify-content-center">
                  <Col lg={11} className="commitment-details-buttons">
                    {buttonSelector(
                      commitment.status,
                      commitment.creator,
                      commitment.user
                    )}
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CommitmentDetails;
