import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { Container, Col } from "react-bootstrap";
import "./Breadcrumbs.css";

function Breadcrumbs({ data }) {
  return (
    <Container fluid>
      <Col className="mb-4">
        <Nav as="ul" className="breadcrumbs">
          {data.map((item, index) => (
            <Nav.Item as="li" key={index}>
              <NavLink to={item.path}>{item.title}</NavLink>
              {" /"}
            </Nav.Item>
          ))}
        </Nav>
      </Col>
    </Container>
  );
}

export default Breadcrumbs;
