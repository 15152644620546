import React from "react";
import { Col } from "react-bootstrap";
import TrainingGroup from "./TrainingGroup";
import "./TrainingGroupsList.css";

function TrainingGroupsList({ trainingGroups, language }) {
  return (
    <Col className="training-groups-list">
      {trainingGroups.map((element) => (
        <TrainingGroup
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default TrainingGroupsList;
