import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { faCog, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./PersonWillEvaluateDetails.css";

function PersonWillEvaluateDetails({
  id,
  name,
  profile,
  relationship,
  handleDeletePersonEvaluation,
}) {
  const { t } = useTranslation();

  return (
    <Col className="person-will-evaluate-details">
      <Row className="align-items-center">
        <Col lg={11}>
          <h5>{name}</h5>
          <p>{profile}</p>
          <p>{relationship}</p>
        </Col>
        <Col lg={1}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faCog} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleDeletePersonEvaluation(id)}>
                <FontAwesomeIcon icon={faTrash} /> {t("Evaluaciones.Eliminar")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Col>
  );
}

export default PersonWillEvaluateDetails;
