import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Questionnaire.css";
import QuestionnaireTab from "./QuestionnaireTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

function Questionnaire() {
  const [english, setEnglish] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [optionSets, setOptionSets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [questionnaireType, setQuestionnaireType] = useState("");

  const { state } = useContext(AuthContext);

  let { qid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/optionsets/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/${qid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setOptionSets(response.data.optionSets);
      setQuestionnaireType(result.data.questionnaire.type);

      console.log(result.data.questionnaire.sections.length);

      if (result.data.questionnaire.sections.length > 0) {
        setTabs(result.data.questionnaire.sections);
        setActiveKey(result.data.questionnaire.sections[0]._id);
      } else {
        const newId = uuidv4();
        console.log(tabs.length);
        setTabs([
          {
            _id: newId,
            label: `Sección ${tabs.length + 1}`,
            title: `Sección ${tabs.length + 1}`,
            title_english: "",
            demographic: false,
            questions: [],
          },
        ]);
        setActiveKey(newId);
      }
    }
    fetch();
    setIsLoading(false);
  }, []);

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleAddTab = () => {
    setTabs([
      ...tabs,
      {
        _id: uuidv4(),
        label: `Sección ${tabs.length + 1}`,
        title: `Sección ${tabs.length + 1}`,
        title_english: "",
        demographic: false,
        questions: [],
      },
    ]);
  };

  const getQuestions = (id, questions) => {
    const newTabsData = tabs.map((item) => {
      if (id === item._id) {
        item.questions = questions;
      }
      return item;
    });

    setTabs(newTabsData);
  };

  const handleChangeInput = (e, id) => {
    const newTabsData = tabs.map((item) => {
      if (id === item._id) {
        if (e.target.name === "demographic") {
          item[e.target.name] = e.target.checked;
        } else {
          item[e.target.name] = e.target.value;
        }
      }
      return item;
    });

    setTabs(newTabsData);
  };

  const handleSubmit = async (e) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/populate/${qid}`,
      data: {
        sections: tabs,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/questionnaires");
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Form>
              <Col>
                <Tab.Container activeKey={activeKey}>
                  <Row>
                    <Col>
                      <Col className="right mb-3">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <FontAwesomeIcon icon={faList} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={activateEnglish}>
                              <FontAwesomeIcon icon={faLanguage} /> English
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col>
                        <Nav>
                          {tabs.map((element) => (
                            <Nav.Item key={element._id} className="active-tab">
                              <Nav.Link
                                eventKey={element._id}
                                onClick={() => setActiveKey(element._id)}
                              >
                                {state.language === "es"
                                  ? element.title
                                  : element.title_english}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col>
                        <Tab.Content>
                          {tabs.map((element) => (
                            <QuestionnaireTab
                              key={element._id}
                              id={element._id}
                              label={element.label}
                              title={element.title}
                              title_english={element.title_english}
                              handleChangeInput={handleChangeInput}
                              getQuestions={getQuestions}
                              optionSets={optionSets}
                              english={english}
                              questions={element.questions}
                              questionnaireType={questionnaireType}
                              language={state.language}
                            />
                          ))}
                        </Tab.Content>
                      </Col>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col className="questionnaire-buttons">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Button className="primary" onClick={handleSubmit}>
                    {t("Cuestionarios.Cuestionario.Guardar")}
                  </Button>
                  <Link to="/questionnaires">
                    <Button className="danger">
                      {t("Cuestionarios.Cuestionario.Cancelar")}
                    </Button>
                  </Link>
                  <Button className="info" onClick={handleAddTab}>
                    {t("Cuestionarios.Cuestionario.AgregarSeccion")}
                  </Button>
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Questionnaire;
