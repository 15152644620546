import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OptionSetsList from "../Components/OptionSetsList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./OptionSets.css";

function OptionSets() {
  const [optionsets, setOptionSets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/optionsets/organization/${state.organization}`,
        data: {
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setOptionSets(response.data.optionSets);
    }
    fetch();
    setIsLoading(false);
  }, [state.language, state.organization, state.token]);

  let breadcrumbs = [
    {
      title: "Cuestionarios",
      path: "/questionnaires",
    },
  ];

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={10} className="optionsets-header">
            <h2>{t("Cuestionarios.OptionSets.Titulo")}</h2>
          </Col>
          <Col lg={2} className="right optionsets-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/questionnaires/optionsets/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Cuestionarios.OptionSets.NuevoSet")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {optionsets.length === 0 ? (
            <Col className="empty-option-sets center">
              <h5>{t("Cuestionarios.OptionSets.SinSets")}</h5>
            </Col>
          ) : (
            <OptionSetsList optionsets={optionsets} language={state.language} />
          )}
        </Row>
      </Container>
    );
  }
}

export default OptionSets;
