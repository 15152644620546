import React from "react";
import Col from "react-bootstrap/Col";
import { Bar } from "react-chartjs-2";
import "./BarChart.css";

function BarChart({ labels, datasets }) {
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Comparación de candidatos - Últimas 5 evaluaciones",
      },
    },
  };

  return (
    <Col className="comparison-chart-container">
      <Bar data={data} options={options} />
    </Col>
  );
}

export default BarChart;
