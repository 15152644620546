import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AuthContext from "./Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainMenu from "./Navigation/Components/MainMenu";
import NavBar from "./Navigation/Components/NavBar";
import MainContent from "./Navigation/Pages/MainContent";
import Login from "./Signing/Pages/Login";
import ForgotPassword from "./Signing/Pages/ForgotPassword";
import RenewPassword from "./Signing/Pages/RenewPassword";
import "./App.css";

function MainContainer() {
  const { state, signin } = useContext(AuthContext);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("UserData"));
    if (storedData && storedData.token) {
      signin(storedData.token, {
        userId: storedData.userId,
        role: storedData.role,
        organization: storedData.organization,
        firstName: storedData.firstName,
        lastName: storedData.lastName,
        account: storedData.account,
        language: storedData.language,
      });
    }
  }, []);

  if (state.isLoggedIn) {
    return (
      <Container fluid>
        <Row>
          <Col xs={2} className="no-padding full-height">
            <MainMenu />
          </Col>
          <Col xs={10} className="full-height">
            <NavBar />
            <MainContent />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot/password" element={<ForgotPassword />} />
              <Route path="/renew/password" element={<RenewPassword />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainContainer;
