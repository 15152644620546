import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./MyTraining.css";

function MyTraining({
  id,
  name,
  name_english,
  group,
  group_english,
  date,
  status,
  userId,
  role,
  language,
}) {
  const { t } = useTranslation();

  const selectColor = (status) => {
    if (status === "approved") {
      return (
        <span className="approved">
          {t("Capacitaciones.MiCapacitacion.Aprobado")}
        </span>
      );
    } else if (status === "pending") {
      return (
        <span className="pending">
          {t("Capacitaciones.MiCapacitacion.Pendiente")}
        </span>
      );
    } else if (status === "overdue") {
      return (
        <span className="overdue">
          {t("Capacitaciones.MiCapacitacion.Expirado")}
        </span>
      );
    } else if (status === "completed") {
      return (
        <span className="completed">
          {t("Capacitaciones.MiCapacitacion.Completado")}
        </span>
      );
    }
  };

  return (
    <Row className="mytraining align-items-center">
      <Col lg={6} className="mytraining-header">
        <h5>{language === "es" ? name : name_english}</h5>
        <p>{language === "es" ? group : group_english}</p>
      </Col>
      <Col lg={2} className="mytraining-date">
        <h5>{date !== null ? "" : date}</h5>
      </Col>
      <Col lg={1} className="mytraining-status">
        {selectColor(status)}
      </Col>
      <Col lg={3} className="mytraining-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/mytraining/edit/${id}/${userId}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Capacitaciones.MiCapacitacion.Actualizar")}
            </Dropdown.Item>
            {(status === "completed" || status === "pending") &&
            role === "admin" ? (
              <Dropdown.Item
                as={Link}
                to={`/trainings/details/${id}/${userId}`}
              >
                <FontAwesomeIcon icon={faCheck} />{" "}
                {t("Capacitaciones.MiCapacitacion.Validar")}
              </Dropdown.Item>
            ) : (
              <></>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default MyTraining;
