import React from "react";
import Col from "react-bootstrap/Col";
import "./FeedbackPanel.css";
import Badge from "react-bootstrap/Badge";

function FeedbackPanel({
  description,
  description_english,
  answers,
  language,
}) {
  return (
    <Col className="feedback-panel">
      <Col className="feedback-panel-header">
        <h5>{language === "es" ? description : description_english}</h5>
      </Col>
      <Col className="feedback-panel-comments">
        {answers !== undefined ? (
          answers.map((item) => (
            <Badge key={item} bg="secondary">
              {item}
            </Badge>
          ))
        ) : (
          <></>
        )}
      </Col>
    </Col>
  );
}

export default FeedbackPanel;
