import React from "react";
import { Radar } from "react-chartjs-2";

function RadarChart({ labels, answers, expectedAnswers, language }) {
  let profile = "";
  let actual = "";
  if (language === "es") {
    profile = "Perfil";
    actual = "Actual";
  } else if (language === "en") {
    profile = "Profile";
    actual = "Current";
  }
  const data = {
    labels: labels,
    datasets: [
      {
        label: profile,
        data: expectedAnswers,
        backgroundColor: "rgba(40, 151, 221, 0.2)",
        borderColor: "rgba(40, 151, 221, 1)",
        borderWidth: 1,
      },
      {
        label: actual,
        data: answers,
        backgroundColor: "rgba(108, 220, 168, 0.2)",
        borderColor: "rgb(108, 220, 168, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 1,
        suggestedMax: 5,
      },
    },
  };

  return <Radar data={data} options={options} />;
}

export default RadarChart;
