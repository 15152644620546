import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./PendingObjPerson.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function PendingObjPerson({
  status,
  firstName,
  lastName,
  bossName,
  id,
  moveEvaluation,
  evaluationId,
}) {
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const selectStatusText = (status) => {
    switch (status) {
      case "createObjectives":
        return "Alta objetivos";
      case "inReview":
        return "En revisión";
      case "inProgress":
        return "Cumplimiento usuario";
      case "firstApproval":
        return "Esperando primera aprobación";
      case "secondApproval":
        return "Esperando segunda aprobación";
      case "finished":
        return "Terminada";
      case "closed":
        return "Cerrada";
      default:
        return " ";
    }
  };

  return (
    <Col className="pending-obj-person">
      <Row className="align-items-center">
        <Col lg={9} className="pending-obj-person-header">
          <h5 className="mb-1">
            <NavLink to={`/objectives/evaluation/${evaluationId}`}>
              {firstName} {lastName}
            </NavLink>
          </h5>
          <h6>
            <strong>{t("Evaluaciones.JefeDirecto")} </strong>
            {bossName}
          </h6>
        </Col>
        <Col lg={3}>
          <Row>
            <Col lg={2} className="center">
              {state.role === "admin" || state.role === "root" ? (
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => moveEvaluation(id, "backwards")}
                />
              ) : (
                <></>
              )}
            </Col>
            <Col lg={8} className="status center">
              {selectStatusText(status)}
            </Col>
            <Col lg={2} className="center">
              {state.role === "admin" || state.role === "root" ? (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  onClick={() => moveEvaluation(id, "forward")}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default PendingObjPerson;
