import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./PersonData.css";

function PersonData({
  id,
  firstName,
  lastName,
  directBoss,
  profile,
  categories,
}) {
  const { t } = useTranslation();

  return (
    <Col className="person-data">
      <Row className="align-items-center">
        <Col lg={4} className="center mb-2">
          <FontAwesomeIcon icon={faUser} size="4x" className="avatar" />
        </Col>
        <Col lg={8} className="mb-2 person-info">
          <h5>
            {firstName} {lastName}
          </h5>
          <h6>
            <strong>ID:</strong> {id}
          </h6>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col>
          <Table borderless>
            <tbody>
              <tr>
                <td className="right">
                  <strong>{t("Personas.Persona.Perfil")} </strong>
                </td>
                <td>{profile}</td>
              </tr>
              <tr>
                <td className="right">
                  <strong>{t("Personas.Persona.Reporta")} </strong>
                </td>
                <td>{directBoss}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center">
        <Col className="center">
          {categories !== undefined
            ? categories.map((element) => (
                <span className="category-tag" key={element.name}>
                  {element.name}
                </span>
              ))
            : ""}
        </Col>
      </Row>
    </Col>
  );
}

export default PersonData;
