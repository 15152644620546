import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Competency.css";

function Competency({
  id,
  name,
  description,
  name_english,
  description_english,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Row className="competency align-items-center">
      <Col lg={11} className="competency-header">
        <Col className="competency-header-title">
          <h5>{language === "es" ? name : name_english}</h5>
        </Col>
        <Col className="competency-header-description">
          <h5>{language === "es" ? description : description_english}</h5>
        </Col>
      </Col>
      <Col lg={1} className="competency-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/behaviors/competencies/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Competencias.Competencia.Editar")}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`/behaviors/competencies/delete/${id}`}
            >
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Competencias.Competencia.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Competency;
