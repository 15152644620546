import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import "./EditScaleOptionForm.css";

function EditScaleOptionForm({
  id,
  title,
  handleChangeInput,
  english,
  anchor,
  anchor_english,
  description_english,
  description,
  isNa,
}) {
  const { t } = useTranslation();

  return (
    <Col className="edit-scale-option-container">
      <Col className="edit-scale-header">
        <h2>
          {t("Competencias.Escala.EditScaleOptionForm.Nivel")} {title}
        </h2>
      </Col>

      <Col className="edit-scale-option-form">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Ancla conductual</Form.Label>
          <Form.Control
            type="text"
            name="anchor"
            onChange={(e) => handleChangeInput(e, id)}
            value={anchor}
          />
        </Form.Group>
        {english ? (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Behavioral anchor</Form.Label>
            <Form.Control
              type="text"
              name="anchor_english"
              onChange={(e) => handleChangeInput(e, id)}
              value={anchor_english}
            />
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            onChange={(e) => handleChangeInput(e, id)}
            value={description}
          />
        </Form.Group>
        {english ? (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description_english"
              onChange={(e) => handleChangeInput(e, id)}
              value={description_english}
            />
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Check
          type="switch"
          id="default-checkbox"
          label="Es N/A?"
          name="isNa"
          checked={isNa}
          onChange={(e) => handleChangeInput(e, id)}
        />
      </Col>
    </Col>
  );
}

export default EditScaleOptionForm;
