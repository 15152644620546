import React, { useState, useContext, useEffect } from "react";
import parse from "html-react-parser";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "./EditEvaluationForm.css";

function EditEvaluationForm() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [english, setEnglish] = useState(false);

  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [instructions, setInstructions] = useState("");

  const [questionnaire, setQuestionnaire] = useState("");

  const [questionnaires, setQuestionnaires] = useState([]);

  const [secondApproval, setSecondApproval] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  let { eid } = useParams();

  const { t } = useTranslation();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setStartDate(new Date(response.data.period.start_date));
      setEndDate(new Date(response.data.period.end_date));
      setDescription(response.data.period.description);
      setDescriptionEnglish(response.data.period.description_english);
      setTitle(response.data.period.name);
      setTitleEnglish(response.data.period.name_english);
      setType(response.data.period.type);
      setInstructions(`<div>${parse(response.data.period.message)}</div>`);
      setSecondApproval(response.data.period.secondApproval);

      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/type/${state.organization}`,
        data: {
          type: response.data.period.type,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setQuestionnaires(result.data.questionnaires);

      setQuestionnaire(response.data.period.questionnaire);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const fetchQuestionnaires = async (type) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/type/${state.organization}`,
      data: {
        type: type,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setQuestionnaires(response.data.questionnaires);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleChangeSecondApprover = (e) => {
    setSecondApproval(e.target.checked);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    fetchQuestionnaires(e.target.value);
  };

  const handleQuestionnaireChange = (e) => {
    setQuestionnaire(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/${eid}`,
        data: {
          name: title,
          name_english: title_english,
          description: description,
          description_english: description_english,
          organization: state.organization,
          start_date: startDate,
          end_date: endDate,
          english: english,
          type: type,
          message: instructions,
          secondApproval: secondApproval,
          questionnaire: questionnaire,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/evaluations");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Evaluaciones",
      path: "/evaluations",
    },
  ];

  let content = <div></div>;

  if (type === "competencias") {
    content = (
      <Col>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t("Evaluaciones.EditForm.Cuestionario")}</Form.Label>
              <Form.Select
                onChange={handleQuestionnaireChange}
                value={questionnaire}
              >
                <option value="">
                  {t("Evaluaciones.EditForm.SeleccionarCuestionario")}
                </option>
                {questionnaires.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={instructions}
              onChange={setInstructions}
            />
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "encuestas") {
    content = (
      <Col>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t("Evaluaciones.EditForm.Cuestionario")}</Form.Label>
              <Form.Select
                onChange={handleQuestionnaireChange}
                value={questionnaire}
              >
                <option value="">
                  {t("Evaluaciones.EditForm.SeleccionarCuestionario")}
                </option>
                {questionnaires.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={instructions}
              onChange={setInstructions}
            />
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "objetivos") {
    content = (
      <Col>
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="default-checkbox"
            label="Activar segunda aprobación"
            name="secondApprover"
            defaultChecked={secondApproval}
            onChange={handleChangeSecondApprover}
          />
        </Form.Group>
      </Col>
    );
  } else {
    content = <div></div>;
  }

  if (isLoading) {
    return (
      <Spinner animation="border" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="new-evaluation-header">
            <h2>{t("Evaluaciones.EditForm.EditarEvaluacion")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2 right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="new-evaluation-form">
            <Form>
              <Col>
                <FloatingLabel label="Título" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Título"
                    onChange={handleTitleChange}
                    value={title}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Title" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      onChange={handleTitleEnglishChange}
                      value={title_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
                <FloatingLabel label="Descripción" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Descripción"
                    onChange={handleDescriptionChange}
                    value={description}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Description" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      onChange={handleDescriptionEnglishChange}
                      value={description_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
                <FloatingLabel
                  controlId="floatingSelect"
                  label={english ? "Type" : "Tipo"}
                  onChange={handleChangeType}
                  className="mb-3"
                >
                  <Form.Select onChange={handleChangeType} value={type}>
                    <option>
                      {t("Evaluaciones.EditForm.SeleccionarTipo")}
                    </option>
                    <option value="competencias">
                      {t("Evaluaciones.EditForm.Talento")}
                    </option>
                    <option value="objetivos">
                      {t("Evaluaciones.EditForm.Desempeno")}
                    </option>
                    <option value="encuestas">
                      {t("Evaluaciones.EditForm.Bienestar")}
                    </option>
                  </Form.Select>
                </FloatingLabel>

                <Form.Group className="mb-3">
                  <Row>
                    <Col lg={6}>
                      <Form.Label>
                        {t("Evaluaciones.EditForm.FechaInicio")}
                      </Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Form.Label>
                        {t("Evaluaciones.EditForm.FechaTermino")}
                      </Form.Label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              {content}
              <Col className="new-evaluation-buttons mt-3">
                <Form.Group>
                  <Button className="primary" onClick={handleSubmit}>
                    {t("Evaluaciones.EditForm.Guardar")}
                  </Button>
                  <Link to="/evaluations">
                    <Button className="danger">
                      {t("Evaluaciones.EditForm.Cancelar")}
                    </Button>
                  </Link>
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditEvaluationForm;
