import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faMinusSquare,
  faPlusSquare,
  faSquare,
  faUser,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./VerticalOrgChart.css";

function VerticalOrgChart() {
  const [expanded, setExpanded] = useState([]);
  const [checked, setChecked] = useState([]);
  const [nodeData, setNodeData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/tree/vertical/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setNodeData(response.data.nodeData);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Col>
        <Col>
          <h2>{t("Organigrama.OrganigramaVertical")}</h2>
        </Col>
        <Col className="vertical-orgChart">
          <CheckboxTree
            disabled={true}
            nodes={nodeData}
            expanded={expanded}
            onExpand={(item) => setExpanded([...item])}
            checked={checked}
            onCheck={(item) => setChecked([...item])}
            icons={{
              check: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-check"
                  icon={faCheckSquare}
                />
              ),
              uncheck: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-uncheck"
                  icon={faSquare}
                />
              ),
              halfCheck: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-half-check"
                  icon={faCheckSquare}
                />
              ),
              expandClose: (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="rct-icon rct-icon-expand-close"
                />
              ),
              expandOpen: (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="rct-icon rct-icon-expand-open"
                />
              ),
              expandAll: (
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  className="rct-icon rct-icon-expand-all"
                />
              ),
              collapseAll: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-collapse-all"
                  icon={faMinusSquare}
                />
              ),
              parentClose: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-parent-close"
                  icon={faUser}
                />
              ),
              parentOpen: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-parent-open"
                  icon={faUserAlt}
                />
              ),
              leaf: (
                <FontAwesomeIcon
                  className="rct-icon rct-icon-leaf-close"
                  icon={faUser}
                />
              ),
            }}
          />
        </Col>
      </Col>
    );
  }
}

export default VerticalOrgChart;
