import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import "./NewPersonForm.css";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

function NewPersonForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [profile, setProfile] = useState("");
  const [role, setRole] = useState("");
  const [boss, setBoss] = useState("");
  const [inactive, setInactive] = useState(false);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/form/data/${state.organization}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setProfiles(response.data.profiles);
      setUsers(response.data.users);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePidChange = (e) => {
    setEmployeeId(e.target.value);
  };

  const handleProfileChange = (e) => {
    setProfile(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleBossChange = (e) => {
    setBoss(e.target.value);
  };

  const handleInactiveChange = (e) => {
    setInactive(e.target.checked);
  };

  const handleShowChange = (e) => {
    setShow(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          employeeId: employeeId,
          inactive: inactive,
          show_org: show,
          organization: state.organization,
          role: role,
          profile: profile,
          boss: boss,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/people");
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  let breadcrumbs = [
    {
      title: "Personas",
      path: "/people",
    },
  ];

  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col className="new-person-header">
          <h2>{t("Personas.Persona.NewForm.Titulo")}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="new-person-form">
          <Form>
            <Row className="justify-content-center">
              <Col className="center mb-5 mt-5">
                <FontAwesomeIcon icon={faUser} size="10x" className="avatar" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nombres"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Nombres"
                    onChange={handleFirstNameChange}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Apellidos"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Apellidos"
                    onChange={handleLastNameChange}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={handleEmailChange}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Id"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Id"
                    onChange={handlePidChange}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Form.Select aria-label="Perfil" onChange={handleProfileChange}>
                  <option>
                    {t("Personas.Persona.NewForm.SeleccionarPerfil")}
                  </option>
                  {profiles.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="mb-3">
                <Form.Select aria-label="Rol" onChange={handleRoleChange}>
                  <option>
                    {t("Personas.Persona.NewForm.SeleccionarRol")}
                  </option>
                  <option value="user">
                    {t("Personas.Persona.NewForm.Usuario")}
                  </option>
                  <option value="admin">
                    {t("Personas.Persona.NewForm.Administrador")}
                  </option>
                  {state.role === "root" || state.role === "owner" ? (
                    <option value="owner">
                      {t("Personas.Persona.NewForm.Owner")}
                    </option>
                  ) : (
                    <></>
                  )}
                </Form.Select>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleBossChange}
                >
                  <option>{t("Personas.Persona.NewForm.JefeDirecto")}</option>
                  {users.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="mb-3">
                <Form.Check
                  inline
                  label="Inactivo"
                  name="group1"
                  type="switch"
                  onChange={handleInactiveChange}
                />
                <Form.Check
                  inline
                  label="Mostrar en organigrama"
                  name="group2"
                  type="switch"
                  onChange={handleShowChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="primary" onClick={handleSubmit}>
                  {t("Personas.Persona.NewForm.Guardar")}
                </Button>
                <Link to="/people">
                  <Button className="danger">
                    {t("Personas.Persona.NewForm.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewPersonForm;
