import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import NewQuestionForm from "./NewQuestionForm";
import { v4 as uuidv4 } from "uuid";

function QuestionnaireTab({
  id,
  handleChangeInput,
  optionSets,
  getQuestions,
  english,
  questions,
  title,
  title_english,
  questionnaireType,
  language,
}) {
  const [currentQuestions, setCurrentQuestions] = useState([
    {
      _id: uuidv4(),
      label: `Pregunta`,
      label_english: `Question`,
      description: "",
      description_english: "",
      type: "",
      optionSet: "",
      perspectives: [
        {
          _id: uuidv4(),
          perspective: "boss",
          active: false,
        },
        {
          _id: uuidv4(),
          perspective: "auto",
          active: false,
        },
        {
          _id: uuidv4(),
          perspective: "peers",
          active: false,
        },
        {
          _id: uuidv4(),
          perspective: "subordinates",
          active: false,
        },
        {
          _id: uuidv4(),
          perspective: "clients",
          active: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    async function fetch() {
      if (questions.length > 0) {
        setCurrentQuestions(questions);
      }
    }
    fetch();
  }, [questions]);

  const handleAddQuestion = () => {
    setCurrentQuestions([
      ...currentQuestions,
      {
        _id: uuidv4(),
        label: `Pregunta`,
        label_english: `Question`,
        description: "",
        description_english: "",
        type: "",
        optionSet: "",
        perspectives: [
          {
            _id: uuidv4(),
            perspective: "boss",
            active: false,
          },
          {
            _id: uuidv4(),
            perspective: "auto",
            active: false,
          },
          {
            _id: uuidv4(),
            perspective: "peers",
            active: false,
          },
          {
            _id: uuidv4(),
            perspective: "subordinates",
            active: false,
          },
          {
            _id: uuidv4(),
            perspective: "clients",
            active: false,
          },
        ],
      },
    ]);
  };

  const handleRemoveQuestion = (id) => {
    let newQuestions = currentQuestions.filter((item) => item._id !== id);
    setCurrentQuestions(newQuestions);
  };

  const handleChangeInputQuestion = (e, eid) => {
    const newQuestionsData = currentQuestions.map((item) => {
      if (eid === item._id) {
        item[e.target.name] = e.target.value;
      }
      return item;
    });

    setCurrentQuestions(newQuestionsData);
    getQuestions(id, currentQuestions);
  };

  const handleChangeCheckbox = (e, qid) => {
    const newQuestionsData = currentQuestions.map((item) => {
      if (qid === item._id) {
        item.perspectives.map((element) => {
          if (element.perspective === e.target.name) {
            element.active = e.target.checked;
          }
          return element;
        });
      }
      return item;
    });

    setCurrentQuestions(newQuestionsData);
    getQuestions(id, currentQuestions);
  };

  return (
    <Tab.Pane eventKey={id}>
      <Col className="questionnaire-tab mb-3">
        <FloatingLabel
          controlId="floatingInput"
          label="Título"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Título"
            name="title"
            value={title}
            onChange={(e) => handleChangeInput(e, id)}
          />
        </FloatingLabel>
        {english ? (
          <FloatingLabel
            controlId="floatingInput2"
            label="Title"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Title"
              name="title_english"
              onChange={(e) => handleChangeInput(e, id)}
              value={title_english}
            />
          </FloatingLabel>
        ) : (
          <></>
        )}
        <Form.Check
          type="switch"
          id="custom-switch"
          label={
            language === "es" ? "Sección demográfica?" : "Demographic section?"
          }
          name="demographic"
          onChange={(e) => handleChangeInput(e, id)}
        />
      </Col>

      {currentQuestions.map((item) => (
        <NewQuestionForm
          id={item._id}
          key={item._id}
          addquestion={handleAddQuestion}
          handleRemoveQuestion={handleRemoveQuestion}
          label={item.label}
          label_english={item.label_english}
          description={item.description}
          description_english={item.description_english}
          optionSet={item.optionSet}
          type={item.type}
          options={optionSets}
          handleChangeInputQuestion={handleChangeInputQuestion}
          handleChangeCheckbox={handleChangeCheckbox}
          english={english}
          questionnaireType={questionnaireType}
          perspectives={item.perspectives}
          language={language}
        />
      ))}
    </Tab.Pane>
  );
}

export default QuestionnaireTab;
