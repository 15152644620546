import React from "react";
import Rule from "./Rule";
import Col from "react-bootstrap/Col";
import "./RulesList.css";

function RulesList({ rules }) {
  return (
    <Col className="rules-list">
      {rules.map((element) => (
        <Rule
          key={element._id}
          id={element._id}
          title={element.title}
        />
      ))}
    </Col>
  );
}

export default RulesList;
