import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EvaluationStatus from "../Components/EvaluationStatus";
import EvaluationStatusObjectives from "../Components/EvaluationStatusObjectives";
import PendingEvaluation from "../Components/PendingEvaluation";
import PendingTraining from "../Components/PendingTraining";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./Dashboard.css";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [evaluations, setEvaluations] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [pendingEvaluations, setPendingEvaluations] = useState([]);

  const { state, signout } = useContext(AuthContext);

  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/periods/dashboard/${state.userId}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        const result = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/periods/dashboard/pending/${state.organization}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setEvaluations(response.data.evaluations);
        setTrainings(response.data.trainings);
        setPendingEvaluations(result.data.pendingEvaluations);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          signout();
        }
      }
    }
    fetch();
  }, [location.key]);

  if (isLoading === true) {
    return (
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col className="center">
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        {(state.role === "admin" || state.role === "root") &&
        pendingEvaluations.length > 0 ? (
          <Row className="mb-4">
            {pendingEvaluations.map((item) => {
              if (item.type === "competencias" || item.type === "encuestas") {
                return (
                  <EvaluationStatus
                    key={item.periodId}
                    data={[
                      item.totalResponses - item.answeredResponses,
                      item.answeredResponses,
                    ]}
                    periodId={item.periodId}
                    periodName={item.title}
                    periodName_english={item.title_english}
                    type={item.type}
                    role={state.role}
                    language={state.language}
                  />
                );
              } else {
                return (
                  <EvaluationStatusObjectives
                    key={item.periodId}
                    data={[
                      item.createObjectivesResponses,
                      item.inReviewResponses,
                      item.inProgressResponses,
                      item.firstApprovalResponses,
                      item.secondApprovalResponses,
                      item.finishedResponses,
                      item.closedResponses,
                    ]}
                    periodId={item.periodId}
                    periodName={item.title}
                    periodName_english={item.title_english}
                    role={state.role}
                    language={state.language}
                  />
                );
              }
            })}
          </Row>
        ) : (
          <Row>
            <></>
          </Row>
        )}
        {evaluations.length > 0 ? (
          <Row>
            {evaluations.map((item) => (
              <PendingEvaluation
                key={item._id}
                id={item._id}
                periodName={item.periodName}
                periodName_english={item.periodName_english}
                start_date={item.start_date}
                end_date={item.end_date}
                type={item.type}
                status={item.status}
                path={item.path}
                user={item.user}
                language={state.language}
              />
            ))}
          </Row>
        ) : (
          <Row>
            <Col className="mb-4">
              <h1 className="text-center no-eval">
                {t("Dashboard.EvaluacionesPendientes.NoTienes")}
              </h1>
            </Col>
          </Row>
        )}
        {trainings.length > 0 ? (
          <Row>
            {trainings.map((item) => (
              <PendingTraining
                key={item._id}
                id={item._id}
                title={item.title}
                title_english={item.title_english}
                group={item.groupName}
                group_english={item.groupName_english}
                completed_at={item.completed_at}
                evaluatee={item.evaluatee}
                path={item.path}
                language={state.language}
              />
            ))}
          </Row>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}

export default Dashboard;
