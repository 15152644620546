import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import TrainingsList from "../Components/TrainingsList";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faPlus,
  faChalkboardTeacher,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import "./Trainings.css";

function Trainings() {
  const [isLoading, setIsLoading] = useState(true);
  const [trainings, setTrainings] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTrainings(response.data.trainings);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.language, state.token]);

  let breadcrumbs = [
    {
      title: "Planes desarrollo",
      path: "/trainings/training-status",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={9} className="trainings">
            <h2>{t("Capacitaciones.Titulo")}</h2>
          </Col>
          <Col lg={3} className="right trainings-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.role === "admin" ? (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to={`/mytrainings/${state.userId}`}
                    >
                      <FontAwesomeIcon icon={faChalkboardTeacher} />{" "}
                      {t("Capacitaciones.MisCapacitaciones.Titulo")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                ) : (
                  <></>
                )}
                <Dropdown.Item as={Link} to={`/trainings/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Capacitaciones.NuevaCapacitacion")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/trainings/training-groups`}>
                  <FontAwesomeIcon icon={faLayerGroup} />{" "}
                  {t("Capacitaciones.GruposCapacitacion")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {trainings.length === 0 ? (
            <Col className="empty-trainings center">
              <h5>{t("Capacitaciones.SinCapacitaciones")}</h5>
            </Col>
          ) : (
            <TrainingsList trainings={trainings} language={state.language} />
          )}
        </Row>
      </Container>
    );
  }
}

export default Trainings;
