import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import "./EditHobbyForm.css";

function EditHobbyForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  let { hid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/hobbies/${hid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTitle(response.data.hobby.title);
      setTitleEnglish(response.data.hobby.title_english);
      setIsLoading(false);
    }
    fetch();
  }, [state.token, hid]);

  const navigate = useNavigate();

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleTitleEnglishChange = (evt) => {
    setTitleEnglish(evt.target.value);
  };

  const handleTitleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/hobbies/${hid}`,
        data: {
          title: title,
          title_english: title_english,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/hobbies");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Hobbies",
      path: "/hobbies",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="edit-hobby-header">
            <h2>{t("Hobbies.Hobby.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="edit-hobby-form">
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre de la hobby"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nombre de la hobby"
                  value={title}
                  onChange={handleTitleChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Name of the hobby" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name of the hobby"
                    onChange={handleTitleEnglishChange}
                    value={title_english}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <Button className="primary" onClick={handleSubmit}>
                {t("Hobbies.Hobby.EditForm.Guardar")}
              </Button>
              <Link to="/hobbies">
                <Button className="danger">
                  {t("Hobbies.Hobby.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditHobbyForm;
