import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../Context/AuthContext";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScalesList from "../Components/ScalesList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./Scales.css";

function Scales() {
  const [isLoading, setIsLoading] = useState(true);
  const [scales, setScales] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/scales/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setScales(response.data.scales);
      setIsLoading(false);
    }
    fetch();
  }, [state.language, state.organization, state.token]);

  let breadcrumbs = [
    {
      title: "Comportamientos",
      path: "/behaviors",
    },
    {
      title: "Competencias",
      path: "/behaviors/competencies",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={11} className="scales-header">
            <h2>{t("Competencias.Escalas")}</h2>
          </Col>
          <Col lg={1} className="right scales-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/behaviors/competencies/scales/new`}
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Competencias.NuevaEscala")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          {scales.length === 0 ? (
            <Col className="empty-scales center">
              <h5>{t("Competencias.SinEscalas")}</h5>
            </Col>
          ) : (
            <ScalesList scales={scales} language={state.language} />
          )}
        </Row>
      </Container>
    );
  }
}

export default Scales;
