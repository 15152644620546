import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import "./ObjectiveFullfillmentValidation.css";

function ObjectiveFullfillmentValidation({
  id,
  description,
  userComment,
  userScore,
  evidencePath,
  handleChangeInput,
  approverScore,
  approverComment,
  handleDownloadEvidence,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Col className="fullfillment-validation mb-3">
      <Col className="fullfillment-validation-header mb-3">
        <h5>{description}</h5>
      </Col>
      <Col className="fullfillment-validation-content">
        <Row>
          <Col lg={9} className="mb-3">
            <h5>{t("Client.ObjectivesEvaluation.ComentarioUsuario")}</h5>
            <Col>{userComment}</Col>
          </Col>
          <Col lg={3} className="level">
            <h5>{t("Client.ObjectivesEvaluation.CumplimientoReportado")}</h5>
            <Col className="user-score">{userScore}</Col>
          </Col>
        </Row>
        {evidencePath === "" ? (
          <></>
        ) : (
          <Row>
            <Col
              className="download-link mb-3"
              onClick={() => handleDownloadEvidence(evidencePath)}
            >
              {t("Client.ObjectivesEvaluation.ArchivoEvidencia")}
            </Col>
          </Row>
        )}
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={approverComment}
            placeholder={
              language === "es"
                ? "Comentario de justificación para el nivel de cumplimiento seleccionado"
                : "Justification comment for the selected level of compliance"
            }
            name="approverComment"
            onChange={(e) => handleChangeInput(e, id)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Client.ObjectivesEvaluation.NivelDeCumplimiento")}
          </Form.Label>
          <Row className="align-items-start">
            <Col lg={11}>
              <Form.Range
                name="approverScore"
                defaultValue={approverScore}
                max={150}
                onChange={(e) => handleChangeInput(e, id)}
              />
            </Col>
            <Col lg={1} className="center">
              <span className="weightVal">{approverScore}</span>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    </Col>
  );
}

export default ObjectiveFullfillmentValidation;
