import React from "react";
import { Col } from "react-bootstrap";
import Competency from "./Competency";
import "./CompetenciesList.css";

function CompetenciesList({ competencies, language }) {
  return (
    <Col className="competencies-list">
      {competencies.map((element) => (
        <Competency
          key={element._id}
          id={element._id}
          name={element.name}
          description={element.description}
          name_english={element.name_english}
          description_english={element.description_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default CompetenciesList;
