import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./EditRuleForm.css";

function EditRuleForm() {
  const [title, setTitle] = useState("");
  const [perspectives, setPerspectives] = useState([]);
  const [period, setPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/rules/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTitle(response.data.rule.title);
      setPerspectives(response.data.rule.perspectives);
      setPeriod(response.data.rule.periodId);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeInput = (e) => {
    const newPerspectives = perspectives.map((item) => {
      if (e.target.name === item.perspective) {
        item.active = e.target.checked;
      }
      return item;
    });

    setPerspectives(newPerspectives);
  };

  const getLabel = (name) => {
    if (name === "boss") {
      return "Jefe";
    } else if (name === "auto") {
      return "Auto";
    } else if (name === "peers") {
      return "Homólogos";
    } else if (name === "subordinates") {
      return "Subordinados";
    }
  };

  const handleSubmit = async (e) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/rules/${rid}`,
      data: {
        title: title,
        perspectives: perspectives,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate(`/evaluations/rules/${period}`);
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h4>{t("Evaluaciones.Reglas.EditForm.Titulo")}</h4>
          </Col>
        </Row>
        <Row>
          <Col className="new-rule-form">
            <Form>
              <FloatingLabel label="Título" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Título"
                  onChange={handleTitleChange}
                  value={title}
                />
              </FloatingLabel>
              <Form.Group as={Row} className="mb-3">
                {perspectives.map((item) => (
                  <Col key={item._id}>
                    <Form.Check
                      type="switch"
                      label={getLabel(item.perspective)}
                      name={item.perspective}
                      onChange={(e) => handleChangeInput(e)}
                      defaultChecked={item.active}
                    />
                  </Col>
                ))}
              </Form.Group>
              <Form.Group className="new-rule-form-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Evaluaciones.Reglas.EditForm.Guardar")}
                </Button>
                <Link to={`/evaluations/rules/${period}`}>
                  <Button className="danger">
                    {t("Evaluaciones.Reglas.EditForm.Cancelar")}
                  </Button>
                </Link>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditRuleForm;
