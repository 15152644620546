import React from "react";
import "./HobbieBadge.css";

function HobbieBadge({
  id,
  title,
  title_english,
  status,
  toggleStatus,
  language,
}) {
  return (
    <span
      className={status === true ? "active-hobby" : "inactive-hobby"}
      onClick={() => toggleStatus(id)}
    >
      {language === "es" ? title : title_english}
    </span>
  );
}

export default HobbieBadge;
