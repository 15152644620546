import React from "react";
import Col from "react-bootstrap/Col";
import { Bar } from "react-chartjs-2";
import "./StackedBarChart.css";

function StackedBarChart({ labels, datasets }) {
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Resultados por sección",
        font: {
          size: 16
      }
      },
    },
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Col className="comparison-chart-container">
      <Bar data={data} options={options} />
    </Col>
  );
}

export default StackedBarChart;
