import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./PeopleEvaluatedDetails.css";
import PeopleEvaluatedDetailsSelectedPerson from "../Components/PeopleEvaluatedDetailsSelectedPerson";
import PoolPerson from "../Components/PoolPerson";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function PeopleEvaluatedDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [people, setPeople] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [person, setPerson] = useState({});
  const [periodTitle, setPeriodTitle] = useState("");
  const [filter, setFilter] = useState("");

  const { state } = useContext(AuthContext);

  let { eid, pid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/organization/${state.organization}`,
        data: {
          periodId: eid,
          personId: pid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.users);
      setFilteredPeople(response.data.users);
      setSelectedPeople(response.data.selectedUsers);
      setPerson(response.data.person);
      setPeriodTitle(response.data.period.name);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const selectRelationship = (user) => {
    if (person.boss === user._id) {
      return "Jefe directo";
    } else if (user.boss === person._id) {
      return "Subordinado";
    } else if (person._id === user._id) {
      return "Autoevaluación";
    } else if (user.boss === person.boss) {
      return "Homologo";
    } else {
      return "Cliente";
    }
  };

  const handleSelection = (id) => {
    let buffer = people.find((element) => element._id === id);
    let updatedBuffer = {
      _id: buffer._id,
      firstName: buffer.firstName,
      lastName: buffer.lastName,
      profileName: buffer.profileName,
      boss: buffer.boss,
      bossName: buffer.bossName,
      relationship: selectRelationship(buffer),
    };

    let newPeopleArray = people.filter((element) => {
      return element._id !== id;
    });

    setSelectedPeople([...selectedPeople, updatedBuffer]);
    setPeople(newPeopleArray);
    setFilteredPeople(
      newPeopleArray.filter(
        (element) =>
          element.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          element.lastName.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  const handleBackToPool = (id) => {
    let buffer = selectedPeople.find((element) => element._id === id);

    let newSelectedPeopleArray = selectedPeople.filter((element) => {
      return element._id !== id;
    });

    setPeople([...people, buffer]);
    setSelectedPeople(newSelectedPeopleArray);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filterSearch = (e) => {
    if (filter !== "") {
      const filteredPeopleArray = people.filter(
        (element) =>
          element.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          element.lastName.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredPeople(filteredPeopleArray);
    } else {
      setFilteredPeople(people);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/${eid}`,
        data: {
          selectedPeople: selectedPeople,
          people: people,
          personId: pid,
          periodId: eid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/evaluation/${eid}/people`);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  let breadcrumbs = [
    {
      title: "Evaluaciones",
      path: "/evaluations",
    },
    {
      title: `${periodTitle}`,
      path: `/evaluation/${eid}/people`,
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={7}>
            <Form>
              <Col className="header">
                <Row className="align-items-center">
                  <Col>
                    <h5>
                      {person.firstName + " " + person.lastName}
                      {t("Evaluaciones.EsEvaluado")}
                    </h5>
                  </Col>
                </Row>
              </Col>
              <Col className="selected-people">
                {selectedPeople.map((element) => (
                  <PeopleEvaluatedDetailsSelectedPerson
                    key={element._id}
                    id={element._id}
                    firstName={element.firstName}
                    lastName={element.lastName}
                    profile={element.profileName}
                    relationship={element.relationship}
                    handleBackToPool={handleBackToPool}
                  />
                ))}
              </Col>
              <Col className="category-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Evaluaciones.Guardar")}
                </Button>
                <Link to={`/evaluation/${eid}/people`}>
                  <Button className="danger">
                    {t("Evaluaciones.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={5} className="people-pool">
            <Row>
              <Col lg={11}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Buscar persona"
                    onChange={(e) => handleFilterChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col lg={1} className="center">
                <Button
                  className="primary search"
                  onClick={() => filterSearch()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </Col>
            </Row>
            <Col>
              {filteredPeople.map((element) => (
                <PoolPerson
                  key={element._id}
                  id={element._id}
                  firstName={element.firstName}
                  lastName={element.lastName}
                  profile={element.profileName}
                  handleSelection={handleSelection}
                />
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PeopleEvaluatedDetails;
