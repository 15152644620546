import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SelectedBehaviorElement.css";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function SelectedBehaviorElement({
  id,
  competency,
  competency_english,
  title,
  title_english,
  description,
  description_english,
  levels,
  handleBackToPool,
  handleLevelChange,
  expectedLevel,
  language,
}) {
  return (
    <Container key={id} fluid className="selected-behavior-container">
      <Row className="align-items-center">
        {language === "es" ? (
          <Col lg={9}>
            <Col className="selected-behavior-container-header">
              <h5>
                {competency} - <span className="anchor">{title}</span>
              </h5>
            </Col>
            <Col>
              <p>{description}</p>
            </Col>
          </Col>
        ) : (
          <Col lg={9}>
            <Col className="selected-behavior-container-header">
              <h5>
                {competency_english} -{" "}
                <span className="anchor">{title_english}</span>
              </h5>
            </Col>
            <Col>
              <p>{description_english}</p>
            </Col>
          </Col>
        )}

        <Col lg={2}>
          <Form.Select
            aria-label="Exigencia"
            onChange={(e) => handleLevelChange(e, id)}
            value={expectedLevel}
          >
            {levels.map((item) => {
              if (!item.isNa) {
                return (
                  <option key={item._id} value={item.title}>
                    {item.title}
                  </option>
                );
              } else {
                return <></>;
              }
            })}
          </Form.Select>
        </Col>
        <Col lg={1} className="center">
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => handleBackToPool(id)}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default SelectedBehaviorElement;
