import React from "react";
import { NavLink, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Person.css";

function Person({
  id,
  firstName,
  lastName,
  profileName,
  profileNameEnglish,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Row className="person align-items-center">
      <Col lg={11} className="person-header">
        <h5>
          <NavLink to={`/people/overview/${id}`}>
            {firstName} {lastName}
          </NavLink>
        </h5>
        <p>{language === "es" ? profileName : profileNameEnglish}</p>
      </Col>
      <Col lg={1} className="person-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/people/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} /> {t("Personas.Persona.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/people/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Personas.Persona.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Person;
