import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import axios from "axios";
import PeopleWillEvaluateList from "../Components/PeopleWillEvaluateList";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

function PeopleWillEvaluate() {
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState([]);

  const { state } = useContext(AuthContext);

  let { eid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/evaluators/evaluation/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.people);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="people-will-evaluate">
            <Col>
              <h2>{t("Evaluaciones.TotalEvaluaciones")}</h2>
            </Col>
            <PeopleWillEvaluateList people={people} evaluation={eid} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PeopleWillEvaluate;
