import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import "./EditTrainingForm.css";

function EditTrainingForm() {
  const [name, setName] = useState("");
  const [expiration, setExpiration] = useState(999);
  const [name_english, setNameEnglish] = useState("");
  const [english, setEnglish] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();
  const [expires, setExpires] = useState(false);

  const { state } = useContext(AuthContext);

  let { tid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainingGroups/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setGroups(result.data.trainingGroups);

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/${tid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setName(response.data.training.name);
      setNameEnglish(response.data.training.name_english);
      setExpiration(response.data.training.expiration);
      setGroup(response.data.training.trainingGroup);
      setExpires(response.data.training.expires);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleExpirationChange = (e) => {
    setExpiration(e.target.value);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
  };

  const handleExpiresChange = (e) => {
    setExpires(e.target.checked);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/${tid}`,
        data: {
          name: name,
          name_english: name_english,
          expiration: expiration,
          expires: expires,
          organization: state.organization,
          group: group,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/trainings");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Capacitaciones",
      path: "/trainings",
    },
  ];

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="new-training-header">
            <h2>{t("Capacitaciones.Capacitacion.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="new-training-form">
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre de la capacitacion"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nombre de la capacitacion"
                  onChange={handleNameChange}
                  value={name}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Training title" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Training title"
                    onChange={handleNameEnglishChange}
                    value={name_english}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <Form.Group className="mb-3">
                <Form.Select
                  aria-label="Seleccionar grupo"
                  onChange={handleGroupChange}
                  value={group}
                >
                  <option value="">
                    {t("Capacitaciones.Capacitacion.EditForm.SeleccionarGrupo")}
                  </option>
                  {groups.map((item) => (
                    <option key={item._id} value={item._id}>
                      {state.language === "es" ? item.name : item.name_english}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  label={english ? "Expires?" : "Expira?"}
                  type="switch"
                  checked={expires}
                  onChange={handleExpiresChange}
                />
              </Form.Group>

              {expires ? (
                <FloatingLabel
                  label={english ? "Expiration in months" : "Vigencia en meses"}
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="# de meses"
                    value={expiration}
                    onChange={handleExpirationChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <Button className="primary" onClick={handleSubmit}>
                {t("Capacitaciones.Capacitacion.EditForm.Guardar")}
              </Button>
              <Link to="/trainings">
                <Button className="danger">
                  {t("Capacitaciones.Capacitacion.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditTrainingForm;
