import React from "react";
import { Col } from "react-bootstrap";
import ReportPerson from "./ReportPerson";
import "./ReportPeopleList.css";

function ReportPeopleList({ people, reportId, language }) {
  return (
    <Col className="report-people-list">
      {people !== undefined
        ? people.map((element) => (
            <ReportPerson
              key={element._id}
              id={element._id}
              name={element.name}
              profile={element.profile}
              profile_english={element.profile_english}
              reportId={reportId}
              language={language}
            />
          ))
        : ""}
    </Col>
  );
}

export default ReportPeopleList;
