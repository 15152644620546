import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import {
  faList,
  faRulerCombined,
  faEye,
  faAngleDoubleLeft,
  faRecycle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import SelectedPerson from "../Components/SelectedPerson";
import PoolPerson from "../Components/PoolPerson";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import "./PeopleEvaluated.css";

function PeopleEvaluated() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [people, setPeople] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [type, setType] = useState("");
  const [filter, setFilter] = useState("");

  const { state } = useContext(AuthContext);

  let { eid } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods/organization/${state.organization}`,
        data: {
          periodId: eid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setPeople(response.data.users);
      setFilteredPeople(response.data.users);
      setSelectedPeople(response.data.selectedUsers);
      setType(response.data.type);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.token, eid]);

  const handleSelection = (id) => {
    let buffer = people.find((element) => element._id === id);

    let newPeopleArray = people.filter((element) => {
      return element._id !== id;
    });

    setSelectedPeople([...selectedPeople, buffer]);
    setPeople(newPeopleArray);
    setFilteredPeople(
      newPeopleArray.filter(
        (element) =>
          element.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          element.lastName.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  const handleBackToPool = (id) => {
    let buffer = selectedPeople.find((element) => element._id === id);

    let newSelectedPeopleArray = selectedPeople.filter((element) => {
      return element._id !== id;
    });

    setPeople([...people, buffer]);
    setFilteredPeople([...people, buffer]);
    setSelectedPeople(newSelectedPeopleArray);
  };

  const handleMassiveSelection = () => {
    setSelectedPeople([...selectedPeople, ...people]);
    setPeople([]);
    setFilteredPeople([]);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filterSearch = (e) => {
    if (filter !== "") {
      const filteredPeopleArray = people.filter(
        (element) =>
          element.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          element.lastName.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredPeople(filteredPeopleArray);
    } else {
      setFilteredPeople(people);
    }
  };

  const updateBosses = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/update/bosses/${eid}`,
        data: {
          selectedPeople: selectedPeople,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setIsLoading(false);
      toast(response.data.message);
    } catch (error) {
      toast(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    if (type === "competencias") {
      try {
        await axios({
          method: "put",
          url: `${process.env.REACT_APP_BACKEND_URL}/competencyEvaluations/${eid}`,
          data: {
            selectedPeople: selectedPeople,
            people: people,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        navigate("/evaluations");
      } catch (error) {
        setIsLoading(false);
        toast(error.response.data.message);
        console.log(error.response.data);
      }
    } else if (type === "objetivos") {
      try {
        await axios({
          method: "put",
          url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/${eid}`,
          data: {
            selectedPeople: selectedPeople,
            people: people,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        navigate("/evaluations");
      } catch (error) {
        setIsLoading(false);
        console.log(error.response.data.message);
      }
    } else if (type === "encuestas") {
      try {
        await axios({
          method: "put",
          url: `${process.env.REACT_APP_BACKEND_URL}/questionnaireEvaluations/${eid}`,
          data: {
            selectedPeople: selectedPeople,
            people: people,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        navigate("/evaluations");
      } catch (error) {
        setIsLoading(false);
        console.log(error.response.data.message);
      }
    }
  };

  let breadcrumbs = [
    {
      title: "Evaluaciones",
      path: "/evaluations",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <ToastContainer />
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={7}>
            <Form>
              <Col className="header">
                <Row className="align-items-center">
                  <Col lg={11}>
                    <h5>{t("Evaluaciones.PersonasEvaluadas")}</h5>
                  </Col>
                  {type === "competencias" ? (
                    <Col lg={1}>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <FontAwesomeIcon icon={faList} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={`/evaluations/rules/${eid}`}
                          >
                            <FontAwesomeIcon icon={faRulerCombined} />{" "}
                            {t("Evaluaciones.ReglasEvaluacion")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/evaluation/${eid}/altpeople`}
                          >
                            <FontAwesomeIcon icon={faEye} />{" "}
                            {t("Evaluaciones.VistaEvaluadores")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : type === "objetivos" ? (
                    <Col lg={1}>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <FontAwesomeIcon icon={faList} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => updateBosses()}>
                            <FontAwesomeIcon icon={faRecycle} />{" "}
                            {t("Evaluaciones.ActualizarJefes")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
              <Col className="selected-people">
                {selectedPeople.map((element) => (
                  <SelectedPerson
                    key={element._id}
                    id={element._id}
                    firstName={element.firstName}
                    lastName={element.lastName}
                    profile={element.profileName}
                    evaluationId={eid}
                    handleBackToPool={handleBackToPool}
                    type={type}
                  />
                ))}
              </Col>
              <Col className="category-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Evaluaciones.Guardar")}
                </Button>
                <Link to="/evaluations">
                  <Button className="danger">
                    {t("Evaluaciones.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={5} className="people-pool">
            <Col className="mb-4">
              <Row className="justify-content-left align-items-center">
                <Col lg={11}>
                  <h6>{t("Evaluaciones.PersonasDisponibles")}</h6>
                </Col>
                <Col lg={1}>
                  <FontAwesomeIcon
                    icon={faAngleDoubleLeft}
                    onClick={handleMassiveSelection}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-left align-items-center">
                <Col lg={11}>
                  <Form.Control
                    type="text"
                    placeholder="Buscar persona"
                    onChange={(e) => handleFilterChange(e)}
                  />
                </Col>
                <Col lg={1} className="center">
                  <Button
                    className="primary search"
                    onClick={() => filterSearch()}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              {filteredPeople.map((element) => (
                <PoolPerson
                  key={element._id}
                  id={element._id}
                  firstName={element.firstName}
                  lastName={element.lastName}
                  profile={element.profileName}
                  handleSelection={handleSelection}
                />
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PeopleEvaluated;
