import React from "react";
import RankingReportPerson from "./RankingReportPerson";
import { Col } from "react-bootstrap";
import "./RankingReportPeopleList.css";

function RankingReportPeopleList({ data, language }) {
  let counter = 0;
  return (
    <Col className="ranking-report-container">
      {data.map((element) => {
        counter = counter + 1;

        return (
          <RankingReportPerson
            key={element._id}
            id={element._id}
            name={element.name}
            profile={element.profile}
            profile_english={element.profile_english}
            grade={element.grade}
            type={element.type}
            number={counter}
            language={language}
          />
        );
      })}
    </Col>
  );
}

export default RankingReportPeopleList;
