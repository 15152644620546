import React from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./SelectedPerson.css";

function SelectedPerson({
  id,
  firstName,
  lastName,
  profile,
  handleBackToPool,
  evaluationId,
  type,
}) {
  return (
    <Container key={id} fluid className="selected-person">
      <Row className="align-items-center">
        <Col lg={10}>
          <Col>
            {type === "competencias" ? (
              <NavLink to={`/evaluation/${evaluationId}/person/${id}`}>
                <h5>
                  {firstName} {lastName}
                </h5>
              </NavLink>
            ) : (
              <h5>
                {firstName} {lastName}
              </h5>
            )}
          </Col>
          <Col>
            <p>{profile}</p>
          </Col>
        </Col>
        <Col lg={2} className="center">
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => handleBackToPool(id)}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default SelectedPerson;
