import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import OrganizationChart from "@dabeng/react-orgchart";
import "./ObjectivesEvaluationMap.css";

function ObjectivesEvaluationMap() {
  const [selected, setSelected] = useState([]);

  const [objectives, setObjectives] = useState([]);

  const [orgChart, setOrgChart] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/map/performance/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setOrgChart(response.data.root);
    }
    fetch();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Spinner animation="border" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Col>
        <Col className="objectives-map">
          <OrganizationChart
            datasource={orgChart}
            pan={true}
            zoom={true}
            collapsible={true}
            draggable={false}
          />
        </Col>
      </Col>
    );
  }
}

export default ObjectivesEvaluationMap;
