import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CategoriesList from "../Components/CategoriesList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./Categories.css";

function Categories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setCategories(response.data.categories);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.token, state.language]);

  const handleDownloadReport = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/categories/report/${state.organization}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = response.data.key;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={11} className="categories-header">
            <h2>{t("Categorias.Titulo")}</h2>
          </Col>
          <Col lg={1} className="right categories-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/categories/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Categorias.NuevaCategoria")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDownloadReport()}>
                  <FontAwesomeIcon icon={faDownload} />{" "}
                  {t("Categorias.Descargar")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {categories.length === 0 ? (
            <Col className="empty-categories center">
              <h5>{t("Categorias.SinCategorias")}</h5>
            </Col>
          ) : (
            <CategoriesList categories={categories} language={state.language} />
          )}
        </Row>
      </Container>
    );
  }
}

export default Categories;
