import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import ReactQuill, { Quill } from "react-quill";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "./NewEvaluationForm.css";

function NewEvaluationForm() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [english, setEnglish] = useState(false);

  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [instructions, setInstructions] = useState("");

  const [questionnaire, setQuestionnaire] = useState("");

  const [questionnaires, setQuestionnaires] = useState([]);

  const [secondApproval, setSecondApproval] = useState(false);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const fetchQuestionnaires = async (type) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/type/${state.organization}`,
      data: {
        type: type,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setQuestionnaires(response.data.questionnaires);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleChangeSecondApprover = (e) => {
    setSecondApproval(e.target.checked);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    fetchQuestionnaires(e.target.value);
  };

  const handleQuestionnaireChange = (e) => {
    setQuestionnaire(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/periods`,
        data: {
          name: title,
          name_english: title_english,
          description: description,
          description_english: description_english,
          organization: state.organization,
          start_date: startDate,
          end_date: endDate,
          english: english,
          type: type,
          message: instructions,
          secondApproval: secondApproval,
          questionnaire: questionnaire,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/evaluations");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Evaluaciones",
      path: "/evaluations",
    },
  ];

  let content = <div></div>;

  if (type === "competencias") {
    content = (
      <Col>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t("Evaluaciones.NewForm.Cuestionario")}</Form.Label>
              <Form.Select onChange={handleQuestionnaireChange}>
                <option value="">
                  {t("Evaluaciones.NewForm.SeleccionarCuestionario")}
                </option>
                {questionnaires.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={instructions}
              onChange={setInstructions}
            />
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "encuestas") {
    content = (
      <Col>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t("Evaluaciones.NewForm.Cuestionario")}</Form.Label>
              <Form.Select onChange={handleQuestionnaireChange}>
                <option value="">
                  {t("Evaluaciones.NewForm.SeleccionarCuestionario")}
                </option>
                {questionnaires.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={instructions}
              onChange={setInstructions}
            />
          </Col>
        </Row>
      </Col>
    );
  } else if (type === "objetivos") {
    content = (
      <Col>
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="default-checkbox"
            label="Activar segunda aprobación"
            name="secondApprover"
            onChange={handleChangeSecondApprover}
          />
        </Form.Group>
      </Col>
    );
  } else {
    content = <div></div>;
  }

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="new-evaluation-header">
          <h2>{t("Evaluaciones.NewForm.Titulo")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2 right">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="new-evaluation-form">
          <Form>
            <Col>
              <FloatingLabel label="Título" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Título"
                  onChange={handleTitleChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Title" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    onChange={handleTitleEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel label="Descripción" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  onChange={handleDescriptionChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Description" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onChange={handleDescriptionEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel
                controlId="floatingSelect"
                label={english ? "Type" : "Tipo"}
                onChange={handleChangeType}
                className="mb-3"
              >
                <Form.Select onChange={handleChangeType}>
                  <option>{t("Evaluaciones.NewForm.SeleccionarTipo")}</option>
                  <option value="competencias">
                    {t("Evaluaciones.NewForm.Talento")}
                  </option>
                  <option value="objetivos">
                    {t("Evaluaciones.NewForm.Desempeno")}
                  </option>
                  <option value="encuestas">
                    {t("Evaluaciones.NewForm.Bienestar")}
                  </option>
                </Form.Select>
              </FloatingLabel>

              <Form.Group className="mb-3">
                <Row>
                  <Col lg={6}>
                    <Form.Label>
                      {t("Evaluaciones.NewForm.FechaInicio")}
                    </Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Label>
                      {t("Evaluaciones.NewForm.FechaTermino")}
                    </Form.Label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            {content}
            <Col className="new-evaluation-buttons mt-3">
              <Form.Group>
                <Button className="primary" onClick={handleSubmit}>
                  {t("Evaluaciones.NewForm.Guardar")}
                </Button>
                <Link to="/evaluations">
                  <Button className="danger">
                    {t("Evaluaciones.NewForm.Cancelar")}
                  </Button>
                </Link>
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewEvaluationForm;
