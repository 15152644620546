import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

function WeightsContainer({ handleWeightsChange, weights }) {
  const { t } = useTranslation();

  const getWeight = (name) => {
    if (weights) {
      const weight = weights.find((weight) => weight.perspective === name);
      if (weight) {
        return weight.weight;
      }
    } else {
      return 0;
    }
  };

  return (
    <Col className="weights-container mb-3">
      <h5>{t("Reportes.Ponderaciones")}</h5>
      <Row>
        <Col>
          <Form.Label>{t("Reportes.Jefe")}</Form.Label>
          <Form.Control
            name="boss"
            onChange={(e) => handleWeightsChange(e)}
            defaultValue={getWeight("boss")}
          />
        </Col>
        <Col>
          <Form.Label>{t("Reportes.Auto")}</Form.Label>
          <Form.Control
            name="auto"
            onChange={(e) => handleWeightsChange(e)}
            defaultValue={getWeight("auto")}
          />
        </Col>
        <Col>
          <Form.Label>{t("Reportes.Homologos")}</Form.Label>
          <Form.Control
            name="peers"
            onChange={(e) => handleWeightsChange(e)}
            defaultValue={getWeight("peers")}
          />
        </Col>
        <Col>
          <Form.Label>{t("Reportes.Subordinados")}</Form.Label>
          <Form.Control
            name="subordinates"
            onChange={(e) => handleWeightsChange(e)}
            defaultValue={getWeight("subordinates")}
          />
        </Col>
        <Col>
          <Form.Label>{t("Reportes.Clientes")}</Form.Label>
          <Form.Control
            name="clients"
            onChange={(e) => handleWeightsChange(e)}
            defaultValue={getWeight("clients")}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default WeightsContainer;
