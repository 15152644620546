import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Wizard } from "react-use-wizard";
import axios from "axios";
import CompetencyStep from "../Components/CompetencyStep";
import MessageStep from "../Components/MessageStep";
import QuestionStep from "../Components/QuestionStep";
import Spinner from "react-bootstrap/Spinner";

function CompetencyEvaluation() {
  const [steps, setSteps] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [period, setPeriod] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  let { eid, pid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/evaluation`,
        data: {
          periodId: eid,
          evaluatorId: pid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setSteps(response.data.steps);
      setQuestions(response.data.questions);
      setPeriod(response.data.period);
    }
    fetch();
    setIsLoading(false);
  }, [state.token, eid, pid]);

  const updateSteps = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses/partial/update/steps`,
        data: {
          steps: steps,
          periodId: eid,
          evaluatorId: pid,
          questions: questions,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencyResponses`,
        data: {
          steps: steps,
          periodId: eid,
          evaluatorId: pid,
          questions: questions,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setShow(false);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleRadioClick = (behavior, answer, value) => {
    let tempSteps = [];
    tempSteps = steps.map((element) => {
      if (element._id === behavior) {
        let tempAnswers = element.answers.map((item) => {
          if (item._id === answer) {
            return {
              evaluateeId: item.evaluateeId,
              evaluateeBoss: item.evaluateeBoss,
              name: item.name,
              value: value,
              _id: item._id,
            };
          } else {
            return item;
          }
        });
        return {
          _id: element._id,
          name: element.name,
          name_english: element.name_english,
          levels: element.levels,
          description: element.description,
          description_english: element.description_english,
          competencyName: element.competencyName,
          competencyName_english: element.competencyName_english,
          answers: tempAnswers,
        };
      } else {
        return element;
      }
    });
    setSteps(tempSteps);
  };

  const handleOpenQuestionChange = (questionId, answerId, e) => {
    let tempQuestions = [];
    tempQuestions = questions.map((element) => {
      if (element._id === questionId) {
        let tempAnswers = element.answers.map((element) => {
          if (element._id === answerId) {
            return {
              evaluateeId: element.evaluateeId,
              name: element.name,
              value: e.target.value,
              _id: element._id,
            };
          } else {
            return element;
          }
        });
        return {
          _id: element._id,
          questionId: element.questionId,
          questionDescription: element.questionDescription,
          questionDescription_english: element.questionDescription_english,
          answers: tempAnswers,
        };
      } else {
        return element;
      }
    });
    setQuestions(tempQuestions);
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Wizard>
              <MessageStep
                periodName={period.name}
                periodName_english={period.name_english}
                periodMessage={period.message}
                language={state.language}
              />
              {steps !== undefined ? (
                steps.map((element) => (
                  <CompetencyStep
                    key={element._id}
                    id={element._id}
                    name={element.name}
                    name_english={element.name_english}
                    description={element.description}
                    description_english={element.description_english}
                    competencyName={element.competencyName}
                    competencyName_english={element.competencyName_english}
                    levels={element.levels}
                    answers={element.answers}
                    handleRadioClick={handleRadioClick}
                    handleSubmit={handleSubmit}
                    updateSteps={updateSteps}
                    show={show}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    periodName={period.name}
                    periodName_english={period.name_english}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleShowModal={handleShowModal}
                    language={state.language}
                  />
                ))
              ) : (
                <></>
              )}
              {questions.map((item) => (
                <QuestionStep
                  key={item._id}
                  id={item._id}
                  description={item.questionDescription}
                  description_english={item.questionDescription_english}
                  answers={item.answers}
                  periodName={period.name}
                  periodName_english={period.name_english}
                  handleSubmit={handleSubmit}
                  show={show}
                  handleClose={handleClose}
                  handleShow={handleShow}
                  handleOpenQuestionChange={handleOpenQuestionChange}
                  language={state.language}
                />
              ))}
            </Wizard>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CompetencyEvaluation;
