import React from "react";
import { Line } from "react-chartjs-2";
import Col from "react-bootstrap/Col";
import "./PersonEvolution.css";

function PersonEvolution({ datasource }) {
  const options = {
    scales: {
      y: {
        suggestedMin: 50,
        suggestedMax: 100,
      },
    },
  };

  return (
    <Col className="person-evolution">
      <Line data={datasource} options={options} />
    </Col>
  );
}

export default PersonEvolution;
