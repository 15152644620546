import React from "react";
import { Col } from "react-bootstrap";
import "./PeopleWillEvaluateList.css";
import PersonWillEvaluate from "./PersonWillEvaluate";

function PeopleWillEvaluateList({ people, evaluation }) {
  return (
    <Col className="people-will-evaluate-list">
      {people.map((element) => (
        <PersonWillEvaluate
          key={element._id}
          id={element._id}
          name={element.name}
          profile={element.profileName}
          amount={element.peopleEvaluated}
          evaluation={evaluation}
        />
      ))}
    </Col>
  );
}

export default PeopleWillEvaluateList;
