import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ProfileSuccesor.css";

function ProfileSuccesor({
  id,
  firstName,
  lastName,
  profileName,
  deleteSuccesor,
}) {
  const { t } = useTranslation();

  return (
    <Row className="profile align-items-center">
      <Col lg={11} className="profile-header">
        <Col className="profile-header-title">
          <h5>
            {firstName} {lastName}
          </h5>
        </Col>
        <Col className="profile-header-description">
          <h5>{profileName}</h5>
        </Col>
      </Col>
      <Col lg={1} className="profile-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => deleteSuccesor(id)}>
              <FontAwesomeIcon icon={faTrash} /> {t("Perfiles.Perfil.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default ProfileSuccesor;
