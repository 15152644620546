import React from "react";
import { Col } from "react-bootstrap";
import Account from "./Account";
import "./AccountsList.css";

function AccountsList({ accounts }) {
  return (
    <Col className="accounts-list">
      {accounts.map((element) => (
        <Account key={element._id} id={element._id} name={element.name} />
      ))}
    </Col>
  );
}

export default AccountsList;
