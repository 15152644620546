import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./EditMyTrainingForm.css";

function EditMyTrainingForm() {
  const [training, setTraining] = useState({});
  const [trainingDate, setTrainingDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState({});

  let { tid, uid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/training/select`,
        data: {
          userId: uid,
          trainingId: tid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTraining(response.data.training);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleEvidenceChange = (e) => {
    setFile({ name: e.target.name, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    const formData = new FormData();
    formData.append(file.name, file.file);
    formData.append("userId", uid);
    formData.append("trainingId", tid);
    formData.append("completed_at", trainingDate);
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/training/select`,
        data: formData,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate(`/mytrainings/${uid}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="new-mytraining-header">
            <h2>{training.name}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="new-mytraining-form">
            <Form>
              <Form.Label>
                {t("Capacitaciones.MiCapacitacion.FechaCumplimiento")}
              </Form.Label>
              <DatePicker
                selected={trainingDate}
                onChange={(date) => setTrainingDate(date)}
                value={
                  training.completed_date ? training.completed_date : new Date()
                }
                className="mb-2"
              />
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Capacitaciones.MiCapacitacion.ArchivoEvidencia")}
                </Form.Label>
                <Form.Control
                  type="file"
                  name="evidence"
                  onChange={(e) => handleEvidenceChange(e)}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleSubmit}>
                {t("Capacitaciones.MiCapacitacion.MandarAprobacion")}
              </Button>
              <Link to={`/mytrainings/${uid}`}>
                <Button variant="danger">
                  {t("Capacitaciones.MiCapacitacion.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditMyTrainingForm;
