import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./PendingCompPerson.css";

function PendingCompPerson({
  status,
  firstName,
  lastName,
  id,
  toggleCompetenciesEvaluation,
}) {
  const { state } = useContext(AuthContext);
  return (
    <Col className="pending-comp-person">
      <Row className="align-items-center">
        <Col lg={8} className="pending-comp-person-header">
          <h5>
            {firstName} {lastName}
          </h5>
        </Col>
        <Col lg={4}>
          <Row>
            <Col lg={9} className="center status">
              {status}
            </Col>
            {state.role === "admin" || state.role === "root" ? (
              <Col lg={3} className="center">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  defaultChecked={status === "active"}
                  onChange={() => toggleCompetenciesEvaluation(id, status)}
                />
              </Col>
            ) : (
              <> </>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default PendingCompPerson;
