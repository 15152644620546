import React from "react";
import { Col } from "react-bootstrap";
import Profile from "./Profile";
import "./ProfilesList.css";

function ProfilesList({ profiles, language }) {
  return (
    <Col className="profiles-list">
      {profiles.map((element) => (
        <Profile
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          description={element.description}
          description_english={element.description_english}
          length={element.length}
          language={language}
        />
      ))}
    </Col>
  );
}

export default ProfilesList;
