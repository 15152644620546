import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import "./ObjectiveFullfillmentContainer.css";

function ObjectiveFullfillmentContainer({
  id,
  description,
  handleChangeInput,
  handleEvidenceChange,
  userScore,
  userComment,
  language,
}) {
  const { t } = useTranslation();
  return (
    <Col className="fullfillment-container mb-3">
      <Col className="fullfillment-container-header mb-3">
        <h5>{description}</h5>
      </Col>
      <Col className="fullfillment-container-content">
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={
              language === "es"
                ? "Comentario de soporte sobre el nivel que reportarás"
                : "Support comment about the level you will report"
            }
            name="userComment"
            value={userComment}
            onChange={(e) => handleChangeInput(e, id)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            {t("Client.ObjectivesEvaluation.SubirEvidencia")}
          </Form.Label>
          <Form.Control
            type="file"
            name={id.toString()}
            onChange={(e) => handleEvidenceChange(e)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Client.ObjectivesEvaluation.NivelDeCumplimiento")}
          </Form.Label>
          <Row className="align-items-start">
            <Col lg={11}>
              <Form.Range
                defaultValue={userScore}
                name="userScore"
                max={150}
                onChange={(e) => handleChangeInput(e, id)}
              />
            </Col>
            <Col lg={1} className="center">
              <span className="weightVal">{userScore}</span>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    </Col>
  );
}

export default ObjectiveFullfillmentContainer;
