import React from "react";
import Col from "react-bootstrap/Col";
import { Pie } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import "./EvaluationStatus.css";

function EvaluationStatus({
  data,
  periodId,
  periodName,
  periodName_english,
  type,
  role,
  language,
}) {
  let labels = [];
  if (language === "es") {
    labels = ["Faltantes", "Completadas"];
  } else if (language === "en") {
    labels = ["Pending", "Completed"];
  }
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: data,
        backgroundColor: [
          "rgba(40, 151, 221, 0.2)",
          "rgba(108, 220, 168, 0.2)",
        ],
        borderColor: ["rgba(40, 151, 221, 1)", "rgba(108, 220, 168, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Col lg={3} className="mb-3">
      <Col className={`dashboard-graph-container ${type}`}>
        <Col className="dashboard-graph-header center">
          {role === "admin" || role === "root" ? (
            <NavLink
              className="nav-link"
              to={`/pending/evaluation/${periodId}`}
            >
              <h6>{language === "es" ? periodName : periodName_english}</h6>
            </NavLink>
          ) : (
            <h6>{language === "es" ? periodName : periodName_english}</h6>
          )}
        </Col>
        <Col className="dashboard-graph-content">
          <Pie data={chartData} options={options} />
        </Col>
      </Col>
    </Col>
  );
}

export default EvaluationStatus;
