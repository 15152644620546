import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./CategoryAssignment.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

function CategoryAssignment() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [people, setPeople] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const { state } = useContext(AuthContext);

  let { cid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/${cid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/users/organization/${state.organization}`,
        data: {
          category: cid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setCategoryName(result.data.category.name);
      setPeople(response.data.users);
      setSelectedPeople(response.data.selectedUsers);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const navigate = useNavigate();

  const handleSelection = (id) => {
    let buffer = people.find((element) => element._id === id);

    let newPeopleArray = people.filter((element) => {
      return element._id !== id;
    });

    setSelectedPeople([...selectedPeople, buffer]);
    setPeople(newPeopleArray);
  };

  const handleBackToPool = (id) => {
    let buffer = selectedPeople.find((element) => element._id === id);

    let newSelectedPeopleArray = selectedPeople.filter((element) => {
      return element._id !== id;
    });

    setPeople([...people, buffer]);
    setSelectedPeople(newSelectedPeopleArray);
  };

  const handleSubmit = async (e) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/categories/users/assign/${cid}`,
      data: {
        selectedPeople: selectedPeople,
        people: people,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/categories");
  };

  let breadcrumbs = [
    {
      title: "Categorias",
      path: "/categories",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={9}>
            <Form>
              <Col className="header">
                <h5>{categoryName}</h5>
              </Col>
              <Col className="selected-people">
                {selectedPeople.map((element) => (
                  <Container key={element._id} fluid className="person">
                    <Row className="align-items-center">
                      <Col lg={11}>
                        <Col>
                          <h5>
                            {element.firstName} {element.lastName}
                          </h5>
                        </Col>
                        <Col>
                          <p>{element.profileName}</p>
                        </Col>
                      </Col>
                      <Col lg={1} className="center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          onClick={() => handleBackToPool(element._id)}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Col>
              <Col className="category-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Categorias.Assignment.Guardar")}
                </Button>
                <Link to="/categories">
                  <Button className="danger">
                    {t("Categorias.Assignment.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={3} className="people-pool">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="text" placeholder="Buscar persona" />
              </Form.Group>
            </Col>
            <Col>
              {people.map((element) => (
                <Row
                  key={element._id}
                  className="align-items-center people-pool-item"
                >
                  <Col lg={2}>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={() => handleSelection(element._id)}
                    />
                  </Col>
                  <Col lg={10} className="right">
                    <Col>
                      <h5>
                        {element.firstName} {element.lastName}
                      </h5>
                    </Col>
                    <Col>
                      <h6>{element.profileName}</h6>
                    </Col>
                  </Col>
                </Row>
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CategoryAssignment;
