import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GeneralDataPanel from "./GeneralDataPanel";
import ObjectiveItem from "./ObjectiveItem";
import "./ReportDetails.css";
import ChartsPanel from "./ChartsPanel";
import PerspectivesTable from "./PerspectivesTable";
import CompetenciesPanelsList from "./CompetenciesPanelsList";
import FeedbackPanel from "./FeedbackPanel";
import axios from "axios";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import ScoresPanel from "./ScoresPanel";

function ReportDetails() {
  let { rid, pid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState({});
  const [reportTitle, setReportTitle] = useState("");
  const [details, setDetails] = useState(false);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/data`,
        data: {
          reportId: rid,
          personId: pid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setReportData(response.data.reportData);
      setReportTitle(response.data.reportData.report.title);
      setIsLoading(false);
    }
    fetch();
  }, [state.token, rid, pid]);

  const handleDetailsStatus = () => {
    setDetails(!details);
  };

  const getCompetencyLabels = (data) => {
    let labels = [];
    for (const iterator of data) {
      if (state.language === "es") {
        labels.push(iterator.competency);
      } else if (state.language === "en") {
        labels.push(iterator.competency_english);
      }
    }
    return labels;
  };

  const getCompetencyValues = (data) => {
    let values = [];
    for (const iterator of data) {
      values.push(iterator.mean);
    }
    return values;
  };

  const getExpectedCompetencyValues = (data) => {
    let values = [];
    for (const iterator of data) {
      values.push(iterator.expected);
    }
    return values;
  };

  const getBehaviorLabels = (dataArray) => {
    let labels = [];
    for (const iterator of dataArray) {
      if (state.language === "es") {
        labels.push(iterator.behavior);
      } else if (state.language === "en") {
        labels.push(iterator.behavior_english);
      }
    }
    return labels;
  };

  const getBehaviorValues = (data) => {
    let values = [];
    for (const iterator of data) {
      values.push(iterator.mean);
    }
    return values;
  };

  const getExpectedBehaviorValues = (dataArray) => {
    let values = [];
    for (const iterator of dataArray) {
      values.push(iterator.expected);
    }
    return values;
  };

  const generateContent = (data) => {
    if (data.period.type === "objetivos") {
      return (
        <>
          {data.objectives.map((item) => (
            <ObjectiveItem
              key={item._id}
              id={item._id}
              title={item.description}
              user_comment={item.userComment}
              approver_comment={item.approverComment}
              grade={item.approverScore}
            />
          ))}
        </>
      );
    } else if (data.period.type === "competencias") {
      return (
        <>
          <ChartsPanel
            competencyLabels={getCompetencyLabels(data.competencyData)}
            competencyAnswers={getCompetencyValues(data.competencyData)}
            behaviorLabels={getBehaviorLabels(data.behaviorData)}
            behaviorAnswers={getBehaviorValues(data.behaviorData)}
            expectedCompetencyAnswers={getExpectedCompetencyValues(
              data.competencyData
            )}
            expectedBehaviorData={getExpectedBehaviorValues(data.behaviorData)}
            language={state.language}
          />

          <PerspectivesTable
            relationshipData={data.relationshipData}
            handleDetailsStatus={handleDetailsStatus}
            language={state.language}
          />

          <CompetenciesPanelsList
            status={details}
            data={data.allBehaviorsData}
            language={state.language}
          />

          {data.openQuestions.map((item) => (
            <FeedbackPanel
              key={item.description}
              description={item.description}
              description_english={item.description_english}
              answers={item.readyComments}
              language={state.language}
            />
          ))}
        </>
      );
    }
  };

  let breadcrumbs = [
    {
      title: "Reportes",
      path: "/reports",
    },
    {
      title: `${reportTitle}`,
      path: `/reports/${rid}`,
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col>
            {reportData.periodsArray ? (
              <>
                <GeneralDataPanel
                  userData={reportData.userData}
                  finalGrade={reportData.finalGrade}
                  pid={pid}
                  rid={rid}
                  language={state.language}
                />
                <ScoresPanel
                  scores={reportData.scoresPanel}
                  language={state.language}
                />
                {reportData.periodsArray.map((item) => generateContent(item))}
              </>
            ) : Object.keys(reportData).length === 0 ? (
              <></>
            ) : (
              <>
                <GeneralDataPanel
                  userData={reportData.userData}
                  finalGrade={reportData.finalGrade}
                  pid={pid}
                  rid={rid}
                  language={state.language}
                />
                {generateContent(reportData)}
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ReportDetails;
