import React from "react";
import { Col } from "react-bootstrap";
import ProfileSuccesor from "./ProfileSuccesor";
import "./ProfilesList.css";

function ProfileSuccesorsList({ succesors, deleteSuccesor }) {
  return (
    <Col className="profiles-list">
      {succesors.map((element) => (
        <ProfileSuccesor
          key={element._id}
          id={element._id}
          firstName={element.firstName}
          lastName={element.lastName}
          profileName={element.profile}
          deleteSuccesor={deleteSuccesor}
        />
      ))}
    </Col>
  );
}

export default ProfileSuccesorsList;
