import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useWizard } from "react-use-wizard";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./CompetencyStep.css";

function CompetencyStep({
  id,
  name,
  name_english,
  description,
  description_english,
  competencyName,
  competencyName_english,
  levels,
  answers,
  handleRadioClick,
  handleSubmit,
  updateSteps,
  handleShow,
  handleClose,
  show,
  showModal,
  handleCloseModal,
  handleShowModal,
  periodName,
  periodName_english,
  language,
}) {
  const {
    stepCount,
    activeStep,
    isFirstStep,
    isLastStep,
    handleStep,
    previousStep,
    nextStep,
  } = useWizard();

  const { t } = useTranslation();

  handleStep(() => {
    if (answers.some((element) => element.value === 0)) {
      handleShowModal();
      previousStep();
    }
    updateSteps();
  });

  return (
    <Col className="step-container">
      <Col className="step-container-header mb-3">
        <h4>{language === "es" ? periodName : periodName_english}</h4>
      </Col>
      <Col className="step-container-controls mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={2}>
            <Button
              className="primary"
              disabled={isFirstStep}
              onClick={() => previousStep()}
            >
              {t("Client.CompetencyStep.Anterior")}
            </Button>
          </Col>
          <Col lg={8} className="center">
            <ProgressBar now={((activeStep + 1) / stepCount) * 100} />
          </Col>
          <Col lg={2} className="right">
            {isLastStep ? (
              <Button className="primary" onClick={() => handleShow()}>
                {t("Client.CompetencyStep.Enviar")}
              </Button>
            ) : (
              <Button className="primary" onClick={() => nextStep()}>
                {t("Client.CompetencyStep.Siguiente")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col className="step-container-content mb-3">
        <h4>
          {language === "es" ? competencyName : competencyName_english} -{" "}
          {language === "es" ? name : name_english}
        </h4>
        <h5 className="my-2">
          {language === "es" ? description : description_english}
        </h5>
        <ol>
          {levels.map((element) => (
            <li key={element._id}>
              <p>
                {language === "es" ? element.anchor : element.anchor_english}
              </p>
            </li>
          ))}
        </ol>
      </Col>
      <Col className="step-container-people">
        {answers.map((element) => (
          <Form.Group
            as={Row}
            className="align-items-center mb-2 person"
            controlId="formPlaintextEmail"
            key={element._id}
            id={element._id}
            onChange={(e) => handleRadioClick(id, element._id, e.target.value)}
            value={element.value.toString()}
          >
            <Form.Label column sm="7">
              <strong>{element.name}</strong>
            </Form.Label>
            <Col sm="5">
              <div key={`inline-radio`} className="right">
                {levels.map((item) => (
                  <Form.Check
                    inline
                    key={item._id}
                    label={item.isNa ? "N/A" : item.title}
                    value={item.isNa ? "0" : item.title}
                    name={`group${element._id}`}
                    type="radio"
                    defaultChecked={element.value.toString() === item.title}
                  />
                ))}
              </div>
            </Col>
          </Form.Group>
        ))}
      </Col>
      <Col className="step-container-controls mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={2}>
            <Button
              className="primary"
              disabled={isFirstStep}
              onClick={() => previousStep()}
            >
              {t("Client.CompetencyStep.Anterior")}
            </Button>
          </Col>
          <Col lg={8} className="center"></Col>
          <Col lg={2} className="right">
            {isLastStep ? (
              <Button className="primary" onClick={() => handleShow()}>
                {t("Client.CompetencyStep.Enviar")}
              </Button>
            ) : (
              <Button className="primary" onClick={() => nextStep()}>
                {t("Client.CompetencyStep.Siguiente")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Client.CompetencyStep.EnviarEvaluacion")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Client.CompetencyStep.UltimaOportunidad")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Client.CompetencyStep.Cancelar")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Client.CompetencyStep.Enviar")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Client.CompetencyStep.PersonaSinRespuesta")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Client.CompetencyStep.SeleccionarOpcion")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Client.CompetencyStep.Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default CompetencyStep;
