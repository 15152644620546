import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PersonData from "./PersonData";
import PersonEvolution from "./PersonEvolution";
import PersonStrenghts from "./PersonStrengths";
import PersonDocuments from "./PersonDocuments";

function PersonOverviewDashboard({
  generalData,
  strenghts,
  weaknesses,
  talentAssessments,
  performanceAssessments,
  documents,
  handleSubmitDocument,
  handleDownloadDocument,
  id,
  handleShow,
  language,
}) {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <Col>
            <PersonData
              id={generalData.id}
              categories={generalData.categories}
              firstName={generalData.firstName}
              lastName={generalData.lastName}
              directBoss={generalData.boss}
              profile={generalData.profile}
              language={language}
            />
          </Col>
          <Col>
            <PersonStrenghts
              weaknesses={weaknesses}
              strenghts={strenghts}
              language={language}
            />
          </Col>
          {/* <Col>
            <PersonDocuments
              documents={documents}
              handleSubmitDocument={handleSubmitDocument}
              handleDownloadDocument={handleDownloadDocument}
              id={id}
              handleShow={handleShow}
            />
          </Col> */}
        </Col>
        <Col lg={8}>
          <Col className="mb-3">
            <PersonEvolution datasource={talentAssessments} />
          </Col>
          <Col>
            <PersonEvolution datasource={performanceAssessments} />
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default PersonOverviewDashboard;
