import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./PeopleEvaluatedDetailsSelectedPerson.css";

function PeopleEvaluatedDetailsSelectedPerson({
  id,
  firstName,
  lastName,
  profile,
  relationship,
  handleBackToPool,
}) {
  return (
    <Container key={id} fluid className="person-evaluated">
      <Row className="align-items-center">
        <Col lg={10}>
          <Col>
            <h5>
              {firstName} {lastName}
            </h5>
          </Col>
          <Col>
            <p>{profile}</p>
            <p>{relationship}</p>
          </Col>
        </Col>
        <Col lg={2} className="center">
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => handleBackToPool(id)}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default PeopleEvaluatedDetailsSelectedPerson;
