import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useTranslation } from "react-i18next";
import "./ProfileContainer.css";

function ProfileContainer({ profiles, handleProfileInputChange, language }) {
  const { t } = useTranslation();

  return (
    <Col className="new-profile-selection-form mb-1">
      <h5>{t("Reportes.Perfil")}</h5>
      <FloatingLabel label="Seleccionar perfil" className="mb-3">
        <Form.Select
          name="eval_id"
          onChange={(e) => handleProfileInputChange(e)}
        >
          <option>{t("Reportes.SeleccionarPerfil")}</option>
          {profiles.map((element) => (
            <option value={element._id} key={element._id}>
              {language === "es" ? element.name : element.name_english}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Col>
  );
}

export default ProfileContainer;
