import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./EditQuestionnaireForm.css";

function EditQuestionnaireForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [type, setType] = useState("");
  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  let { qid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/${qid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTitle(response.data.questionnaire.title);
      setDescription(response.data.questionnaire.description);
      setType(response.data.questionnaire.type);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/${qid}`,
        data: {
          title: title,
          title_english: title_english,
          description: description,
          description_english: description_english,
          type: type,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/questionnaires");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Cuestionarios",
      path: "/questionnaires",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="questionnaire-form-header">
            <h2>{t("Cuestionarios.Cuestionario.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2 right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="questionnaire-form">
            <Form>
              <Col className="mb-3">
                <FloatingLabel label="Título">
                  <Form.Control
                    type="text"
                    placeholder="Título"
                    onChange={handleTitleChange}
                    value={title}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Title" className="mt-2">
                    <Form.Control
                      type="text"
                      placeholder="Título"
                      onChange={handleTitleEnglishChange}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
              </Col>
              <Col className="mb-3">
                <FloatingLabel label="Descripción">
                  <Form.Control
                    type="text"
                    placeholder="Descripción"
                    onChange={handleDescriptionChange}
                    value={description}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Description" className="mt-2">
                    <Form.Control
                      type="text"
                      placeholder="Descripción"
                      onChange={handleDescriptionEnglishChange}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
              </Col>
              <Col className="mb-3">
                <FloatingLabel
                  controlId="floatingSelect"
                  label={
                    english ? "Questionnaire type" : "Tipo de cuestionario"
                  }
                >
                  <Form.Select onChange={handleTypeChange} value={type}>
                    <option>
                      {t("Cuestionarios.Cuestionario.EditForm.Tipo")}
                    </option>
                    <option value="traditional">
                      {t("Cuestionarios.Cuestionario.EditForm.Tradicional")}
                    </option>
                    <option value="annonymus">
                      {t("Cuestionarios.Cuestionario.EditForm.Anonimo")}
                    </option>
                    <option value="competencies">
                      {t("Cuestionarios.Cuestionario.EditForm.Integrado")}
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col className="questionnaire-form-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Cuestionarios.Cuestionario.EditForm.Guardar")}
                </Button>
                <Link to="/questionnaires">
                  <Button className="danger">
                    {t("Cuestionarios.Cuestionario.EditForm.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditQuestionnaireForm;
