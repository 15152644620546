import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrainingStatusList from "../Components/TrainingsStatusList";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faList,
  faMap,
  faLayerGroup,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./TrainingsStatus.css";

function TrainingsStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/users/status/${state.organization}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setUsers(response.data.users);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.token]);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={9} className="trainings">
            <h2>{t("Capacitaciones.Capacitacion.DetalleCapacitaciones")}</h2>
          </Col>
          <Col lg={3} className="right trainings-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.role === "admin" ? (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to={`/mytrainings/${state.userId}`}
                    >
                      <FontAwesomeIcon icon={faChalkboardTeacher} />{" "}
                      {t("Capacitaciones.MisCapacitaciones.Titulo")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                ) : (
                  <></>
                )}

                <Dropdown.Item as={Link} to={`/trainings/map`}>
                  <FontAwesomeIcon icon={faMap} />{" "}
                  {t("Capacitaciones.MapaCapacitaciones")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/trainings/training-groups`}>
                  <FontAwesomeIcon icon={faLayerGroup} />{" "}
                  {t("Capacitaciones.GruposCapacitacion")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/trainings`}>
                  <FontAwesomeIcon icon={faChalkboardTeacher} />{" "}
                  {t("Capacitaciones.Actividades")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <TrainingStatusList users={users} />
        </Row>
      </Container>
    );
  }
}

export default TrainingsStatus;
