import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import "./DeleteBehavior.css";

function DeleteBehavior() {
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let { bid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/behaviors/${bid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setName(response.data.behavior.title);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleSubmit = async () => {
    try {
      await axios({
        method: "delete",
        url: `${process.env.REACT_APP_BACKEND_URL}/behaviors/${bid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/behaviors");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col className="delete-behavior">
            <Col>
              <h4>{t("Competencias.Comportamiento.DeleteBehavior.Titulo")}</h4>
            </Col>
            <Col className="delete-behavior-form">
              <Col className="mb-1">
                <p>
                  {t("Competencias.Comportamiento.DeleteBehavior.Pregunta")}
                </p>
              </Col>
              <Col>
                <Button className="primary" onClick={handleSubmit}>
                  {t("Competencias.Comportamiento.DeleteBehavior.Eliminar")}
                </Button>
                <Button
                  className="danger"
                  onClick={() => navigate("/behaviors")}
                >
                  {t("Competencias.Comportamiento.DeleteBehavior.Cancelar")}
                </Button>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DeleteBehavior;
