import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "./BarChart";
import Spinner from "react-bootstrap/Spinner";
import "./ComparisonReport.css";

function ComparisonReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/comparison/data/${rid}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setLabels(response.data.labels);
      setDatasets(response.data.datasets);
      setIsLoading(false);
    }
    fetch();
  }, []);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span>Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container>
        <Row>
          <Col className="comparison-report">
            <BarChart labels={labels} datasets={datasets} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ComparisonReport;
