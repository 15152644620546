import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import SelectedBehaviorElement from "./SelectedBehaviorElement";
import PoolBehaviorElement from "./PoolBehaviorElement";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./NewProfileForm.css";

function NewProfileForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [competencies, setCompetencies] = useState([]);
  const [keyProfile, setKeyProfile] = useState(false);

  const [name_english, setNameEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [english, setEnglish] = useState(false);

  const [selectedBehaviors, setSelectedBehaviors] = useState([]);
  const [completeBehaviors, setCompleteBehaviors] = useState([]);
  const [behaviors, setBehaviors] = useState([]);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/profiles/competencies/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setCompetencies(response.data.competencies);
      setBehaviors(response.data.behaviors);
      setCompleteBehaviors(response.data.behaviors);
    }
    fetch();
    setIsLoading(false);
  }, [state.language, state.organization, state.token]);

  const handlePoolChange = async (e) => {
    const difference = completeBehaviors.filter(
      ({ _id: id1 }) => !selectedBehaviors.some(({ _id: id2 }) => id2 === id1)
    );
    if (e.target.value !== "") {
      const newBehaviors = difference.filter(
        (item) => item.competencyId === e.target.value
      );
      setBehaviors(newBehaviors);
    } else {
      setBehaviors(difference);
    }
  };

  const handleKeyProfileChange = (e) => {
    setKeyProfile(e.target.checked);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/profiles`,
        data: {
          name: name,
          name_english: name_english,
          description: description,
          description_english: description_english,
          behaviors: selectedBehaviors,
          organization: state.organization,
          keyProfile: keyProfile,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/profiles");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleSelection = (id) => {
    let buffer = behaviors.find((element) => element._id === id);
    /* buffer.expectedLevel = 1; */

    let newBehaviorsArray = behaviors.filter((element) => {
      return element._id !== id;
    });

    setSelectedBehaviors([...selectedBehaviors, buffer]);
    setBehaviors(newBehaviorsArray);
  };

  const handleBackToPool = (id) => {
    let buffer = selectedBehaviors.find((element) => element._id === id);

    let newSelectedBehaviorsArray = selectedBehaviors.filter((element) => {
      return element._id !== id;
    });

    setBehaviors([...behaviors, buffer]);
    setSelectedBehaviors(newSelectedBehaviorsArray);
  };

  const handleLevelChange = (e, id) => {
    let newArray = selectedBehaviors.map((item) => {
      if (item._id === id) {
        item.expectedLevel = e.target.value;
      }
      return item;
    });

    setSelectedBehaviors(newArray);
  };

  let breadcrumbs = [
    {
      title: "Perfiles",
      path: "/profiles",
    },
  ];

  if (isLoading) {
    return (
      <div className="loading">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="new-profile-header">
            <h2>{t("Perfiles.Perfil.NewForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Form>
            <Tabs className="mb-3">
              <Tab eventKey="competencias" title="Competencias">
                <Row>
                  <Col lg={9} className="no-padding-left">
                    <Col className="new-profile-form">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Nombre del perfil"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="nombre del perfil"
                          onChange={handleNameChange}
                        />
                      </FloatingLabel>
                      {english ? (
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Profile's name"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Profile's name"
                            onChange={handleNameEnglishChange}
                          />
                        </FloatingLabel>
                      ) : (
                        <></>
                      )}
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Descripción del perfil"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="descripción del perfil"
                          onChange={handleDescriptionChange}
                        />
                      </FloatingLabel>
                      {english ? (
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Profile's description"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Profile's description"
                            onChange={handleDescriptionEnglishChange}
                          />
                        </FloatingLabel>
                      ) : (
                        <></>
                      )}
                      <Form.Check
                        type="switch"
                        id="key-profile"
                        label={
                          state.language === "es"
                            ? "Perfil clave"
                            : "Key profile"
                        }
                        onChange={(e) => handleKeyProfileChange(e)}
                      />
                    </Col>
                    <Col>
                      {selectedBehaviors.map((element) => (
                        <SelectedBehaviorElement
                          key={element._id}
                          id={element._id}
                          competency={element.competency}
                          competency_english={element.competency_english}
                          title={element.title}
                          title_english={element.title_english}
                          description={element.description}
                          description_english={element.description_english}
                          handleBackToPool={handleBackToPool}
                          levels={element.levels}
                          handleLevelChange={handleLevelChange}
                          language={state.language}
                        />
                      ))}
                    </Col>
                    <Col className="new-profile-buttons">
                      <Button className="primary" onClick={handleSubmit}>
                        {t("Perfiles.Perfil.NewForm.Guardar")}
                      </Button>
                      <Link to="/profiles">
                        <Button className="danger">
                          {t("Perfiles.Perfil.NewForm.Cancelar")}
                        </Button>
                      </Link>
                    </Col>
                  </Col>
                  <Col lg={3} className="new-profile-behaviors">
                    <Col>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          {t("Perfiles.Perfil.NewForm.Filtrar")}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handlePoolChange}
                        >
                          <option value="">
                            {t("Perfiles.Perfil.NewForm.Seleccionar")}
                          </option>
                          {competencies.map((item) => (
                            <option key={item._id} value={item._id}>
                              {state.language === "es"
                                ? item.name
                                : item.name_english}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      {behaviors.map((element) => (
                        <PoolBehaviorElement
                          key={element._id}
                          id={element._id}
                          title={element.title}
                          title_english={element.title_english}
                          handleSelection={handleSelection}
                          language={state.language}
                        />
                      ))}
                    </Col>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Form>
        </Row>
      </Container>
    );
  }
}

export default NewProfileForm;
