import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import "./ObjectiveFullfillmentValidationContainer.css";

function ObjectiveFullfillmentValidationContainer({
  description,
  userComment,
  secondApproverComment,
  userScore,
  secondApproverScore,
  approverComment,
  approverScore,
  evidencePath,
  second,
  handleDownloadEvidence,
}) {
  const { t } = useTranslation();

  return (
    <Col className="fullfillment-validation-container mb-3">
      <Col className="fullfillment-validation-container-header mb-3">
        <h5>{description}</h5>
      </Col>
      <Col className="fullfillment-validation-container-content">
        <Row>
          <Col lg={9} className="mb-3">
            <h5>{t("Client.ObjectivesEvaluation.RetroUsuario")}</h5>
            <Col>{userComment}</Col>
          </Col>
          <Col lg={3} className="level">
            <h5>{t("Client.ObjectivesEvaluation.CumplimientoUsuario")}</h5>
            <Col>{userScore}</Col>
          </Col>
        </Row>
        {second ? (
          <Row>
            <Col lg={9} className="mb-3">
              <h5>{t("Client.ObjectivesEvaluation.RetroSegundoJefe")}</h5>
              <Col>{secondApproverComment}</Col>
            </Col>
            <Col lg={3} className="level">
              <h5>
                {t("Client.ObjectivesEvaluation.CumplimientoSegundoJefe")}
              </h5>
              <Col>{secondApproverScore}</Col>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={9} className="mb-3">
              <h5>{t("Client.ObjectivesEvaluation.RetroJefeDirecto")}</h5>
              <Col>{approverComment}</Col>
            </Col>
            <Col lg={3} className="level">
              <h5>{t("Client.ObjectivesEvaluation.CumplimientoJefe")}</h5>
              <Col>{approverScore}</Col>
            </Col>
          </Row>
        )}
        {evidencePath === "" ? (
          <></>
        ) : (
          <Row>
            <Col
              className="download-link mb-3"
              onClick={() => handleDownloadEvidence(evidencePath)}
            >
              {t("Client.ObjectivesEvaluation.ArchivoEvidencia")}
            </Col>
          </Row>
        )}
      </Col>
    </Col>
  );
}

export default ObjectiveFullfillmentValidationContainer;
