import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Wizard } from "react-use-wizard";
import axios from "axios";
import WellnessStep from "../Components/WellnessStep";
import MessageStep from "../Components/MessageStep";
import Spinner from "react-bootstrap/Spinner";

function WellnessEvaluation() {
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [period, setPeriod] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let { eid } = useParams();

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaireEvaluations/evaluation`,
        data: {
          periodId: eid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setSteps(response.data.steps);
      setPeriod(response.data.period);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaireEvaluations`,
        data: {
          steps: steps,
          periodId: eid,
          evaluatorId: state.userId,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setShow(false);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleRadioClick = (question, value) => {
    let tempSteps = [];
    tempSteps = steps.map((element) => {
      if (element._id === question) {
        element.value = value;
      }
      return element;
    });
    setSteps(tempSteps);
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Wizard>
              <MessageStep
                periodName={period.name}
                periodMessage={period.message}
              />
              {steps !== undefined ? (
                steps.map((element) => (
                  <WellnessStep
                    key={element._id}
                    id={element._id}
                    type={element.type}
                    question={element.description}
                    question_english={element.description_english}
                    answers={element.answers}
                    handleRadioClick={handleRadioClick}
                    value={element.value}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    show={show}
                    period={period}
                    handleSubmit={handleSubmit}
                    language={state.language}
                  />
                ))
              ) : (
                <></>
              )}
            </Wizard>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WellnessEvaluation;
