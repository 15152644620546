import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./NewCategoryForm.css";

function NewCategoryForm() {
  const [name, setName] = useState("");
  const [name_english, setNameEnglish] = useState("");

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const [english, setEnglish] = useState(false);

  const { t } = useTranslation();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories`,
        data: {
          name: name,
          name_english: name_english,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/categories");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Categorias",
      path: "/categories",
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="new-category-header">
          <h2>{t("Categorias.Categoria.NewForm.Titulo")}</h2>
        </Col>
        <Col lg={1} className="new-category-header mb-2 right">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="new-category-form">
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Nombre de la categoría"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="nombre de la categoría"
                onChange={handleNameChange}
              />
            </FloatingLabel>
            {english ? (
              <FloatingLabel label="Category name" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Category name"
                  onChange={handleNameEnglishChange}
                />
              </FloatingLabel>
            ) : (
              <></>
            )}
            <Button className="primary" onClick={handleSubmit}>
              {t("Categorias.Categoria.NewForm.Guardar")}
            </Button>
            <Link to="/categories">
              <Button className="danger">
                {t("Categorias.Categoria.NewForm.Cancelar")}
              </Button>
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewCategoryForm;
