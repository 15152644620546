import React from "react";
import { Col } from "react-bootstrap";
import Behavior from "./Behavior";
import "./BehaviorsList.css";

function BehaviorsList({ competencies, language }) {
  return (
    <Col className="behaviors-list">
      {competencies.map((element) => (
        <Behavior
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          behaviors={element.behaviors}
          language={language}
        />
      ))}
    </Col>
  );
}

export default BehaviorsList;
