import React, { useContext, useState } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faList } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./ImportPeople.css";

function ImportPeople() {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("file");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setName(e.target.name);
  };

  const handleDownloadFormat = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/download/format`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = response.data.key;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/import/people/${state.organization}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setIsLoading(false);
      toast("Personas importadas correctamente");
    } catch (error) {
      console.log(error.response.data);
      toast(error.response.data.message);
    }
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col lg={8} className="people">
            <h2>{t("Personas.ImportarPersonas")}</h2>
          </Col>
          <Col lg={4} className="right people-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleDownloadFormat()}>
                  <FontAwesomeIcon icon={faDownload} />
                  {t("Personas.DescargarFormato")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="import-people-container">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e)}
                name="file"
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Button variant="primary" onClick={handleSubmit}>
                {t("Personas.Importar")}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ImportPeople;
