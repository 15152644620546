import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ObjectiveFullfillmentValidationSecond.css";

function ObjectiveFullfillmentValidationSecond({
  id,
  description,
  userComment,
  approverComment,
  userScore,
  approverScore,
  evidencePath,
  handleChangeInput,
  secondApproverScore,
  secondApproverComment,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Col className="fullfillment-validation-second mb-3">
      <Col className="fullfillment-validation-second-header mb-3">
        <h5>{description}</h5>
      </Col>
      <Col className="fullfillment-validation-second-content">
        <Row>
          <Col lg={9} className="mb-3">
            <h5>{t("Client.ObjectivesEvaluation.RetroUsuario")}</h5>
            <Col>{userComment}</Col>
          </Col>
          <Col lg={3} className="level">
            <h5>{t("Client.ObjectivesEvaluation.CumplimientoUsuario")}</h5>
            <Col>{userScore}</Col>
          </Col>
        </Row>
        <Row>
          <Col lg={9} className="mb-3">
            <h5>{t("Client.ObjectivesEvaluation.RetroJefeDirecto")}</h5>
            <Col>{approverComment}</Col>
          </Col>
          <Col lg={3} className="level">
            <h5>{t("Client.ObjectivesEvaluation.CumplimientoJefe")}</h5>
            <Col>{approverScore}</Col>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Link>{evidencePath}</Link>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={secondApproverComment}
            placeholder={
              language === "es"
                ? "Comentario de justificación para el nivel de cumplimiento a reportar"
                : "Justification comment for the level of compliance to report"
            }
            name="secondApproverComment"
            onChange={(e) => handleChangeInput(e, id)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Client.ObjectivesEvaluation.NivelDeCumplimiento")}
          </Form.Label>
          <Row className="align-items-start">
            <Col lg={11}>
              <Form.Range
                name="secondApproverScore"
                defaultValue={secondApproverScore}
                max={150}
                onChange={(e) => handleChangeInput(e, id)}
              />
            </Col>
            <Col lg={1} className="center">
              <span className="weightVal">{secondApproverScore}</span>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    </Col>
  );
}

export default ObjectiveFullfillmentValidationSecond;
