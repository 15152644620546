import React from "react";
import { Col } from "react-bootstrap";
import Scale from "./Scale";
import "./ScalesList.css";

function ScalesList({ scales, language }) {
  return (
    <Col className="scales-list">
      {scales.map((element) => (
        <Scale
          key={element._id}
          id={element._id}
          name={element.name}
          description={element.description}
          name_english={element.name_english}
          description_english={element.description_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default ScalesList;
