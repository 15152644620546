import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./PendingTraining.css";
const dayjs = require("dayjs");

function PendingTraining({
  completed_at,
  title,
  title_english,
  evaluatee,
  path,
  group,
  group_english,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Col lg={4} className="mb-4">
      <Col className="inReview-training">
        <p className="inReview-training-date">
          {t("Dashboard.Capacitaciones.Completada")}:{" "}
          {dayjs(completed_at).format("MMM-DD")}
        </p>
        <h5 className="inReview-training-title">
          {language === "es" ? title : title_english}
        </h5>
        <p className="training-group">
          {language === "es" ? group : group_english}
        </p>
        <p className="inReview-training-evaluatee">{evaluatee}</p>
        <div className="d-grid gap-2">
          <Link to={path}>
            <Button size="lg">{t("Dashboard.Capacitaciones.Validar")}</Button>
          </Link>
        </div>
      </Col>
    </Col>
  );
}

export default PendingTraining;
