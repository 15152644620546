import React from "react";
import { NavLink, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Report.css";

function Report({
  id,
  title,
  title_english,
  description,
  description_english,
  type,
  language,
}) {
  const { t } = useTranslation();

  const getReportType = (type) => {
    if (type === "competencies") {
      if (language === "es") {
        return "Competencias";
      } else if (language === "en") {
        return "Competencies";
      }
    } else if (type === "performance") {
      if (language === "es") {
        return "Desempeño";
      } else if (language === "en") {
        return "Performance";
      }
    } else if (type === "survey") {
      if (language === "es") {
        return "Bienestar";
      } else if (language === "en") {
        return "Wellness";
      }
    } else if (type === "map") {
      if (language === "es") {
        return "Mapa de talento";
      } else if (language === "en") {
        return "Talent map";
      }
    } else if (type === "hybrid") {
      if (language === "es") {
        return "Híbrido";
      } else if (language === "en") {
        return "Hybrid";
      }
    } else if (type === "comparison") {
      if (language === "es") {
        return "Comparativo";
      } else if (language === "en") {
        return "Comparison";
      }
    }
  };

  const getReportLink = (type) => {
    if (type === "survey") {
      return `/reports/survey/${id}`;
    } else if (type === "map") {
      return `/reports/map/${id}`;
    } else if (type === "hobbies") {
      return `/reports/hobbies/${id}`;
    } else if (type === "comparison") {
      return `/reports/comparison/${id}`;
    } else {
      return `/reports/${id}`;
    }
  };
  return (
    <Row className="align-items-center report">
      <Col lg={11} className="report-header">
        <NavLink className="nav-link" to={getReportLink(type)}>
          <h5>{language === "es" ? title : title_english}</h5>
        </NavLink>
        <p>{language === "es" ? description : description_english}</p>
        <p>{getReportType(type)}</p>
      </Col>
      <Col lg={1} className="report-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/reports/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} /> {t("Reportes.Reporte.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/reports/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Reportes.Reporte.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Report;
