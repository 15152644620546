import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../Public/sphere-logo-grey.png";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/forgot/password`,
        data: {
          email: email,
        },
      });
      toast(response.data.message);
      setTimeout(() => {
        navigate("/renew/password", { state: { email: email }, emails: email });
      }, 3000);
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center login">
        <Col lg={5} className="forgot-password">
          <Col className="center mb-3">
            <Col className="center mb-3">
              <Image src={Logo} fluid />
              <ToastContainer />
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingUsername"
                label="Ingresa el correo con el que estas registrado(a)"
                className="mb-3"
                onChange={handleEmailChange}
              >
                <Form.Control
                  type="email"
                  placeholder="Ingresa el correo con el que estas registrado(a)"
                />
              </FloatingLabel>
            </Col>
            <Col className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleSubmit}>
                Solicitar código de recuperación
              </Button>
            </Col>
            <Col className="mt-3">
              <Col>
                <Link to="/login">Regresar a Log In</Link>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
