import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./NewCommitmentForm.css";

function NewCommitmentForm() {
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/commitments/users/${state.userId}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setUsers(response.data.users);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleEvidenceChange = (e) => {
    setFile({ name: e.target.name, file: e.target.files[0] });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append(file.name, file.file);
    formData.append("title", title);
    formData.append("user", user);
    formData.append("description", description);
    formData.append("status", "Pending");
    formData.append("dueDate", new Date(dueDate));
    formData.append("organization", state.organization);
    formData.append("creator", state.userId);

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/commitments`,
        data: formData,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/commitments");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Compromisos",
      path: "/commitments",
    },
  ];

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col className="new-commitment-header">
            <h2>{t("Compromisos.Compromiso.NewForm.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="new-commitment-form">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.NewForm.ParaQuien")}
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleUserChange}
                >
                  <option value="">
                    {t("Compromisos.Compromiso.NewForm.SeleccionarPersona")}
                  </option>
                  {users.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.NewForm.TituloCompromiso")}
                </Form.Label>
                <Form.Control type="text" onChange={handleTitleChange} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.NewForm.DecripcionCompromiso")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  disabled={false}
                  onChange={handleDescription}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.NewForm.ArchivoReferencia")}
                </Form.Label>
                <Form.Control
                  type="file"
                  name="evidence"
                  onChange={(e) => handleEvidenceChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("Compromisos.Compromiso.NewForm.FechaCumplimiento")}
                </Form.Label>
                <DatePicker
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                />
              </Form.Group>
              <Button className="primary" onClick={handleSubmit}>
                {t("Compromisos.Compromiso.NewForm.CrearCompromiso")}
              </Button>
              <Link to="/commitments">
                <Button className="danger">
                  {t("Compromisos.Compromiso.NewForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NewCommitmentForm;
