import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faStream } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import CompetenciesList from "../Components/CompetenciesList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import "./Competencies.css";

function Competencies() {
  const [isLoading, setIsLoading] = useState(true);
  const [competencies, setCompetencies] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/competencies/organization/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setCompetencies(response.data.competencies);
      setIsLoading(false);
    }
    fetch();
  }, [state.language, state.organization, state.token]);

  let breadcrumbs = [
    {
      title: "Comportamientos",
      path: "/behaviors",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={11} className="competencies-header">
            <h2>{t("Competencias.Competencias")}</h2>
          </Col>
          <Col lg={1} className="right competencies-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/behaviors/competencies/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Competencias.NuevaCompetencia")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/behaviors/competencies/scales`}>
                  <FontAwesomeIcon icon={faStream} />{" "}
                  {t("Competencias.IrEscalas")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          {competencies.length === 0 ? (
            <Col className="empty-competencies center">
              <h5>{t("Competencias.SinCompetencias")}</h5>
            </Col>
          ) : (
            <CompetenciesList
              competencies={competencies}
              language={state.language}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default Competencies;
