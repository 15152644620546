import React from "react";
import Col from "react-bootstrap/Col";
import { Pie } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import "./EvaluationStatusObjectives.css";

function EvaluationStatusObjectives({
  data,
  periodId,
  periodName,
  periodName_english,
  role,
  language,
}) {
  let labels = [];
  if (language === "es") {
    labels = [
      "Alta objetivos",
      "En revisión",
      "Cumplimiento usuario",
      "Esperando 1ra aprobación",
      "Esperando 2da aprobación",
      "Terminada",
      "Cerrada",
    ];
  } else if (language === "en") {
    labels = [
      "Create objectives",
      "In review",
      "In progress",
      "Waiting for 1st approval",
      "Waiting for 2nd approval",
      "Finished",
      "Closed",
    ];
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: data,
        backgroundColor: [
          "rgba(200, 0, 100, 0.2)",
          "rgba(215, 101, 139, 0.2)",
          "rgba(223, 151, 158, 0.2)",
          "rgba(228, 188, 173, 0.2)",
          "rgba(186, 219, 219, 0.2)",
          "rgba(152, 209, 209, 0.2)",
          "rgba(118, 200, 200, 0.2)",
        ],
        borderColor: [
          "rgba(200, 0, 100, 1)",
          "rgba(215, 101, 139, 1)",
          "rgba(223, 151, 158, 1)",
          "rgba(228, 188, 173, 1)",
          "rgba(186, 219, 219, 1)",
          "rgba(152, 209, 209, 1)",
          "rgba(118, 200, 200, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Col lg={3} className="mb-3">
      <Col className="dashboard-graph-container objetivos">
        <Col className="dashboard-graph-header center">
          {role === "admin" || role === "root" ? (
            <NavLink
              className="nav-link"
              to={`/pending/evaluation/${periodId}`}
            >
              <h6>{language === "es" ? periodName : periodName_english}</h6>
            </NavLink>
          ) : (
            <h6>{language === "es" ? periodName : periodName_english}</h6>
          )}
        </Col>
        <Col className="dashboard-graph-content">
          <Pie data={chartData} options={options} />
        </Col>
      </Col>
    </Col>
  );
}

export default EvaluationStatusObjectives;
