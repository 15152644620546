import React from "react";
import { Col } from "react-bootstrap";
import Report from "./Report";
import "./ReportsList.css";

function ReportsList({ reports, language }) {
  return (
    <Col className="reports-list">
      {reports.map((element) => (
        <Report
          key={element._id}
          id={element._id}
          title={element.title}
          title_english={element.title_english}
          description={element.description}
          description_english={element.description_english}
          type={element.type}
          language={language}
        />
      ))}
    </Col>
  );
}

export default ReportsList;
