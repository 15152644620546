import React from "react";
import { Col } from "react-bootstrap";
import Training from "./Training";
import "./TrainingsList.css";

function TrainingsList({ trainings, language }) {
  return (
    <Col className="trainings-list">
      {trainings.map((element) => (
        <Training
          key={element._id}
          id={element._id}
          name={element.name}
          name_english={element.name_english}
          group={element.trainingGroupName}
          group_english={element.trainingGroupName_english}
          language={language}
        />
      ))}
    </Col>
  );
}

export default TrainingsList;
