import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import "./ObjectiveContainer.css";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function ObjectiveContainer({
  id,
  description,
  weight,
  bossObjective,
  bossObjectives,
  handleChangeInput,
  handleRemoveObjective,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Col className="objective-container mb-3">
      <Col className="objective-container-header">
        <Row className="align-items-center">
          <Col lg={11}>
            <h4>{t("Client.ObjectivesEvaluation.Objetivo")}</h4>
          </Col>
          <Col lg={1} className="right">
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => handleRemoveObjective(id)}
            />
          </Col>
        </Row>
      </Col>
      <Col className="objective-container-form">
        <Form.Group className="mb-3">
          <p>{t("Client.ObjectivesEvaluation.SMARTDescription")}</p>
          <Form.Select
            aria-label="Default select example"
            className="mb-3"
            name="bossObjective"
            onChange={(e) => handleChangeInput(e, id)}
            value={bossObjective}
          >
            <option value={null}>
              {t("Client.ObjectivesEvaluation.PreguntaObjetivo")}
            </option>
            {bossObjectives.map((item) => (
              <option key={item._id} id={item._id} value={item._id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={description}
            name="description"
            placeholder={
              language === "es"
                ? "Ingresa la correcta descripción del objetivo"
                : "Enter the correct description of the objective"
            }
            onChange={(e) => handleChangeInput(e, id)}
          />
        </Form.Group>
      </Col>
      <Col className="objective-container-range">
        <Form.Label>{t("Client.ObjectivesEvaluation.Ponderacion")}</Form.Label>
        <Row className="align-items-center">
          <Col lg={11}>
            <Form.Range
              defaultValue={weight}
              name="weight"
              onChange={(e) => handleChangeInput(e, id)}
            />
          </Col>
          <Col lg={1} className="center">
            <span className="weightVal">{weight}</span>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default ObjectiveContainer;
