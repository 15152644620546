import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import RulesList from "../Components/RulesList";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./Rules.css";

function Rules() {
  const [isLoading, setIsLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [show, setShow] = useState(false);

  const { state } = useContext(AuthContext);

  let { eid } = useParams();

  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/rules/period/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setRules(response.data.rules);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleApply = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/rules/apply/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setShow(false);
      setIsLoading(false);
      toast(response.data.message);
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <ToastContainer />
        <Row className="align-items-center">
          <Col lg={11} className="rules-header">
            <h2>{t("Evaluaciones.Reglas.Titulo")}</h2>
          </Col>

          <Col lg={1} className="right rules-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/evaluations/rules/new/${eid}`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Evaluaciones.Reglas.NuevaRegla")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        {rules.length === 0 ? (
          <Row>
            <Col className="rules-list center mb-2">
              <h3>{t("Evaluaciones.Reglas.SinReglas")}</h3>
            </Col>
          </Row>
        ) : (
          <Row>
            <RulesList rules={rules} />
          </Row>
        )}
        {rules.length === 0 ? (
          <Row>
            <Col></Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Button className="primary" onClick={handleShow}>
                {t("Evaluaciones.Reglas.AplicarReglas")}
              </Button>
            </Col>
          </Row>
        )}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Evaluaciones.Reglas.Cuidado")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("Evaluaciones.Reglas.CuidadoTexto")}</Modal.Body>
          <Modal.Footer>
            <Button className="primary" onClick={handleApply}>
              {t("Evaluaciones.Reglas.Aplicar")}
            </Button>
            <Button className="danger" onClick={handleClose}>
              {t("Evaluaciones.Reglas.Cancelar")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default Rules;
