import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useTranslation } from "react-i18next";
import "./EvaluationContainer.css";

function EvaluationContainer({
  id,
  type,
  evaluations,
  handleChangeInput,
  eval_id,
  weight,
  language,
}) {
  const { t } = useTranslation();

  return (
    <Col className="new-evaluation-selection-form mb-3">
      <h5>{t("Reportes.Evaluacion")}</h5>
      <FloatingLabel
        label={
          language === "es" ? "Seleccionar evaluación" : "Select evaluation"
        }
        className="mb-3"
      >
        <Form.Select
          name="eval_id"
          onChange={(e) => handleChangeInput(e, id)}
          value={eval_id}
        >
          <option>{t("Reportes.SeleccionarEvaluacion")}</option>
          {evaluations.map((element) => (
            <option value={element._id} key={element._id}>
              {language === "es" ? element.name : element.name_english}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
      {type === "hybrid" ? (
        <FloatingLabel
          controlId="floatingPassword"
          label={language === "es" ? "Ponderación" : "Weight"}
        >
          <Form.Control
            type="text"
            placeholder={language === "es" ? "Ponderación" : "Weight"}
            name="weight"
            value={weight}
            onChange={(e) => handleChangeInput(e, id)}
          />
        </FloatingLabel>
      ) : (
        ""
      )}
    </Col>
  );
}

export default EvaluationContainer;
