import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import RankingReportPeopleList from "./RankingReportPeopleList";
import { useTranslation } from "react-i18next";

function RankingReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [evalResults, setEvalResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState("");

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/ranking/data`,
        data: {
          reportId: rid,
          filter: "",
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setEvalResults(response.data.peopleData);
      setCategories(response.data.categories);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const filterRanking = async (e) => {
    setIsLoading(true);
    setFilter(e.target.value);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/ranking/data`,
      data: {
        reportId: rid,
        filter: e.target.value,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setEvalResults(response.data.peopleData);
    setCategories(response.data.categories);
    setIsLoading(false);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  className="mb-3"
                  name="categories"
                  onChange={(e) => filterRanking(e)}
                  value={filter}
                >
                  <option value={""}>{t("Reportes.Filtar")}</option>
                  {categories.map((item) => (
                    <option key={item._id} id={item._id} value={item._id}>
                      {state.language === "es" ? item.name : item.name_english}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <RankingReportPeopleList
                data={evalResults}
                language={state.language}
              />
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RankingReport;
