import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./ReportPerson.css";

function ReportPerson({
  id,
  name,
  profile,
  profile_english,
  reportId,
  language,
}) {
  return (
    <Row className="report-person align-items-center">
      <Col className="report-person-header">
        <h5>
          <NavLink to={`/reports/${reportId}/person/${id}`}>{name}</NavLink>
        </h5>
        <p>{language === "es" ? profile : profile_english}</p>
      </Col>
    </Row>
  );
}

export default ReportPerson;
