import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Logo from "../../Public/sphere-logo-grey.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signin } = useContext(AuthContext);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        data: {
          email: email,
          password: password,
        },
      });

      const result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/me`,
        data: {
          email: email,
        },
      });

      signin(response.data.token, result.data);
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center login">
        <Col lg={5}>
          <Col className="center mb-3">
            <Image src={Logo} fluid />
            <ToastContainer />
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingUsername"
              label="Email"
              className="mb-3"
              onChange={handleEmailChange}
            >
              <Form.Control type="email" placeholder="Username" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Password"
              className="mb-3"
              onChange={handlePasswordChange}
            >
              <Form.Control type="password" placeholder="Password" />
            </FloatingLabel>
            <Row className="mb-3">
              <Col lg={6}></Col>
              <Col lg={6} className="right">
                <Link to="/forgot/password">Olvidaste tu contraseña?</Link>
              </Col>
            </Row>
            <Col className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleSubmit}>
                Log In
              </Button>
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
