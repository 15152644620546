import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import NewOptionForm from "./NewOptionForm";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./EditOptionSetForm.css";

function EditOptionSetForm() {
  const [counter, setCounter] = useState(1);
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");

  const [english, setEnglish] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  const { oid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/optionSets/${oid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setOptions(response.data.optionSet.options);
      setTitle(response.data.optionSet.title);
      setTitleEnglish(response.data.optionSet.title_english);
      setDescription(response.data.optionSet.description);
      setDescriptionEnglish(response.data.optionSet.description_english);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleAddOption = () => {
    setCounter(counter + 1);
    setOptions([
      ...options,
      {
        id: uuidv4(),
        title: `Opcion ${counter}`,
        title_english: `Option ${counter}`,
        description: "",
        description_english: "",
        isNegative: false,
        isNeutral: false,
      },
    ]);
  };

  const handleChangeInput = (e, id) => {
    const newOptionsData = options.map((i) => {
      if (id === i._id.toString()) {
        if (e.target.name === "negative" || e.target.name === "neutral") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });
    setOptions(newOptionsData);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/optionsets/${oid}`,
        data: {
          title: title,
          title_english: title_english,
          description: description,
          description_english: description_english,
          options: options,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/questionnaires/optionsets");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col lg={11} className="option-set-form-header">
            <h2>{t("Cuestionarios.OptionSets.Set.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2 right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="option-set-form">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Título"
                  value={title}
                  onChange={handleTitleChange}
                />
              </Form.Group>
              {english ? (
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    onChange={handleTitleEnglishChange}
                    value={title_english}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </Form.Group>
              {english ? (
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onChange={handleDescriptionEnglishChange}
                    value={description_english}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              <Col>
                {options.map((element) => (
                  <NewOptionForm
                    key={element._id}
                    id={element._id}
                    name={element.label}
                    title={element.title}
                    title_english={element.title_english}
                    description={element.description}
                    description_english={element.description_english}
                    isNegative={element.isNegative}
                    isNeutral={element.isNeutral}
                    handleChangeInput={handleChangeInput}
                    english={english}
                  />
                ))}
              </Col>
              <Button className="primary" onClick={handleSubmit}>
                {t("Cuestionarios.OptionSets.Set.EditForm.Guardar")}
              </Button>
              <Link to="/questionnaires/optionsets">
                <Button className="danger">
                  {t("Cuestionarios.OptionSets.Set.EditForm.Cancelar")}
                </Button>
              </Link>
              <Button className="info" onClick={handleAddOption}>
                {t("Cuestionarios.OptionSets.Set.EditForm.Agregar")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditOptionSetForm;
