import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import "./ChangePassword.css";

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { t } = useTranslation();

  const { state } = useContext(AuthContext);

  const handleChangeOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const passwordValidation = () => {
    if (newPassword !== confirmPassword) {
      toast("Las contraseñas no coinciden");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/password/change/${state.userId}`,
        data: {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      toast("Contraseña actualizada");
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-center align-items-center">
        <Col>
          <Col>
            <h1>{t("MiPerfil.CambioContrasena.Titulo")}</h1>
          </Col>
          <Col className="change-password-content">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("MiPerfil.CambioContrasena.ContrasenaActual")}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña actual"
                  onChange={(e) => handleChangeOldPassword(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("MiPerfil.CambioContrasena.NuevaContrasena")}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña nueva"
                  onChange={(e) => handleChangeNewPassword(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("MiPerfil.CambioContrasena.ConfirmarContrasena")}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirmar contraseña nueva"
                  onChange={(e) => handleChangeConfirmPassword(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Button variant="primary" onClick={() => passwordValidation()}>
                  {t("MiPerfil.CambioContrasena.Cambiar")}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
