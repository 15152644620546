import React from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Training.css";

function Training({ id, name, name_english, language, group, group_english }) {
  const { t } = useTranslation();

  return (
    <Row className="training align-items-center">
      <Col lg={9} className="training-header">
        <NavLink className="nav-link" to={`/trainings/assignment/${id}`}>
          <h5>{language === "es" ? name : name_english}</h5>
        </NavLink>
        <p>{language === "es" ? group : group_english}</p>
      </Col>
      <Col lg={3} className="training-content">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faCog} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/trainings/edit/${id}`}>
              <FontAwesomeIcon icon={faEdit} />{" "}
              {t("Capacitaciones.Capacitacion.Editar")}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/trainings/delete/${id}`}>
              <FontAwesomeIcon icon={faTrash} />{" "}
              {t("Capacitaciones.Capacitacion.Eliminar")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default Training;
