import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./NineBoxPerson.css";

function NineBoxPerson({
  name,
  profile,
  profile_english,
  talent,
  performance,
  language,
}) {
  return (
    <Col className="nine-box-person">
      <Row className="align-items-center">
        <Col lg={8} className="nine-box-person-header">
          <h5>{name}</h5>
          <p>{language === "es" ? profile : profile_english}</p>
        </Col>
        <Col lg={2} className="center talent-grade">
          <p>{talent}</p>
        </Col>
        <Col lg={2} className="center performance-grade">
          <p>{performance}</p>
        </Col>
      </Row>
    </Col>
  );
}

export default NineBoxPerson;
