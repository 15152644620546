import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./NewBehaviorForm.css";

function NewBehaviorForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [scales, setScales] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [title_english, setTitleEnglish] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [competency, setCompetency] = useState();
  const [scale, setScale] = useState();
  const [english, setEnglish] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/behaviors/organization/create/${state.organization}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setScales(response.data.scales);
      setCompetencies(response.data.competencies);
    }
    fetch();
    setIsLoading(false);
  }, [state.language, state.organization, state.token]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleEnglishChange = (e) => {
    setTitleEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleCompetencyChange = (e) => {
    setCompetency(e.target.value);
  };

  const handleScaleChange = (e) => {
    setScale(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/behaviors`,
        data: {
          title: title,
          title_english: title_english,
          description: description,
          description_english: description_english,
          competency: competency,
          scale: scale,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/behaviors");
    } catch (error) {
      toast(error.response.data.message);
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Comportamientos",
      path: "/behaviors",
    },
  ];

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <ToastContainer />
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="new-behavior-header">
            <h2>
              {t("Competencias.Comportamiento.NewForm.NuevoComportamiento")}
            </h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="new-behavior-form">
            <Form>
              <FloatingLabel label="Título" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Título"
                  onChange={handleTitleChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Title" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    onChange={handleTitleEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel className="mb-3" label="Descripción">
                <Form.Control
                  as="textarea"
                  placeholder="Descripción"
                  style={{ height: "100px" }}
                  onChange={handleDescriptionChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel className="mb-3" label="Description">
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    onChange={handleDescriptionEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <Form.Group className="mb-3">
                <Form.Select
                  aria-label="Seleccionar competencia"
                  onChange={handleCompetencyChange}
                >
                  {state.language === "en" ? (
                    <option value="">Select competency</option>
                  ) : (
                    <option value="">Seleccionar competencia</option>
                  )}
                  {competencies.map((item) => (
                    <option value={item._id} key={item._id}>
                      {state.language === "es" ? item.name : item.name_english}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Select
                  aria-label="Seleccionar escala"
                  onChange={handleScaleChange}
                >
                  {state.language === "en" ? (
                    <option value="">Select scale</option>
                  ) : (
                    <option value="">Seleccionar escala</option>
                  )}

                  {scales.map((item) => (
                    <option value={item._id} key={item._id}>
                      {state.language === "es" ? item.name : item.name_english}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Button className="primary" onClick={handleSubmit}>
                {t("Competencias.Comportamiento.NewForm.Guardar")}
              </Button>
              <Link to="/behaviors">
                <Button className="danger">
                  {t("Competencias.Comportamiento.NewForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NewBehaviorForm;
