import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../Context/AuthContext";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import "./TrainingMap.css";

function TrainingMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [trainings, setTrainings] = useState([]);
  const [users, setUsers] = useState([]);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/organization/trainings/map/${state.organization}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTrainings(response.data.trainings);
      setUsers(response.data.users);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const createColGroups = (array) => {
    let cols = [];
    cols.push(<col key={-2} className="userNames" />);
    for (let index = -1; index < array.length; index++) {
      cols.push(<col key={index} className="training-status" />);
    }
    let content = <colgroup>{cols.map((item) => item)}</colgroup>;
    return content;
  };

  let breadcrumbs = [
    {
      title: "Planes desarrollo",
      path: "/trainings/training-status",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <>
        <Col>
          <Breadcrumbs data={breadcrumbs} />
        </Col>
        <Col className="training-map">
          <Table responsive>
            {createColGroups(trainings)}
            <thead>
              <tr>
                <th>
                  <div></div>
                </th>
                {trainings.map((training) => (
                  <th key={training._id}>
                    <div className="training-name">{training.name}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>
                    <div className="userName">
                      {user.firstName + " " + user.lastName}
                    </div>
                  </td>
                  {trainings.map((training) => {
                    if (user.trainings.some((e) => e.name === training.name)) {
                      let trainingStatus = user.trainings.find(
                        (e) => e.name === training.name
                      ).status;
                      return (
                        <td key={training._id}>
                          <div className={`${trainingStatus}`}></div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={training._id}>
                          <div className="blank"></div>
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </>
    );
  }
}

export default TrainingMap;
