import React from "react";
import { Col } from "react-bootstrap";
import TrainingStatusElement from "./TrainingStatusElement";
import "./TrainingsStatusList.css";

function TrainingStatusList({ users }) {
  return (
    <Col className="training-status-list">
      {users.map((element) => (
        <TrainingStatusElement
          key={element._id}
          id={element._id}
          name={element.firstName + " " + element.lastName}
          profile={element.profileName}
          trainings={element.trainings}
        />
      ))}
    </Col>
  );
}

export default TrainingStatusList;
