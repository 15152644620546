import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import "./ObjectiveItem.css";

function ObjectiveItem({ id, title, user_comment, approver_comment, grade }) {
  const { t } = useTranslation();

  return (
    <Col className="objective mb-3" key={id}>
      <Col className="objective-header">
        <h5>{title}</h5>
      </Col>
      <Col>
        <Row className="align-items-center">
          <Col lg={6} className="objective-user-feedback">
            <h6>{t("Reportes.ComentarioUsuario")}</h6>
            <p>{user_comment}</p>
          </Col>
          <Col lg={6} className="objective-approver-feedback">
            <h6>{t("Reportes.ComentarioJefeDirecto")}</h6>
            <p>{approver_comment}</p>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="align-items-center">
          <Col lg={11}>
            <ProgressBar now={grade} />
          </Col>
          <Col lg={1} className="objective-grade center">
            <p>{grade}</p>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default ObjectiveItem;
