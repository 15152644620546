import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faStream } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuestionnairesList from "../Components/QuestionnairesList";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./Questionnaires.css";

function Questionnaires() {
  const [isLoading, setIsLoading] = useState(true);
  const [questionnaires, setQuestionnaires] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/questionnaires/organization/${state.organization}`,
        data: {
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setQuestionnaires(response.data.questionnaires);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.token, state.language]);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={10} className="questionnaires">
            <h2>{t("Cuestionarios.Titulo")}</h2>
          </Col>
          <Col lg={2} className="right questionnaires-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/questionnaires/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Cuestionarios.NuevoCuestionario")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/questionnaires/optionsets`}>
                  <FontAwesomeIcon icon={faStream} />{" "}
                  {t("Cuestionarios.IrSets")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {questionnaires.length === 0 ? (
            <Col className="empty-questionnaires center">
              <h5>{t("Cuestionarios.SinCuestionarios")}</h5>
            </Col>
          ) : (
            <QuestionnairesList
              questionnaires={questionnaires}
              language={state.language}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default Questionnaires;
