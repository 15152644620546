import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import EvaluationContainer from "./EvaluationContainer";
import ProfileContainer from "./ProfileContainer";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import WeightsContainer from "./WeightsContainer";
import { useTranslation } from "react-i18next";
import "./EditReportForm.css";

function EditReportForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [name_english, setNameEnglish] = useState("");
  const [description, setDescription] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [visibility, setVisibility] = useState("");
  const [profile, setProfile] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [weights, setWeights] = useState([]);

  const [english, setEnglish] = useState(false);

  const { t } = useTranslation();

  const [evaluations, setEvaluations] = useState([
    { id: uuidv4(), eval_id: "", weight: 0 },
  ]);
  const [accountEvaluations, setAccountEvaluations] = useState([]);
  const [type, setType] = useState("");
  const [normStatus, setNormStatus] = useState(false);
  const [normLevel, setNormLevel] = useState("");

  let { rid } = useParams();

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setName(response.data.report.title);
      setNameEnglish(response.data.report.title_english);
      setDescription(response.data.report.description);
      setDescriptionEnglish(response.data.report.description_english);
      setVisibility(response.data.report.visibility);
      setProfile(response.data.report.profile);
      setEvaluations(response.data.report.periods);
      setType(response.data.report.type);
      setNormLevel(response.data.report.normalization);
      setNormStatus(response.data.report.normStatus);
      setWeights(response.data.report.weights);

      if (
        response.data.report.type === "competencies" ||
        response.data.report.type === "map"
      ) {
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: response.data.report.type,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(result.data.evaluations);
      } else if (response.data.report.type === "performance") {
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: response.data.report.type,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(result.data.evaluations);
      } else if (response.data.report.type === "hybrid") {
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: response.data.report.type,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(result.data.evaluations);
      } else if (response.data.report.type === "survey") {
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: response.data.report.type,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(result.data.evaluations);
      } else if (response.data.report.type === "comparison") {
        const result = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/profiles/organization/${state.organization}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setProfiles(result.data.profiles);
      }
      setIsLoading(false);
    }
    fetch();
  }, []);

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleNormStatusChange = (e) => {
    setNormStatus(e.target.checked);
  };

  const handleNormLevel = (e) => {
    setNormLevel(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const handleVisibilityChange = (e) => {
    setVisibility(e.target.value);
  };

  const handleChangeType = async (e) => {
    try {
      if (e.target.value === "competencies" || e.target.value === "map") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "performance") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "hybrid") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "survey") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "comparison") {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/profiles/organization/${state.organization}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setProfiles(response.data.profiles);
      }
      setType(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOption = () => {
    setEvaluations([...evaluations, { id: uuidv4(), eval_id: "", weight: 0 }]);
  };

  const handleChangeInput = (e, id) => {
    if (type === "hybrid") {
      let newEvaluations = evaluations.map((item) =>
        item.id === id ? { ...item, [e.target.name]: e.target.value } : item
      );
      setEvaluations(newEvaluations);
    } else {
      let newEvaluations = [{ eval_id: e.target.value, weight: 100 }];
      setEvaluations(newEvaluations);
    }
  };

  const handleWeightsChange = (e) => {
    let newWeights = weights.map((item) => {
      if (item.perspective === e.target.name) {
        return { ...item, weight: e.target.value };
      } else {
        return item;
      }
    });
    setWeights(newWeights);
  };

  const handleProfileInputChange = (e) => {
    setProfile(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/${rid}`,
        data: {
          name: name,
          name_english: name_english,
          description: description,
          description_english: description_english,
          visibility: visibility,
          profile: profile,
          evaluations: evaluations,
          type: type,
          normLevel: normLevel,
          organization: state.organization,
          weights: weights,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/reports");
    } catch (error) {
      console.log(error);
    }
  };

  let breadcrumbs = [
    {
      title: "Reportes",
      path: "/reports",
    },
  ];

  let content = <></>;

  if (type === "competencies") {
    content = (
      <>
        {/* <Form.Group className="mb-3">
          <Form.Check
            label="Normalizar"
            type="switch"
            checked={normStatus}
            onChange={handleNormStatusChange}
          />
        </Form.Group>
        {normStatus === true ? (
          <FloatingLabel label="Normalización" className="mb-3">
            <Form.Select onChange={handleNormLevel} defaultValue={normLevel}>
              <option value="">Seleccionar nivel</option>
              <option value="99">99%</option>
              <option value="95">95%</option>
              <option value="90">90%</option>
              <option value="85">85%</option>
              <option value="80">80%</option>
              <option value="75">75%</option>
              <option value="70">70%</option>
            </Form.Select>
          </FloatingLabel>
        ) : (
          <></>
        )} */}
        <EvaluationContainer
          evaluations={accountEvaluations}
          handleChangeInput={handleChangeInput}
          type={type}
          eval_id={evaluations[0].eval_id}
          weight={evaluations[0].weight}
          language={state.language}
        />
      </>
    );
  } else if (type === "performance") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        eval_id={evaluations[0].eval_id}
        weight={evaluations[0].weight}
        language={state.language}
      />
    );
  } else if (type === "hybrid") {
    content = (
      <>
        {/* <Form.Group className="mb-3">
          <Form.Check
            label="Normalizar"
            type="switch"
            checked={normStatus}
            onChange={handleNormStatusChange}
          />
        </Form.Group>
        {normStatus === true ? (
          <FloatingLabel label="Normalización" className="mb-3">
            <Form.Select onChange={handleNormLevel} defaultValue={normLevel}>
              <option value="">Seleccionar nivel</option>
              <option value="99">99%</option>
              <option value="95">95%</option>
              <option value="90">90%</option>
              <option value="85">85%</option>
              <option value="80">80%</option>
              <option value="75">75%</option>
              <option value="70">70%</option>
            </Form.Select>
          </FloatingLabel>
        ) : (
          <></>
        )} */}
        {evaluations.map((item) => (
          <EvaluationContainer
            key={item._id}
            id={item._id}
            evaluations={accountEvaluations}
            type={type}
            handleChangeInput={handleChangeInput}
            weight={item.weight}
            eval_id={item.eval_id}
            language={state.language}
          />
        ))}
      </>
    );
  } else if (type === "map") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        language={state.language}
      />
    );
  } else if (type === "survey") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        language={state.language}
      />
    );
  } else if (type === "comparison") {
    content = (
      <ProfileContainer
        profiles={profiles}
        handleProfileInputChange={handleProfileInputChange}
        language={state.language}
      />
    );
  }

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="new-report-header">
            <h2>{t("Reportes.Reporte.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="new-scale-header mb-2 right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Col className="new-report-form">
                <FloatingLabel label="Título" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Titulo"
                    value={name}
                    onChange={handleNameChange}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Title" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      onChange={handleNameEnglishChange}
                      value={name_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
                <FloatingLabel label="Descripción" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Descripción"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </FloatingLabel>
                {english ? (
                  <FloatingLabel label="Description" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      onChange={handleDescriptionEnglishChange}
                      value={description_english}
                    />
                  </FloatingLabel>
                ) : (
                  <></>
                )}
                <FloatingLabel
                  label={state.language === "es" ? "Visibilidad" : "Visibility"}
                  className="mb-3"
                >
                  <Form.Select
                    onChange={handleVisibilityChange}
                    value={visibility}
                  >
                    <option value="">
                      {t("Reportes.Reporte.EditForm.SeleccionarNivel")}
                    </option>
                    <option value="admin">
                      {t("Reportes.Reporte.EditForm.Administradores")}
                    </option>
                    <option value="bosses">
                      {t("Reportes.Reporte.EditForm.Jefes")}
                    </option>
                    <option value="everyone">
                      {t("Reportes.Reporte.EditForm.Todos")}
                    </option>
                  </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={state.language === "es" ? "Tipo" : "Type"}
                  className="mb-3"
                >
                  <Form.Select value={type} onChange={handleChangeType}>
                    <option value="">
                      {t("Reportes.Reporte.EditForm.SeleccionarTipo")}
                    </option>
                    <option value="competencies">
                      {t("Reportes.Reporte.EditForm.Talento")}
                    </option>
                    <option value="map">
                      {t("Reportes.Reporte.EditForm.MapaDeTalento")}
                    </option>
                    <option value="performance">
                      {t("Reportes.Reporte.EditForm.Desempeno")}
                    </option>
                    <option value="hybrid">
                      {t("Reportes.Reporte.EditForm.Hibrido")}
                    </option>
                    <option value="survey">
                      {t("Reportes.Reporte.EditForm.Encuesta")}
                    </option>
                    <option value="comparison">
                      {t("Reportes.Reporte.EditForm.Comparativa")}
                    </option>
                  </Form.Select>
                </FloatingLabel>
                {type === "competencies" ? (
                  <WeightsContainer
                    handleWeightsChange={handleWeightsChange}
                    weights={weights}
                  />
                ) : type === "hybrid" ? (
                  <WeightsContainer
                    handleWeightsChange={handleWeightsChange}
                    weights={weights}
                  />
                ) : (
                  <></>
                )}
                {content}
                <Form.Group>
                  <Button className="primary" onClick={handleSubmit}>
                    {t("Reportes.Reporte.EditForm.Guardar")}
                  </Button>
                  <Link to="/reports">
                    <Button className="danger">
                      {t("Reportes.Reporte.EditForm.Cancelar")}
                    </Button>
                  </Link>
                  {type === "hybrid" ? (
                    <Button className="info space-up" onClick={handleAddOption}>
                      {t("Reportes.Reporte.EditForm.AgregarEvaluacion")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditReportForm;
