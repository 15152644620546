import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./EditAccountForm.css";

function EditAccountForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");

  const { state } = useContext(AuthContext);

  let { aid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/${aid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setName(response.data.account.name);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const navigate = useNavigate();

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/${aid}`,
        data: {
          name: name,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/accounts");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Cuentas",
      path: "/accounts",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col className="edit-account-header">
            <h2>{t("Cuentas.Cuenta.EditForm.Titulo")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="edit-account-form">
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre de la cuenta"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nombre de la cuenta"
                  value={name}
                  onChange={handleNameChange}
                />
              </FloatingLabel>
              <Button className="primary" onClick={handleSubmit}>
                {t("Cuentas.Cuenta.EditForm.Guardar")}
              </Button>
              <Link to="/accounts">
                <Button className="danger">
                  {t("Cuentas.Cuenta.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditAccountForm;
