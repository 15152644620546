import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./EditCategoryForm.css";

function EditCategoryForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [name_english, setNameEnglish] = useState("");

  const [english, setEnglish] = useState(false);

  const { state } = useContext(AuthContext);

  let { cid } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/${cid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setName(response.data.category.name);
      setNameEnglish(response.data.category.name_english);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/categories/${cid}`,
        data: {
          name: name,
          name_english: name_english,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/categories");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Categorias",
      path: "/categories",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={11} className="edit-category-header">
            <h2>{t("Categorias.Categoria.EditForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="edit-category-header mb-2 right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col className="edit-category-form">
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre de la categoría"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nombre de la categoría"
                  onChange={handleNameChange}
                  value={name}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Category name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Category name"
                    onChange={handleNameEnglishChange}
                    value={name_english}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <Button className="primary" onClick={handleSubmit}>
                {t("Categorias.Categoria.EditForm.Guardar")}
              </Button>
              <Link to="/categories">
                <Button className="danger">
                  {t("Categorias.Categoria.EditForm.Cancelar")}
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditCategoryForm;
