import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faEyeSlash,
  faList,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PeopleList from "../Components/PeopleList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./People.css";

function People() {
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [count, setCount] = useState(0);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/organization/${state.organization}`,
        data: { status: "active" },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPeople(response.data.users);
      setCount(response.data.count);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const getInactivePeople = async () => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/organization/${state.organization}`,
      data: { status: "inactive" },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPeople(response.data.users);
  };

  const getAllPeople = async () => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/organization/${state.organization}`,
      data: { status: "all" },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPeople(response.data.users);
  };

  const getActivePeople = async () => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/organization/${state.organization}`,
      data: { status: "active" },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setPeople(response.data.users);
  };

  const handleDownloadReport = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/report/${state.organization}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");

    link.download = response.data.key;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={8} className="people">
            <h2>
              {t("Personas.Titulo")} ({count})
            </h2>
          </Col>
          <Col lg={4} className="right people-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/people/new`}>
                  <FontAwesomeIcon icon={faPlus} /> {t("Personas.NuevaPersona")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/people/import`}>
                  <FontAwesomeIcon icon={faUpload} />{" "}
                  {t("Personas.ImportarPersonas")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => getActivePeople()}>
                  <FontAwesomeIcon icon={faEye} />{" "}
                  {t("Personas.MostrarActivos")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => getInactivePeople()}>
                  <FontAwesomeIcon icon={faEyeSlash} />{" "}
                  {t("Personas.MostrarInactivos")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => getAllPeople()}>
                  <FontAwesomeIcon icon={faEye} /> {t("Personas.MostrarTodos")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleDownloadReport()}>
                  <FontAwesomeIcon icon={faDownload} />{" "}
                  {t("Personas.DescargarLista")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {people.length === 0 ? (
            <Col className="empty-people center">
              <h5>{t("Personas.SinPersonas")}</h5>
            </Col>
          ) : (
            <PeopleList people={people} language={state.language} />
          )}
        </Row>
      </Container>
    );
  }
}

export default People;
