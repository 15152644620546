import React from "react";
import { Col } from "react-bootstrap";
import "./PeopleWillEvaluateDetailsList.css";
import PersonWillEvaluateDetails from "./PersonWillEvaluateDetails";

function PeopleWillEvaluateDetailsList({ people, handleDeletePersonEvaluation }) {
  return (
    <Col className="people-will-evaluate-details-list">
      {people.map((element) => (
        <PersonWillEvaluateDetails
          key={element._id}
          id={element._id}
          name={element.name}
          profile={element.profileName}
          relationship={element.relationship}
          handleDeletePersonEvaluation={handleDeletePersonEvaluation}
        />
      ))}
    </Col>
  );
}

export default PeopleWillEvaluateDetailsList;
