import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./NineBoxes.css";

function NineBoxes({ selectBox }) {
  const { t } = useTranslation();

  return (
    <Col>
      <Row className="justify-content-center">
        <Col className="d-grid gap-2">
          <Button className="nine-box" onClick={() => selectBox("upper-left")}>
            {t("Reportes.ProfesionalExperimentado")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button
            className="nine-box"
            onClick={() => selectBox("upper-center")}
          >
            {t("Reportes.EstrellaEmergente")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button className="nine-box" onClick={() => selectBox("upper-right")}>
            {t("Reportes.FuturosLideres")}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="d-grid gap-2">
          <Button className="nine-box" onClick={() => selectBox("middle-left")}>
            {t("Reportes.Dilema")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button
            className="nine-box"
            onClick={() => selectBox("middle-center")}
          >
            {t("Reportes.FuturoPrometedor")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button
            className="nine-box"
            onClick={() => selectBox("middle-right")}
          >
            {t("Reportes.EstrellaEmergente")}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="d-grid gap-2">
          <Button className="nine-box" onClick={() => selectBox("lower-left")}>
            {t("Reportes.BajoDesarrollo")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button
            className="nine-box"
            onClick={() => selectBox("lower-center")}
          >
            {t("Reportes.Dilema")}
          </Button>
        </Col>
        <Col className="d-grid gap-2">
          <Button className="nine-box" onClick={() => selectBox("lower-right")}>
            {t("Reportes.DiamanteEnBruto")}
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default NineBoxes;
